import React from 'react';
import { object, ObjectSchema, string } from 'yup';
import {
  BaseWidget,
  ConfigViewProps,
  EditorViewProps
} from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { Editor } from '@components/markdownEditor/dataDisplayWidgets/widgets/diagramGeneration/view/editor/editor';
import { Config } from '@components/markdownEditor/dataDisplayWidgets/widgets/diagramGeneration/view/config/config';

export interface DiagramGenerationValue {
  mapping: string;
}

const valueSchema: ObjectSchema<Partial<DiagramGenerationValue>> = object().shape({
  mapping: string().required()
});

export class DiagramGeneration extends BaseWidget<DiagramGenerationValue> {
  public override valueSchema = valueSchema;

  public override getName(): string {
    return 'diagramGeneration';
  }

  public override getMenuItem(): string {
    return 'Генерация диаграммы';
  }

  public override getEditorView(): React.FC<EditorViewProps<DiagramGenerationValue>> | null {
    return Editor;
  }

  public override getConfigView(): React.FC<ConfigViewProps<DiagramGenerationValue>> | null {
    return Config;
  }

  protected override async getInitConfig(): Promise<DiagramGenerationValue> {
    return new Promise((resolve) =>
      resolve({
        mapping: ''
      })
    );
  }
}
