import {
  JsonMetaEntities,
  JsonMetaEntity,
  JsonMetaRelation,
  JsonMetaRelations,
  JsonRootBlock,
  ModelType
} from '@baseModel/types/jsonDescription';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { Listener, Unsubscriber } from '@utils/observable';
import { EventEditorMetaModelObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { YamlModelState } from '@components/yamlEditor/yamlModelState';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { editorObservable } from '@components/yamlEditor/plugins/yamlBind/editor/editorObservable';

const yamlModelState = YamlModelState.getInstance();

export interface DiffElement<T> {
  name: string;
  changedValue?: T;
  deleted?: boolean;
}

function getMetaDiff(before: JsonMetaEntities, after: JsonMetaEntities): Set<DiffElement<JsonMetaEntities>>;

function getMetaDiff(before: JsonMetaRelations, after: JsonMetaRelations): Set<DiffElement<JsonMetaRelations>>;
function getMetaDiff(
  before: JsonMetaEntities | JsonMetaRelations,
  after: JsonMetaEntities | JsonMetaRelations
): Set<DiffElement<JsonMetaEntities>> | Set<DiffElement<JsonMetaRelations>> {
  const diff: Set<DiffElement<typeof before>> = new Set();

  const beforeNames = Object.keys(before);
  const afterNames = Object.keys(after);
  const deleted = difference(beforeNames, afterNames);

  const different = differenceWith(
    afterNames.map((el) => ({ name: el, value: after[el] })),
    beforeNames.map((el) => ({ name: el, value: before[el] })),
    isEqual
  );
  deleted.forEach((el) => {
    diff.add({ deleted: true, name: el });
  });
  different.forEach((el) => {
    diff.add({ name: el.name, changedValue: <typeof before>(<unknown>el.value) });
  });

  return diff;
}

export function editorMetaModelsSubscriber(
  modelType: ModelType,
  listener: Listener<EventEditorMetaModelObserver<JsonMetaEntity>>
): Unsubscriber;
export function editorMetaModelsSubscriber(
  modelType: ModelType,
  listener: Listener<EventEditorMetaModelObserver<JsonMetaRelation>>
): Unsubscriber;
export function editorMetaModelsSubscriber(
  modelType: ModelType,
  listener:
    | Listener<EventEditorMetaModelObserver<JsonMetaEntity>>
    | Listener<EventEditorMetaModelObserver<JsonMetaRelation>>
): Unsubscriber {
  return editorObservable.subscribe((newValue) => {
    const before =
      modelType === ModelType.entities ? yamlModelState.getMetaEntities() : yamlModelState.getMetaRelations();
    const after = newValue[JsonRootBlock.metamodel][modelType];
    if (modelType === ModelType.entities) {
      YamlModelState.getInstance().setMetaEntities(<JsonMetaEntities>after);
    } else {
      YamlModelState.getInstance().setMetaRelations(<JsonMetaRelations>after);
    }
    // Проверить приходит ли новый объект
    // TODO он всегда новый, т.к. сериализуется, полное сравнение делать дольше пока
    if (after === before) {
      return;
    }

    const diff = getMetaDiff(before, after);
    diff.forEach((el) => {
      listener({
        // TODO разобраться с типами, он думает что там всегда JsonMetaEntity
        changes: { after: <JsonMetaRelation>(<unknown>el.changedValue) },
        meta: {
          name: el.name,
          deleted: el.deleted
        }
      });
    });
  }, EDITOR_SENDER);
}
