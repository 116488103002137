import React, { forwardRef } from 'react';
import cn from 'classnames';
import { capitalize } from '@ui/utils';
import s from './svgIcon.module.less';

export type SvgIconSize = 'inherit' | 'small' | 'medium' | 'large';

export interface SvgIconProps extends React.SVGAttributes<SVGSVGElement> {
  children?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  title?: string;
  size?: SvgIconSize | number;
}

export const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>(function SvgIcon(props, ref) {
  const { children, title, className, size = 'medium', style, ...rest } = props;
  const hasSvg = React.isValidElement(children) && children.type === 'svg';
  const isCustomSize = typeof size === 'number';

  return (
    <svg
      ref={ref}
      className={cn(s.root, className, !isCustomSize && size !== 'inherit' && s[`size${capitalize(size)}`])}
      width="1em"
      height="1em"
      style={{
        ...(isCustomSize && { '--Icon-fontSize': `${size}px` }),
        ...style
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      {...rest}
      {...(hasSvg && children.props)}
    >
      {hasSvg ? children.props.children : children}
      {title && <title>{title}</title>}
    </svg>
  );
});
