import React from 'react';

export function useMediaQuery(query: string) {
  const [state, setState] = React.useState(window.matchMedia(query).matches);

  React.useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const onChange = () => {
      setState(mediaQuery.matches);
    };

    mediaQuery.addListener(onChange);

    setState(mediaQuery.matches);

    return () => {
      mediaQuery.removeListener(onChange);
    };
  }, [query]);

  return state;
}
