import { Engine } from '../engine/engine';
import { Models, ModelTypes } from '../engine/types';
import { useEffect, useState } from 'react';
import { EntityMetaModel } from '../metaModel/entityMetaModel';
import { RelationMetaModel } from '../metaModel/relationMetaModel';
import { Entity } from '../model/entity';
import { Relation } from '../model/relation';
import { ModelError } from '../errors';

const engine = Engine.getInstance();
export type MetaModels = Models.EntityMetaModel | Models.RelationMetaModel;
export type EntityModels = Models.Entity | Models.Relation;

function getModelByName(modelName: string, modelType: MetaModels) {
  return modelType === Models.EntityMetaModel
    ? engine.getMetaEntityByName(modelName)
    : engine.getMetaRelationByName(modelName);
}

function getModelById(id: string, modelType: EntityModels) {
  return modelType === Models.Entity ? engine.getEntityById(id) : engine.getRelationById(id);
}

function getModel(modelName: string, modelType: Models): Entity | Relation | EntityMetaModel | RelationMetaModel {
  if (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel) {
    return getModelByName(modelName, modelType);
  }
  if (modelType === Models.Entity || modelType === Models.Relation) {
    return getModelById(modelName, modelType);
  }
  throw new Error('Unknown model type');
}

/**
 * Получение модели по имени для мета или Ид для экземпляра
 * @param modelName имя модели или Ид
 * @param modelType тип модели
 */
export function useModel(modelName?: string, modelType?: Models): Exclude<ModelTypes, 'unknown'> | undefined {
  let initModel;

  try {
    initModel = modelName && modelType ? getModel(modelName, modelType) : undefined;
  } catch (error) {
    if (!(error instanceof ModelError)) {
      throw error;
    }
  }

  const [model, setModel] = useState(initModel);

  useEffect(() => {
    if (modelName && modelType) {
      let initModel;

      try {
        initModel = modelName && modelType ? getModel(modelName, modelType) : undefined;
      } catch (error) {
        if (!(error instanceof ModelError)) {
          throw error;
        }
      }
      setModel(initModel);
    } else {
      setModel(undefined);
    }
  }, [modelName, modelType]);

  return model;
}
