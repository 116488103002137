import React, { useCallback, useEffect, useRef } from 'react';
import { Engine } from '@baseModel/engine/engine';
import { ModelEventTypes, Models } from '@baseModel/engine/types';
import { Unsubscriber } from '@utils/observable';
import {
  ChangeEngineModel,
  ChangeModel,
  SenderBaseModel
} from '@components/drawio/plugins/graphElements/graphElementResult';
import { useEngineModelsSubscription } from './useEngineModelsSubscription';

enum UnsubscribersActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UNMOUNT = 'UNMOUNT'
}

type UnsubscribersState = { key: string; unsubscribe: Unsubscriber }[];

type UnsubscribersAction =
  | { type: UnsubscribersActionType.ADD; unsubscribe: { key: string; unsubscribe: Unsubscriber } }
  | { type: UnsubscribersActionType.REMOVE; key: string }
  | { type: UnsubscribersActionType.UNMOUNT };

function unsubscribersReducer(state: UnsubscribersState, action: UnsubscribersAction): UnsubscribersState {
  switch (action.type) {
    case UnsubscribersActionType.ADD:
      return [...state, action.unsubscribe];
    case UnsubscribersActionType.REMOVE:
      for (let i = 0; i < state.length; i++) {
        if (state[i].key === action.key) {
          state[i].unsubscribe();
        }
      }
      return state.filter((value) => value.key !== action.key);
    case UnsubscribersActionType.UNMOUNT:
      for (let i = 0; i < state.length; i++) {
        state[i].unsubscribe();
      }
      return state;
    default:
      throw new Error();
  }
}

export function useChangeModelsSubscription(
  dataId: string,
  model: Models,
  changeModelProp?: (changeModel: ChangeModel) => void,
  changeEngineModelProp?: (changeEngineModel: ChangeEngineModel) => void
): string[] {
  const engineModelKeys = useEngineModelsSubscription(model, onChangeEngineModel);
  const [unsubscribers, unsubscribersDispatch] = React.useReducer(unsubscribersReducer, []);
  const UnsubscribersRef = useRef<UnsubscribersState>(unsubscribers);
  const [isLoad, setIsLoad] = React.useState<boolean>(false);

  const subscribeModels = useCallback(
    (model: Models, modelKey: string): Unsubscriber => {
      return getModelByKey(model, modelKey).subscribeModel(
        (value) => changeModelProp && changeModelProp({ model, modelKey: modelKey, value }),
        SenderBaseModel + dataId
      );
    },
    [dataId, changeModelProp]
  );

  useEffect(() => {
    UnsubscribersRef.current = unsubscribers;
  }, [unsubscribers]);

  useEffect(() => {
    return () => {
      unsubscribersReducer(UnsubscribersRef.current, { type: UnsubscribersActionType.UNMOUNT });
    };
  }, []);

  useEffect(() => {
    if (!isLoad) {
      for (let i = 0; i < engineModelKeys.length; i++)
        unsubscribersDispatch({
          type: UnsubscribersActionType.ADD,
          unsubscribe: { key: engineModelKeys[i], unsubscribe: subscribeModels(model, engineModelKeys[i]) }
        });

      setIsLoad(true);
    }
  }, [model, isLoad, engineModelKeys, subscribeModels]);

  function onChangeEngineModel(changeEngineModel: ChangeEngineModel) {
    changeEngineModelProp && changeEngineModelProp(changeEngineModel);

    if (changeEngineModel.value.event === ModelEventTypes.add)
      unsubscribersDispatch({
        type: UnsubscribersActionType.ADD,
        unsubscribe: {
          key: changeEngineModel.modelKey,
          unsubscribe: subscribeModels(changeEngineModel.value.type, changeEngineModel.modelKey)
        }
      });
    else if (changeEngineModel.value.event === ModelEventTypes.remove)
      unsubscribersDispatch({ type: UnsubscribersActionType.REMOVE, key: changeEngineModel.modelKey });
  }

  return engineModelKeys;
}

export function getModelByKey(model: Models, key: string) {
  const engine = Engine.getInstance();

  if (model === Models.Entity || model === Models.EntityMetaModel)
    return model === Models.Entity ? engine.getEntityById(key) : engine.getMetaEntityByName(key);
  else return model === Models.Relation ? engine.getRelationById(key) : engine.getMetaRelationByName(key);
}
