import { MxCell } from '../../types/mxCell';
import { GraphContextGetElements, GraphElementResultEdge, GraphElementResultTypes, ID } from './graphElementResult';
import { getAttributesArray } from '../eventDifference/eventDifferenceValue';
import { getStyleArray } from '../eventDifference/eventDifferenceStyles';
import { getGraphElement } from './getGraphElement';

export function getGraphElementEdge(this: GraphContextGetElements, cell: MxCell): GraphElementResultEdge {
  const source = cell.source;
  const target = cell.target;

  return {
    id: <string>cell.id,
    _id: cell.getAttribute(ID, null),
    label: this.getLabel(cell),
    attributes: cell.value ? getAttributesArray(cell.value) : {},
    styles: cell.style ? getStyleArray(cell.style) : {},
    type: GraphElementResultTypes.edge,
    edge: {
      source: source ? getGraphElement.apply(this, [source, GraphElementResultTypes.vertex]) : null,
      target: target ? getGraphElement.apply(this, [target, GraphElementResultTypes.vertex]) : null
    }
  };
}
