import { Loggable } from '../loggable';
import { Listener, Observable, Sender, Unsubscriber } from '@utils/observable';
import { ModelError } from '@baseModel/errors';

type FieldsStore<T> = Map<string, T>;

export enum DataStoreName {
  fields = 'fields',
  relations = 'relations',
  common = 'common'
}

type ValueType<F, C, R> = {
  [DataStoreName.fields]: F;
  [DataStoreName.relations]: R;
  [DataStoreName.common]: C;
};

type DataStoreListeners<F, C, R> = {
  [K in keyof ValueType<F, C, R>]: Map<string, Observable<ValueType<F, C, R>[K] | undefined>>;
};

const t = {};
export type EmptyObject = typeof t;

export interface JSONSerialize<F, C, R> {
  [DataStoreName.fields]: {
    [name: string]: ValueType<F, C, R>[DataStoreName.fields];
  };
  [DataStoreName.relations]: {
    [name: string]: ValueType<F, C, R>[DataStoreName.relations];
  };
  [DataStoreName.common]: {
    [name: string]: ValueType<F, C, R>[DataStoreName.common];
  };
}

export interface AnyObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [property: string]: any;
}

export interface ModelUpdateData {
  fieldType: DataStoreName;
  fieldName: string;
  value: string | number | boolean | undefined | null | AnyObject;
  itsNewUnit: boolean;
}

export interface JSONRepresentation<F, R, C> {
  fields: { [name: string]: F };
  relations: { [name: string]: R };
  common: { [name: string]: C };
}

export const storeTypes = [DataStoreName.fields, DataStoreName.relations, DataStoreName.common];

/*
  TODO привести бы в порядок типы и убрать дублирование, когда будет возможно
 */

export class DataJuggler<F, C = never, R = never> extends Loggable {
  private readonly [DataStoreName.fields]: FieldsStore<F> = new Map();
  private readonly [DataStoreName.relations]: FieldsStore<R> = new Map();
  private readonly [DataStoreName.common]: FieldsStore<C> = new Map();
  private readonly chunkListeners: DataStoreListeners<F, C, R> = {
    [DataStoreName.fields]: new Map(),
    [DataStoreName.relations]: new Map(),
    [DataStoreName.common]: new Map()
  };
  private readonly modelListeners = new Observable('DataJuggler_modelListeners', {} as ModelUpdateData);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected validateField(fieldName: string, value: F): void {
    const availableSystemFields = ['__id', '__name', '__type'];

    if (!availableSystemFields.includes(fieldName) && /^__/i.test(fieldName)) {
      const error = `field ${fieldName} with two leading underscores are system fields and cannot be created`;
      this.logger(error);
      throw new ModelError(error);
    }
    // need override
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected validateRelation(relationName: string, value: R): void {
    // need override
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected validateCommon(commonFieldName: string, value: C): void {
    // need override
  }

  public validateData(): void {
    // need override
  }

  public setFieldValue(fieldName: string, value: F, sender?: Sender) {
    this.validateField(fieldName, value);
    const itsNewUnit = !this.fields.has(fieldName);
    this.fields.set(fieldName, value);
    this.emit(DataStoreName.fields, fieldName, itsNewUnit, sender);
    this.logger('set field % value %o', fieldName, value);
  }

  public getFieldValue(fieldName: string): F | undefined {
    return this.fields.get(fieldName);
  }

  public setRelationValue(relationName: string, value: R, sender?: Sender) {
    this.validateRelation(relationName, value);
    const itsNewUnit = !this.relations.has(relationName);
    this.relations.set(relationName, value);
    this.emit(DataStoreName.relations, relationName, itsNewUnit, sender);
    this.logger('set relation % value %o', relationName, value);
  }

  public getRelationValue(relationName: string): R | undefined {
    return this.relations.get(relationName);
  }

  public setCommonValue(commonFieldName: string, value: C, sender?: Sender) {
    this.validateCommon(commonFieldName, value);
    const itsNewUnit = !this.common.has(commonFieldName);
    this.common.set(commonFieldName, value);
    this.emit(DataStoreName.common, commonFieldName, itsNewUnit, sender);
    this.logger('set common % value %o', commonFieldName, value);
  }

  public getCommonValue(commonFieldName: string): C | undefined {
    return this.common.get(commonFieldName);
  }

  public getValue(storeName: DataStoreName.fields, fieldName: string): F | undefined;
  public getValue(storeName: DataStoreName.relations, fieldName: string): R | undefined;
  public getValue(storeName: DataStoreName.common, fieldName: string): C | undefined;
  public getValue(storeName: DataStoreName, fieldName: string): F | R | C | undefined;
  public getValue(storeName: DataStoreName, fieldName: string) {
    switch (storeName) {
      case DataStoreName.fields:
        return this.getFieldValue(fieldName);
      case DataStoreName.relations:
        return this.getRelationValue(fieldName);
      case DataStoreName.common:
        return this.getCommonValue(fieldName);
    }
    return undefined;
  }

  public getFieldNames() {
    return Array.from(this.fields.keys());
  }

  public getRelationNames() {
    return Array.from(this.relations.keys());
  }

  public getCommonNames() {
    return Array.from(this.common.keys());
  }

  public removeField(name: string, sender?: Sender) {
    const itsNewUnit = !this.fields.has(name);
    this.fields.delete(name);
    this.emit(DataStoreName.fields, name, itsNewUnit, sender);
    this.logger('remove field %', name);
  }

  public removeRelation(name: string, sender?: Sender) {
    const itsNewUnit = !this.relations.has(name);
    this.relations.delete(name);
    this.emit(DataStoreName.relations, name, itsNewUnit, sender);
    this.logger('remove relation %', name);
  }

  public removeCommon(name: string, sender?: Sender) {
    const itsNewUnit = !this.common.has(name);
    this.common.delete(name);
    this.emit(DataStoreName.common, name, itsNewUnit, sender);
    this.logger('remove common field %', name);
  }

  private emit(fieldType: DataStoreName, fieldName: string, itsNewUnit: boolean, sender?: Sender) {
    const observable = this.chunkListeners[fieldType].get(fieldName);
    this.modelListeners.setValue(
      { fieldType, fieldName, value: this.getValue(fieldType, fieldName) as ModelUpdateData['value'], itsNewUnit },
      sender
    );
    if (!observable) {
      return;
    }
    if (fieldType === DataStoreName.fields) {
      (observable as Observable<F | undefined>).setValue(this.getValue(fieldType, fieldName), sender);
    } else if (fieldType === DataStoreName.relations) {
      (observable as Observable<R | undefined>).setValue(this.getValue(fieldType, fieldName), sender);
    } else if (fieldType === DataStoreName.common) {
      (observable as Observable<C | undefined>).setValue(this.getValue(fieldType, fieldName), sender);
    }
  }

  /**
   * Subscribe to field value changes
   * @param fieldType
   * @param fieldName
   * @param listener
   * @param sender
   */
  public subscribeData(
    fieldType: DataStoreName,
    fieldName: string,
    listener: Listener<F | undefined> | Listener<C | undefined> | Listener<R | undefined>,
    sender?: Sender
  ): Unsubscriber {
    // observe listener to observable
    let observable = this.chunkListeners[fieldType].get(fieldName);
    if (!observable) {
      const observableName = `DataJuggler_${fieldType}_${fieldName}_${sender}`;
      if (fieldType === DataStoreName.fields) {
        observable = new Observable<F | undefined>(observableName, this.getValue(fieldType, fieldName));
        this.chunkListeners[fieldType].set(fieldName, observable);
        return observable.subscribe(listener as Listener<F | undefined>, sender);
      } else if (fieldType === DataStoreName.relations) {
        observable = new Observable<R | undefined>(observableName, this.getValue(fieldType, fieldName));
        this.chunkListeners[fieldType].set(fieldName, observable);
        return observable.subscribe(listener as Listener<R | undefined>, sender);
      } else if (fieldType === DataStoreName.common) {
        observable = new Observable<C | undefined>(observableName, this.getValue(fieldType, fieldName));
        this.chunkListeners[fieldType].set(fieldName, observable);
        return observable.subscribe(listener as Listener<C | undefined>, sender);
      } else {
        throw new Error(`Unknown fieldType ${fieldType}`);
      }
    }
    if (fieldType === DataStoreName.fields) {
      return (observable as Observable<F | undefined>).subscribe(listener as Listener<F | undefined>, sender);
    } else if (fieldType === DataStoreName.relations) {
      return (observable as Observable<R | undefined>).subscribe(listener as Listener<R | undefined>, sender);
    } else if (fieldType === DataStoreName.common) {
      return (observable as Observable<C | undefined>).subscribe(listener as Listener<C | undefined>, sender);
    } else {
      throw new Error(`Unknown fieldType ${fieldType}`);
    }
  }

  /**
   * Subscribe to model chane event
   */
  public subscribeModel(listener: Listener<ModelUpdateData>, sender?: Sender): Unsubscriber {
    return this.modelListeners.subscribe(listener, sender);
  }

  public toJSON(): JSONSerialize<F, C, R> {
    const result: JSONSerialize<F, C, R> = {
      [DataStoreName.fields]: {},
      [DataStoreName.relations]: {},
      [DataStoreName.common]: {}
    };
    this.fields.forEach((v, k) => {
      result.fields[k] = v;
    });

    for (const storeType of storeTypes) {
      this[storeType].forEach((v, k) => {
        result[storeType][k] = v;
      });
    }
    return result;
  }

  public override toString(): string {
    return JSON.stringify(this.toJSON());
  }
}
