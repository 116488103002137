import { Block } from './block';

import { BlockType } from './types';
import { DOCUMENT_BLOCK_TAG } from '@components/markdownEditor/const';
import { string } from 'yup';
import { JsonDocumentMarkdownBlock } from '../../types/jsonDescription';

export class Markdown extends Block<string> {
  public override valueSchema = string();

  constructor(id: string) {
    super(BlockType.markdown, id);
  }

  public static override getValueFromJSON(json: string | JsonDocumentMarkdownBlock): string {
    try {
      const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentMarkdownBlock) : json;
      return jsonObj.value || '';
    } catch (e) {
      return typeof json === 'string' ? json : json.value || '';
    }
  }

  public static override fromJSON(json: string | JsonDocumentMarkdownBlock): Markdown {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentMarkdownBlock) : json;
    const markdown = new Markdown(jsonObj.id);
    markdown.setValue(Markdown.getValueFromJSON(jsonObj));
    return markdown;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()}]\n${this.getValue() || ''}\n:::`;
  }
}
