import { DrawioContext } from '../types/window';
import { MxCellState } from '../types/mxCellState';
import { GraphElementResultTypes, ID } from './graphElements/graphElementResult';
import { MxCell } from '../types/mxCell';

export function loadSetAttributeByIdPlugin(this: DrawioContext) {
  const graph = this.EditorUi.editor.graph;
  const model = graph.model;
  const editElementById = loadEditElementByIdPlugin.apply(this);

  return (_id: string, name: string, value: string | null, type?: GraphElementResultTypes): void => {
    editElementById(
      _id,
      (cell: MxCell) => {
        if (name === 'style') {
          // убрано по просьбе 30.08.2023
          //   model.setStyle(cell, value || '');
          return;
        }

        const node = <HTMLElement>cell?.value;
        const cloneNode = <HTMLElement>node.cloneNode(true);

        if (name === 'label')
          if (graph.isHtmlLabel(cell)) {
            const label = graph.getLabel(cell);
            value = replaceTextLabelHtml(label, value || '');
          }

        const oldValue = node.getAttribute(name);
        if (oldValue !== value) {
          if (value) {
            cloneNode.setAttribute(name, value);
            model.setValue(cell, cloneNode);
          } else {
            graph.setAttributeForCell(cell, name, value);
          }
        }
      },
      type
    );
  };

  function replaceTextLabelHtml(label: string, text: string): string {
    const div = document.createElement('div');
    div.innerHTML = label;

    let child = div.firstChild;
    while (child !== null && child.firstChild !== null) child = child.firstChild;

    if (child !== null) child.replaceWith(text);
    else div.innerHTML = text;

    return div.innerHTML;
  }
}
export function loadEditElementByIdPlugin(this: DrawioContext) {
  const mxUtils = this.mxUtils;
  const graph = this.EditorUi.editor.graph;

  return (_id: string, func: (cell: MxCell) => void, type?: GraphElementResultTypes): void => {
    graph.view.states.visit((id: string, state: MxCellState) => {
      const cell = state.cell;
      const node = <HTMLElement>cell?.value;

      if (mxUtils.isNode(node, null, ID, _id)) {
        if (type) {
          if (checkType(cell, type)) func(cell);
        } else func(cell);
      }
    });
  };
}
export function loadFindElementByIdPlugin(this: DrawioContext) {
  const mxUtils = this.mxUtils;
  const graph = this.EditorUi.editor.graph;
  const model = graph.model;

  return (_id: string, type?: GraphElementResultTypes): MxCell | undefined => {
    for (const id in model.cells) {
      const cell = model.cells[id];
      const node = <HTMLElement>cell?.value;

      if (mxUtils.isNode(node, null, ID, _id)) {
        if (type) {
          if (checkType(cell, type)) return cell;
        } else return cell;
      }
    }
    return;
  };
}

export function loadChangeEdgeByIdPlugin(this: DrawioContext) {
  const findElementById = loadFindElementByIdPlugin.apply(this);

  return (_idEdge: string, _idCell: string, isSource: boolean): void => {
    const edge = findElementById(_idEdge, GraphElementResultTypes.edge);
    const cell = findElementById(_idCell, GraphElementResultTypes.vertex);

    if (edge && cell) {
      /* todo пока не работает */
      // console.log(edge, cell);
      cell.insertEdge(edge, isSource);
    }
  };
}

export function checkType(cell: MxCell, type: GraphElementResultTypes): boolean | undefined {
  return (
    (type === GraphElementResultTypes.vertex && cell.vertex) || (type === GraphElementResultTypes.edge && cell.edge)
  );
}
