import { TextModel } from '@components/yamlEditor/types';

export function findDocumentBlockIdByLineNumber(model: TextModel, lineNumber: number): string | undefined {
  if (lineNumber < 0) {
    return;
  }
  const text = model.getLineContent(lineNumber).trim();
  const res = text.match(/id/);
  if (res === null || res.index !== 0) {
    return findDocumentBlockIdByLineNumber(model, lineNumber - 1);
  }
  return text.replace(/(id:|")/g, '').trim();
}
