import { MxRootChange } from './mxRootChange';
import { MxStyleChange } from './mxStyleChange';
import { MxValueChange } from './mxValueChange';
import { MxCell } from './mxCell';
import { MxChildChange } from './mxChildChange';
import { MxTerminalChange } from './mxTerminalChange';

export interface MxEvent {
  readonly CHANGE: string;
  readonly CELLS_ADDED: string;
  readonly ADD_CELLS: string;
  readonly CELL_CONNECTED: string;
}

export interface MxEventsData {
  readonly getProperty: (type: string) => MxEventsChange[];
}

export enum MxEventObjectProperty {
  cells = 'cells',
  edge = 'edge',
  source = 'source'
}

export type MxEventObjectPropertyResult = {
  [MxEventObjectProperty.cells]: MxCell[] | MxCell;
  [MxEventObjectProperty.edge]: MxCell[] | MxCell;
  [MxEventObjectProperty.source]: string;
};

export interface MxEventObject {
  readonly getProperty: <K extends MxEventObjectProperty>(type: K) => MxEventObjectPropertyResult[K];
}

export type MxEventsChange = MxRootChange | MxStyleChange | MxValueChange | MxChildChange | MxTerminalChange;

export enum GraphEvents {
  cellsInserted = 'cellsInserted'
}
