import { markdownExtendParser } from '../utils/markdownExtendParser';
import { ProseMirrorView } from '../proseMirrorView';
import { DocumentEvent, DocumentEventData, MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';
import { INNER_TRANSACTION_META } from '../const';
import { Engine } from '@baseModel/engine/engine';

const document = Engine.getInstance().getDocument();

export function documentAddHandler(proseMirror: ProseMirrorView, data: DocumentEvent<DocumentEventData>): void {
  const state = proseMirror?.getState();
  const dispatch = proseMirror?.getDispatch();
  if (!state || !dispatch) {
    console.log('instance is empty');
    return;
  }
  const position = data.position;
  let insertPosition = 0;
  // После которого надо вставить
  if (position) {
    // C 1 т.к. нам надо вставить на место блока, а существующих станет следующим
    let counter = 1;
    let found = false;
    state.doc.forEach((node, startBlockPos) => {
      if (!found) {
        insertPosition = startBlockPos;
      }
      if (!found && counter === position && node.attrs.id !== MARKDOWN_NEW_BLOCK_ID) {
        insertPosition = startBlockPos + node.nodeSize;
        counter++;
        found = true;
      }
      // Если далее идет служебный блок, то вставим после него
      if (found && counter - 2 === position && node.attrs.id == MARKDOWN_NEW_BLOCK_ID) {
        insertPosition = startBlockPos + node.nodeSize;
      } else if (node.attrs.id && node.attrs.id !== MARKDOWN_NEW_BLOCK_ID) {
        counter++;
      }
    });
  }
  const block = document.getBlockById(data.id);

  const wrappedContent = block?.getMarkdown() || '';
  const doc = markdownExtendParser.parse(wrappedContent);
  if (doc?.content) {
    const transaction = state.tr.replaceWith(insertPosition, insertPosition, doc.content);
    // Пометим, что такую транзакцию повторно обрабатывать не надо
    transaction.setMeta(INNER_TRANSACTION_META, true);
    dispatch(transaction);
  } else {
    console.error(`fragment error`);
  }
}
