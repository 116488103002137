import { stringify } from 'yaml';
import set from 'lodash/set';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import { Engine } from '@baseModel/engine/engine';
import { Models } from '@baseModel/engine/types';
import { BlockType } from '@baseModel/document/blocks/types';
import { JsonDocumentBlockType, JsonDocumentTableBlock, ModelType } from '@baseModel/types/jsonDescription';
import { TableBlockValue } from '@baseModel/document/blocks/table';
import { DiagramBlockValue } from '@baseModel/document/blocks/diagram';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { CustomNodeValue } from '@baseModel/document/blocks/customNode';
import { getWidget } from '@components/markdownEditor/dataDisplayWidgets/utils';
import { JsonDocumentBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';

export type ToYAMLModifyJson = (json: AnyObject) => AnyObject;

export function toYAML(data: ReturnType<Engine['toJSON']>, modifyJson?: ToYAMLModifyJson): string {
  const metaModelEntities: AnyObject = {};
  const metaModelRelations: AnyObject = {};
  const modelEntities: AnyObject[] = [];
  const modelRelations: AnyObject[] = [];
  const document: AnyObject[] = [];

  for (const entityMetaModel of data.entityMetaModels) {
    const { name } = entityMetaModel;

    for (const commonKey in entityMetaModel.common) {
      set(metaModelEntities, [name, kebabCase(commonKey)], entityMetaModel.common[commonKey]);
    }

    if (!isEmpty(entityMetaModel.fields)) {
      set(metaModelEntities, [name, 'fields'], entityMetaModel.fields);
    }
  }

  for (const relationMetaModel of data.relationMetaModels) {
    const { name } = relationMetaModel;

    for (const commonKey in relationMetaModel.common) {
      set(metaModelRelations, [name, kebabCase(commonKey)], relationMetaModel.common[commonKey]);
    }

    if (!isEmpty(relationMetaModel.relations)) {
      set(metaModelRelations, [name, 'ends'], relationMetaModel.relations);
    }

    if (!isEmpty(relationMetaModel.fields)) {
      set(metaModelRelations, [name, 'fields'], relationMetaModel.fields);
    }
  }

  for (const entity of data.entities) {
    modelEntities.push(entity.fields);
  }

  for (const relation of data.relations) {
    modelRelations.push({
      type: relation.metaModelName,
      ends: relation.relations,
      fields: relation.fields
    });
  }

  for (const documentElement of data.document) {
    switch (documentElement.type) {
      case BlockType.markdown: {
        document.push({
          type: documentElement.type,
          id: documentElement.id,
          value: documentElement.value
        });

        break;
      }
      case BlockType.table: {
        const value = documentElement.value as TableBlockValue;

        const data = {
          type: JsonDocumentBlockType.table,
          id: documentElement.id,
          'model-type': value.modelType === Models.Entity ? ModelType.entities : ModelType.relations,
          'row-type': value.rowType,
          columns: value.columns
        } as JsonDocumentTableBlock;

        const showLink = value.showLink;
        if (showLink) {
          data['show-link'] = {
            table: showLink.table,
            'end-target': showLink.endTarget,
            'end-self': showLink.endSelf,
            relation: showLink.relation
          };

          const childShowLink = showLink.showLink;
          if (childShowLink) {
            data['show-link']['show-link'] = {
              table: childShowLink.table,
              'end-target': childShowLink.endTarget,
              'end-self': childShowLink.endSelf,
              relation: childShowLink.relation
            };
          }
        }
        if (value.filter) {
          data['filter'] = value.filter;
        }

        document.push(data);

        break;
      }
      case BlockType.diagram: {
        const value = documentElement.value as DiagramBlockValue;

        document.push({
          type: JsonDocumentBlockType.diagram,
          id: documentElement.id,
          'allowed-types': value.allowedTypes,
          base64: value.base64,
          contains: value.contains
        });

        break;
      }

      case BlockType.customNode: {
        const value = documentElement.value as CustomNodeValue;

        document.push({
          type: JsonDocumentBlockType.customNode,
          id: documentElement.id,
          'entry-point': value.entryPoint,
          src: value.src,
          value: value.value
        });

        break;
      }
      default: {
        const value = documentElement.value as JsonDocumentBlock['config'];
        const Widget = getWidget(documentElement.type);

        if (Widget) {
          const widget = new Widget();
          const Block = widget.getBlock();
          const yamlJson = Block.getValueInJSONForYAML({
            id: documentElement.id,
            type: documentElement.type,
            config: value
          });

          document.push(yamlJson);
        } else {
          console.log(`Неизвестный тип блока: ${value.type}`);
        }
      }
    }
  }

  let json: AnyObject = {};

  set(json, 'version', data.version);
  set(json, 'metamodel.entities', metaModelEntities);
  set(json, 'metamodel.relations', metaModelRelations);
  set(json, 'model.entities', modelEntities);
  set(json, 'model.relations', modelRelations);
  set(json, 'document', document);

  if (modifyJson) {
    json = modifyJson(json);
  }

  return stringify(json);
}
