import { Node, Slice, Fragment } from 'prosemirror-model';
import { ReplaceStep } from 'prosemirror-transform';
import { ProseMirrorView } from '../proseMirrorView';
import { DocumentEvent, DocumentEventData } from '@baseModel/document/document';
import { INNER_TRANSACTION_META } from '../const';
import { docForEach } from '@components/markdownEditor/utils/docForEach';
import { BlockControlsActions, blockControlsPluginKey } from '@components/markdownEditor/plugins/blockControls';
import { EditorState, Transaction } from 'prosemirror-state';

const sliceCut = new Slice(Fragment.empty, 0, 0);

function getReplaceSteps(cutPosition: number, pastePosition: number, node: Node): [ReplaceStep, ReplaceStep] {
  const stepCut = new ReplaceStep(cutPosition, cutPosition + node.nodeSize, sliceCut);
  const pastFragment = Fragment.empty.addToStart(node);
  const slicePast = new Slice(pastFragment, 0, 0);
  // Если вырезаем сверху и вставляем ниже, то размер блока надо вычесть из первоначального pastePosition, Т.к. его какое-то время нет
  if (cutPosition < pastePosition) {
    pastePosition = pastePosition - node.nodeSize;
  }
  const stepPast = new ReplaceStep(pastePosition, pastePosition, slicePast);
  return [stepCut, stepPast];
}

function getTransaction(
  state: EditorState,
  cutPosition: number,
  pastePosition: number,
  movedNode: Node,
  placedNode: Node,
  blockId: string
): [Transaction, BlockControlsActions | undefined] {
  const [stepCut, stepPast] = getReplaceSteps(cutPosition, pastePosition, movedNode);
  let tr = state.tr.step(stepCut).step(stepPast).setMeta(INNER_TRANSACTION_META, true);
  let delayedBlockAction: BlockControlsActions | undefined;
  const controlsPluginState = blockControlsPluginKey.getState(state);
  // Если был установлен виджет на блоке, переместим его тоже
  if (controlsPluginState?.find(undefined, undefined, (spec: { blockId: string }) => spec.blockId === blockId).length) {
    const blockAddActions: BlockControlsActions = {
      addIcon: { pos: pastePosition },
      blockId: blockId
    };
    if (cutPosition < pastePosition) {
      // Т.к. блоки меняются местами, позицию вставки надо сдвинуть на разницу их размеров
      delayedBlockAction = {
        addIcon: { pos: pastePosition + placedNode.nodeSize - movedNode.nodeSize },
        blockId: blockId
      };
    } else {
      tr = tr.setMeta(blockControlsPluginKey, blockAddActions);
    }
  }
  return [tr, delayedBlockAction];
}

export function documentSortChangeHandler(proseMirror: ProseMirrorView, data: DocumentEvent<DocumentEventData>): void {
  const state = proseMirror?.getState();
  const dispatch = proseMirror?.getDispatch();
  if (!state || !dispatch) {
    console.error('instance is empty');
    return;
  }

  // Позиция, на которую хотим переместить блок
  let pastePosition: number | undefined;
  // Позиция, с которой хотим переместить
  let cutPosition: number | undefined;
  // Нода, которую хотим переместить
  let movedNode: Node | undefined;
  // Нода, которая занимает наше место
  let placedNode: Node | undefined;
  docForEach(state.doc, (node, pos, index) => {
    // Запомним, где начинается блок, на место которого, мы должны переместить блок из события
    if (index === data.position) {
      pastePosition = pos;
      placedNode = node;
    }
    // Если только что нашли места вставки, а перемещаемый блок уже нашли ранее, проведем транзакцию
    if (placedNode && pastePosition !== undefined && movedNode && cutPosition !== undefined) {
      const [tr, delayedBlockAction] = getTransaction(
        state,
        cutPosition,
        pastePosition,
        movedNode,
        placedNode,
        data.id
      );
      dispatch(tr);
      if (delayedBlockAction) {
        setTimeout(() =>
          dispatch(
            proseMirror
              ?.getState()
              .tr.setMeta(INNER_TRANSACTION_META, true)
              .setMeta(blockControlsPluginKey, delayedBlockAction)
          )
        );
      }
      return false;
    }
    if (node.attrs.id === data.id) {
      // Если требуемый блок уже в нужном месте, просто ничего не делаем
      if (index === data.position) {
        return false;
      }
      // Если уже знаем, куда переместить - переместим
      if (placedNode && pastePosition !== undefined) {
        const [tr, delayedBlockAction] = getTransaction(state, pos, pastePosition, node, placedNode, data.id);
        dispatch(tr);
        if (delayedBlockAction) {
          setTimeout(() =>
            dispatch(
              proseMirror
                ?.getState()
                .tr.setMeta(INNER_TRANSACTION_META, true)
                .setMeta(blockControlsPluginKey, delayedBlockAction)
            )
          );
        }
        return false;
      }
      movedNode = node;
      cutPosition = pos;
    }
    return true;
  });
}
