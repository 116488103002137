import { SelectField, SelectFieldProps, TextField } from '@smwb/summer-ui';
import React from 'react';
import { TableColumn } from '../tableConfigurationForm';

interface Props {
  value: string;
  name: keyof TableColumn;
  onChange: (fieldName: keyof TableColumn, value?: string) => void;
  items?: SelectFieldProps['items'];
  isTextField?: boolean;
  label?: string;
}

export function EditColumnField({ value, onChange, name, items = [], isTextField = false, label }: Props) {
  if (isTextField) {
    return <TextField variant="filled" onChange={(e) => onChange(name, e.target.value)} value={value} label={label} />;
  }
  return (
    <SelectField
      onChange={(e, data) => onChange(name, data.value as string | undefined)}
      value={value}
      label={label}
      items={items}
      menuMaxHeight={220}
    />
  );
}
