import s from './modelToolbar.module.less';
import { ButtonGroup, Menu, MenuItem, useSnackbar, useTheme, Theme } from '@smwb/summer-ui';
import { Button } from '@ui/components';
import React, { useEffect, useRef, useState } from 'react';
import { toYAML } from '@baseModel/utils/toYAML';
import FileSaver from 'file-saver';
import { Engine } from '@baseModel/engine/engine';
import { isYamlValid } from '@utils/isYamlValid';
import { parse } from 'yaml';
import { JsonDescription } from '@baseModel/types/jsonDescription';
import { EngineLoader } from '@baseModel/engine/engineLoader';
import axios from 'axios';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useSearchParams } from 'react-router-dom';

import templateBasisModel from '@baseModel/templates/basis.json';
import template220 from '@baseModel/templates/220.json';
import templateEmpty from '@baseModel/templates/empty.json';

const engine = Engine.getInstance();
const engineLoader = new EngineLoader();

import config from '../../config';
import { useTypedSelector } from '../../redux/types';

export enum Template {
  basis,
  n220,
  empty
}

export function ModelToolbar() {
  const [searchParams, setSearchParams] = useSearchParams();
  const fileId = searchParams.get('file');
  const isReadyPrint = useTypedSelector((state) => state.app.markdownEditor.actions.isReadyPrint);

  const [isDisabled, setIsDisabled] = useState(false);
  const { addSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const snackbarPosition = isMobile ? 'bottom' : 'top-right';

  const anchorPatternMenuRef = useRef<HTMLButtonElement>(null);
  const [patternMenuOpen, setPatternMenuOpen] = useState(false);

  const { theme, setTheme } = useTheme();
  const prevTheme = useRef(theme);

  const onDownload = () => {
    setIsDisabled(true);
    try {
      const yaml = toYAML(engine.toJSON());
      const blob = new Blob([yaml], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, 'config.yaml');
    } finally {
      setIsDisabled(false);
    }
  };

  const uploadFileRef = React.useRef<HTMLInputElement>(null);
  const onUpload = React.useCallback(() => {
    uploadFileRef.current?.click();
  }, []);
  const onUploadChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setIsDisabled(true);
    const file = event.target.files?.[0];

    if (!file) {
      setIsDisabled(false);
      return;
    }

    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => {
      const value = reader.result;

      // Todo: Стоит ли разрешить загрузку файла, если yaml-файл невалидный?
      if (typeof value === 'string' && isYamlValid(value)) {
        engine.removeAll();
        // Подождем пока все узнают об изменениях.
        // TODO: При подключении бека, надо будет организовать очереди.
        setTimeout(() => {
          engineLoader.schemaLoad(parse(value) as unknown as JsonDescription);
          setIsDisabled(false);
        }, 100);
      }
    };
  };

  const onSave = () => {
    setIsDisabled(true);
    const yaml = toYAML(engine.toJSON());
    const blob = new Blob([yaml], { type: 'text/plain;charset=utf-8' });
    const id = fileId ? fileId : Date.now().toString(36);
    const data = new File([blob], 'config.yaml');

    axios
      .put(`${config.apiS3Endpoint}/${id}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        return new Promise<void>((rs, rj) => {
          if (response.status === axios.HttpStatusCode.Ok) {
            addSnackbar({ message: 'Файл сохранён.', variant: 'success', position: snackbarPosition });

            if (!fileId) {
              setSearchParams((params) => {
                params.set('file', id);
                return params;
              });
            }
            rs();
          } else {
            rj('Ошибка сохранения');
          }
        });
      })
      .catch((error) => {
        console.error(error);
        addSnackbar({ message: 'Ошибка сохранения файла.', variant: 'danger', position: snackbarPosition });
      })
      .finally(() => setIsDisabled(false));
  };

  const templateLoad = (template: Template) => {
    setPatternMenuOpen(false);
    setIsDisabled(true);
    let value: JsonDescription | undefined;

    if (template === Template.basis) {
      value = templateBasisModel as unknown as JsonDescription;
    } else if (template === Template.n220) {
      value = template220 as unknown as JsonDescription;
    } else if (template === Template.empty) {
      value = templateEmpty as unknown as JsonDescription;
    }

    if (value === undefined) {
      setIsDisabled(false);
      addSnackbar({ message: 'Шаблон не найден.', variant: 'danger', position: snackbarPosition });
      return;
    }

    engine.removeAll();
    // Подождем пока все узнают об изменениях.
    setTimeout(() => {
      if (value === undefined) {
        setIsDisabled(false);
        addSnackbar({ message: 'Шаблон не найден.', variant: 'danger', position: snackbarPosition });
        return;
      }
      engineLoader.schemaLoad(value);
      setIsDisabled(false);
      addSnackbar({ message: 'Шаблон загружен.', variant: 'success', position: snackbarPosition });
      setSearchParams();
    }, 100);
  };

  useEffect(() => {
    function onAfterPrint() {
      setTheme(prevTheme.current);
    }

    window.addEventListener('afterprint', onAfterPrint);

    return () => {
      window.removeEventListener('afterprint', onAfterPrint);
    };
  }, [theme, setTheme]);

  const onPrint = () => {
    if (!isReadyPrint) {
      return;
    }

    prevTheme.current = theme;

    // Добиваемся максимального контраста
    setTheme(Theme.light);

    setTimeout(window.print);
  };

  return (
    <div className={s.buttons}>
      <ButtonGroup className={s.group} fullWidth disabled={isDisabled}>
        <Button icon="file_download" onClick={onDownload}>
          Скачать
        </Button>
        <Button icon="file_upload" onClick={onUpload}>
          Загрузить
        </Button>
        <Button icon="save_as" onClick={onSave}>
          Сохранить S3
        </Button>
        <Button icon="pattern" ref={anchorPatternMenuRef} onClick={() => setPatternMenuOpen((state) => !state)}>
          Шаблоны
        </Button>
        <Button icon="print" onClick={onPrint} disabled={!isReadyPrint}>
          Печать
        </Button>
      </ButtonGroup>
      <Menu
        anchorElement={anchorPatternMenuRef.current}
        isOpen={patternMenuOpen}
        onClose={() => setPatternMenuOpen(false)}
      >
        <MenuItem onClick={() => templateLoad(Template.empty)}>Пустой документ</MenuItem>
        <MenuItem onClick={() => templateLoad(Template.basis)}>Базисная модель</MenuItem>
        <MenuItem onClick={() => templateLoad(Template.n220)}>220</MenuItem>
      </Menu>
      <input ref={uploadFileRef} type="file" accept=".yaml" onChange={onUploadChange} hidden />
    </div>
  );
}
