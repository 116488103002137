import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { Engine } from '@baseModel/engine/engine';
import { EventEditorModelObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { JsonRelation } from '@baseModel/types/jsonDescription';
import { Relation } from '@baseModel/model/relation';

const engine = Engine.getInstance();

export function editorRelationsListener(event: EventEditorModelObserver<JsonRelation>) {
  const {
    changes: { after },
    meta
  } = event;

  if (meta.deleted) {
    engine.removeRelation(meta.id, EDITOR_SENDER);
    return;
  }

  let isNew = false;
  let relation: Relation | undefined;
  try {
    relation = engine.getRelationById(meta.id);
  } catch (e) {
    const type = after?.type;
    if (!type) {
      return;
    }
    const metaModel = engine.getMetaRelationByName(type);
    if (!metaModel) {
      return;
    }
    isNew = true;
    relation = new Relation(metaModel);
  }

  // TODO еще возможен вариант когда все филды и енды удалили
  const fields = after?.fields;
  if (fields) {
    for (const field in fields) {
      if (field === 'type' || relation.getFieldValue(field) === fields[field]) {
        continue;
      }
      try {
        if (fields[field] === undefined) {
          relation.removeField(field, EDITOR_SENDER);
        } else {
          relation.setFieldValue(field, fields[field], EDITOR_SENDER);
        }
      } catch (e) {
        return;
      }
    }
  }
  const ends = after?.ends;
  if (ends) {
    for (const end in ends) {
      if (end === 'type' || relation.getRelationValue(end) === ends[end]) {
        continue;
      }
      try {
        if (ends[end] === undefined) {
          relation.removeField(end, EDITOR_SENDER);
        } else {
          relation.setFieldValue(end, ends[end], EDITOR_SENDER);
        }
      } catch (e) {
        return;
      }
    }
  }
  if (isNew) {
    engine.addRelation(relation, EDITOR_SENDER);
  }
}
