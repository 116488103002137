import { useCallback, useEffect, useMemo, useState } from 'react';
import { Engine } from '@baseModel/engine/engine';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { Block as WidgetBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';

const engine = Engine.getInstance();

export function getBlockById<T extends AnyObject>(id: string | undefined): WidgetBlock<T> | undefined {
  if (id === undefined) {
    return undefined;
  }

  const document = engine.getDocument();

  return document.getBlockById(id) as WidgetBlock<T> | undefined;
}

export function useBlockData<T extends AnyObject>(
  id: string | undefined,
  customSender?: string
): [T | undefined, (value: T | undefined) => void] | [undefined, undefined] {
  const [block, setBlock] = useState<WidgetBlock<T> | undefined>(getBlockById<T>(id));
  const [value, setValue] = useState<T | undefined>(block?.getValue());

  const sender = useMemo(() => customSender || `${WidgetBlock.constructor.name}_${Math.random()}`, [customSender]);

  useEffect(() => {
    setBlock(getBlockById(id));
  }, [id]);

  useEffect(() => {
    if (!block) {
      return;
    }

    return block.subscribe((newValue) => {
      setValue(newValue);
    }, sender);
  }, [block, sender, setValue]);

  useEffect(() => {
    if (!block) {
      return;
    }

    setValue(block.getValue());
  }, [block, setValue]);

  const changeValue = useCallback(
    (value: T | undefined) => {
      if (!block) {
        return;
      }

      return block.setValue(value, sender);
    },
    [block, sender]
  );

  if (block === undefined) {
    return [undefined, undefined];
  }

  return [value, changeValue];
}
