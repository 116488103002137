import debug, { Debugger } from 'debug';
import snakeCase from 'lodash/snakeCase';

//
export class Loggable {
  protected readonly logger: Debugger;

  constructor(logKey: string) {
    this.logger = debug(`base-model:${snakeCase(this.constructor.name)}:${logKey}`);
    this.logger('created');
  }
}
