import { Models } from '@baseModel/engine/types';
import { ModelType } from '@baseModel/types/jsonDescription';
import { editorInternalChangesSubscriber } from './editorInternalChangesSubscriber';
import { editorEmit } from './editorObserver';
import { editorDocumentBlockListener } from '@components/yamlEditor/plugins/yamlBind/editor/editorDocumentBlockListener';
import { editorEntitiesListener } from '@components/yamlEditor/plugins/yamlBind/editor/editorEntitiesListener';
import { editorModelsSubscriber } from '@components/yamlEditor/plugins/yamlBind/editor/editorModelsSubscriber';
import { editorDocumentBlockSubscriber } from '@components/yamlEditor/plugins/yamlBind/editor/editorDocumentBlockSubscriber';
import { editorRelationsListener } from '@components/yamlEditor/plugins/yamlBind/editor/editorRelationsListener';
import { baseModelDocumentSubscriber } from '@components/yamlEditor/plugins/yamlBind/baseModel/baseModelDocumentSubscriber';
import { baseModelModelsSubscriber } from '@components/yamlEditor/plugins/yamlBind/baseModel/baseModelModelsSubscriber';
import { editorMetaModelsSubscriber } from '@components/yamlEditor/plugins/yamlBind/editor/editorMetaModelsSubscriber';
import { Unsubscriber } from '@utils/observable';
import { editorMetaEntitiesListener } from '@components/yamlEditor/plugins/yamlBind/editor/editorMetaEntitiesListener';
import { editorMetaRelationsListener } from '@components/yamlEditor/plugins/yamlBind/editor/editorMetaRelationsListener';

export class YamlBind {
  public callEditorModelChange(value: string | undefined): void {
    editorEmit(value);
  }

  /**
   * Запросы на модификацию текста в редакторе
   */
  public editorInternalChangesSubscriber(...args: Parameters<typeof editorInternalChangesSubscriber>) {
    return editorInternalChangesSubscriber(...args);
  }

  private unsubscribes(subscribes: Unsubscriber[]) {
    subscribes.forEach((unsubscribe) => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    });
  }

  public baseModelSubscribe(): Unsubscriber {
    const subscribes = [
      baseModelModelsSubscriber(Models.EntityMetaModel),
      baseModelModelsSubscriber(Models.RelationMetaModel),
      baseModelModelsSubscriber(Models.Entity),
      baseModelModelsSubscriber(Models.Relation),
      baseModelDocumentSubscriber()
      // this.modelVersionSubscribe()
    ];

    return () => {
      this.unsubscribes(subscribes);
    };
  }

  /**
   * Все подписки на изменение yaml в редакторе.
   */
  public editorSubscribe(): Unsubscriber {
    const subscribes = [
      editorMetaModelsSubscriber(ModelType.entities, editorMetaEntitiesListener),
      editorMetaModelsSubscriber(ModelType.relations, editorMetaRelationsListener),
      editorModelsSubscriber(ModelType.entities, editorEntitiesListener),
      editorModelsSubscriber(ModelType.relations, editorRelationsListener),
      editorDocumentBlockSubscriber(editorDocumentBlockListener)
      // this.editorEngineVersion(initialValue)
    ];

    return () => {
      this.unsubscribes(subscribes);
    };
  }
}
