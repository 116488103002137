import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@smwb/summer-ui/dist/components/dataDisplay/table';
import s from './columnsPrevie.module.less';
import { TableColumn } from '../tableConfigurationForm';
import { CellRenderer } from './cellRenderer';
import { ColumnActionsCell } from './columnActionsCell';
import { JsonDocumentTableBlockColumn } from '@baseModel/types/jsonDescription';
import st from '@components/tableConfiguration/insertTableSheet.module.less';
import { EditColumnStepper } from '@components/tableConfiguration/columnsPreview/editColumnStepper';
import { Models } from '@baseModel/engine/types';
import { Button, Icon } from '@smwb/summer-ui';
import { confirmDialog } from '@components/confirmDialog/confirmDialog';
import { Engine } from '@baseModel/engine/engine';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';

const engine = Engine.getInstance();

export interface TablePreviewProps {
  // initColumns: JsonDocumentTableBlockColumn[];
  columns: TableColumn[];
  setColumns: (tableColumns: TableColumn[]) => void;
  modelType: Models.Entity | Models.Relation;
  rowType: string;
}

const tableRows: Array<keyof JsonDocumentTableBlockColumn> = ['display-name', 'name', 'type', 'value'];

export function ColumnsPreview({ columns, setColumns, rowType, modelType }: TablePreviewProps) {
  // const [columns, setColumns] = useState<TableColumn[]>(tableColumns(initColumns));
  const [activeColumnIdx, setActiveColumnIdx] = useState<number | undefined>();
  const handleAddColumn = () => {
    setColumns([...columns, { value: '', 'display-name': '', name: '', id: columns.length }]);
    setActiveColumnIdx(() => columns.length);
  };

  /**
   * Добавим все филды, которых еще нет
   */
  const handleAddAllFields = () => {
    let startId = columns.length;
    let meta: EntityMetaModel | RelationMetaModel | undefined;
    if (modelType === Models.Entity) {
      meta = engine.getMetaEntityByName(rowType);
    } else {
      meta = engine.getMetaRelationByName(rowType);
    }
    if (meta === undefined) {
      return;
    }
    const getFieldValue = meta.getFieldValue.bind(meta);
    const fields = meta.getFieldNames();
    const newColumns: TableColumn[] = fields
      .filter((fieldName) => !columns.find((column) => column.value === fieldName))
      .map((fieldName) => {
        const fieldValue = getFieldValue(fieldName);
        return {
          id: startId++,
          'display-name': fieldValue?.['display-name'] || fieldName,
          value: fieldName
        };
      });
    if (newColumns.length) {
      setColumns([...columns, ...newColumns].map((el, i) => ({ ...el, id: i })));
    }
  };

  const handleCloseStepper = () => {
    columns.pop();
    setColumns([...columns]);
    setActiveColumnIdx(undefined);
  };

  const handleSaveCol = (editColumn: TableColumn) => {
    setActiveColumnIdx(undefined);
    setColumns(columns.map((column, index) => (index === editColumn.id ? editColumn : column)));
  };

  const handleDeleteColumn = (id: number) => {
    confirmDialog({
      message: 'Безвозвратное удаление. Вы действительно хотите удалить колонку?',
      accept: () => {
        setColumns(columns.filter((c) => c.id !== id));
      }
    });
  };

  return (
    <>
      <div className={st.addCol}>
        <div className={st.addCol}>
          <Button className={st.addButton} type="button" onClick={handleAddColumn} disabled={!modelType || !rowType}>
            <Icon name="add" />
          </Button>
          Добавить колонку
        </div>
        <div className={st.addCol}>
          <Button className={st.addButton} type="button" onClick={handleAddAllFields} disabled={!modelType || !rowType}>
            <Icon name="playlist_add" />
          </Button>
          Добавить все поля
        </div>
      </div>
      {activeColumnIdx !== undefined && columns[activeColumnIdx] && (
        <div className={st.stepper}>
          <EditColumnStepper
            onSubmit={handleSaveCol}
            modelType={modelType}
            currentColumn={columns[activeColumnIdx]}
            rowType={rowType}
            onClose={handleCloseStepper}
          />
        </div>
      )}
      <div className={s.tableView} onKeyDown={(e) => e.stopPropagation()}>
        <Table>
          <TableBody>
            {tableRows.map((r, idx) => (
              <TableRow key={idx}>
                <TableCell>{r}</TableCell>
                {columns.map((c, i) => {
                  if (r === 'value') {
                    return (
                      <TableCell key={c.id}>
                        <CellRenderer column={c} index={i + 1} />
                      </TableCell>
                    );
                  }
                  return <TableCell key={c.id}>{c[r]}</TableCell>;
                })}
              </TableRow>
            ))}

            <TableRow>
              <TableCell className={s.actionsCell}></TableCell>
              {columns.map((column, index) => (
                <TableCell className={s.actionsCell} key={column.id}>
                  <ColumnActionsCell
                    onDelete={() => handleDeleteColumn(column.id)}
                    onEdit={() => setActiveColumnIdx(index)}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
