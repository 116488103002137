import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paths } from '@commonTypes/index';

export interface YamlEditorState {
  highlightedDocumentBlockId?: string;
  highlightedModelFieldPath: undefined | Paths;
}

const initialState: YamlEditorState = { highlightedDocumentBlockId: '', highlightedModelFieldPath: undefined };

export const yamlEditorSlice = createSlice({
  name: 'yamlEditor',
  initialState,
  reducers: {
    highlightDocumentBlockById(state, action: PayloadAction<string | undefined>) {
      state.highlightedDocumentBlockId = action.payload;
    },
    highlightModelFieldByPath(state, action: PayloadAction<Paths | undefined>) {
      state.highlightedModelFieldPath = action.payload;
    }
  }
});

export const { highlightDocumentBlockById, highlightModelFieldByPath } = yamlEditorSlice.actions;
export default yamlEditorSlice.reducer;
