import { Entity } from '../model/entity';
import { Relation } from '../model/relation';
import { EntityMetaModel } from '../metaModel/entityMetaModel';
import { RelationMetaModel } from '../metaModel/relationMetaModel';
import { ModelUpdateData } from '../utils/dataJuggler';
import { Document } from '../document/document';

export type ModelTypes = Entity | Relation | EntityMetaModel | RelationMetaModel | 'unknown';

export enum ModelEventTypes {
  all = 'all',
  none = 'none',
  add = 'add',
  remove = 'remove',
  addOrRemove = 'addOrRemove',
  update = 'update'
}

export enum Models {
  Entity = 'Entity',
  Relation = 'Relation',
  EntityMetaModel = 'EntityMetaModel',
  RelationMetaModel = 'RelationMetaModel'
}

export interface EngineEventAddOrRemoveData {
  name?: string;
  id?: string;
}

export interface EngineEventUpdateData extends EngineEventAddOrRemoveData {
  data: ModelUpdateData;
}

export type EngineEventData = EngineEventUpdateData | EngineEventAddOrRemoveData;

export interface EngineEvent<T> {
  type: Models;
  event: ModelEventTypes;
  data: T;
}

export type UnitTypes = {
  entityMetaModels: EntityMetaModel;
  relationMetaModels: RelationMetaModel;
  entities: Entity;
  relations: Relation;
  document: Document;
};

export type JSONSerializeEngine<K extends keyof UnitTypes> = {
  [T in K]: T extends 'document' ? ReturnType<UnitTypes[T]['toJSON']> : ReturnType<UnitTypes[T]['toJSON']>[];
};
