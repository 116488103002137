import { object, string, ObjectSchema } from 'yup';
import { Block } from './block';
import omit from 'lodash/omit';
import { BlockType } from './types';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
// import { MARKDOWN_NEW_LINE_HELPER } from '../document';
import { JsonDocumentCustomNodeBlock } from '../../types/jsonDescription';

export type CustomNodeValue = Omit<JsonDocumentCustomNodeBlock, 'type' | 'id' | 'entry-point'> & {
  entryPoint?: string;
};

const customNodeSchema: ObjectSchema<CustomNodeValue> = object().shape({
  entryPoint: string().required(),
  src: string(),
  value: string()
});

export class CustomNode extends Block<CustomNodeValue> {
  public override valueSchema = customNodeSchema;

  constructor(id: string) {
    super(BlockType.customNode, id);
  }

  public static override getValueFromJSON(json: string | JsonDocumentCustomNodeBlock): CustomNodeValue {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentCustomNodeBlock) : json;
    const entryPoint = jsonObj['entry-point'];
    return { ...omit(jsonObj, ['id', 'type', 'entry-point']), entryPoint };
  }

  public static override fromJSON(json: string | JsonDocumentCustomNodeBlock): CustomNode {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentCustomNodeBlock) : json;
    const customNode = new CustomNode(jsonObj.id);
    customNode.setValue(CustomNode.getValueFromJSON(jsonObj));
    return customNode;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()},type=${
      this.type
    }]\n;;; ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] *here be dragons* \n;;; \n:::`;
    // Убрал, т.к. ломает много
    // return `\n::: ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] \n:::${MARKDOWN_NEW_LINE_HELPER}`;
  }
}
