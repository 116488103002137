import type { Paths, TargetPath } from '@commonTypes/index';
import toPath from 'lodash/toPath';

export function getModifyModelPath(path: string): Paths {
  return toPath(path).map((fragmentPath) => {
    const toNumber = Number(fragmentPath);

    if (Number.isInteger(toNumber)) {
      return toNumber;
    }

    return fragmentPath;
  });
}

export function getModifyTargetPath(path: TargetPath | undefined): Paths {
  const paths: Paths = [];
  if (path === undefined) {
    return paths;
  }

  if (Array.isArray(path)) {
    return path;
  } else {
    paths.push(path);
  }

  return paths;
}

export function getPath(modelPath: string, targetPath: TargetPath | undefined): Paths {
  return [...getModifyModelPath(modelPath), ...getModifyTargetPath(targetPath)];
}
