import { Models } from '@baseModel/engine/types';
import {
  Attribute,
  ChangeEngineModel,
  ChangeModel,
  CreateElement,
  CreateSidebarElement,
  Edge,
  GraphElementResultTypes,
  ID
} from '@components/drawio/plugins/graphElements/graphElementResult';
import invert from 'lodash/invert';
import { DifferenceProperties } from '@components/drawio/plugins/eventDifference/eventDifferenceResults';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { getModelByKey, useChangeModelsSubscription } from './useChangeModelsSubscription';
import find from 'lodash/find';
import { getEnds } from '@components/drawio/utils';

export function useDrawioModel(
  dataId: string,
  changeModel?: (changeModel: ChangeModel) => void,
  changeEngineModel?: (changeEngineModel: ChangeEngineModel) => void
) {
  const entities = useChangeModelsSubscription(dataId, Models.Entity, changeModel, changeEngineModel);
  const relations = useChangeModelsSubscription(dataId, Models.Relation, changeModel, changeEngineModel);

  return { entityModelIds: entities, relationModelIds: relations };
}

export function getDrawioModelValues(modelIds: string[], model: Models): CreateElement[] {
  const res: CreateElement[] = [];

  for (let i = 0; i < modelIds.length; i++) {
    res.push(getModelValues(model, modelIds[i]));
  }

  return res;
}

export function getDrawioMetaModelValues(modelNames: string[], model: Models): CreateSidebarElement[] {
  const res: CreateSidebarElement[] = [];

  for (let i = 0; i < modelNames.length; i++) {
    res.push(getMetaModelValues(model, modelNames[i]));
  }

  return res;
}

export function changeFieldsName(fieldName: string, revers: boolean) {
  let fields: {
    [key: string]: string;
  } = {
    __id: ID,
    __name: 'label',
    __type: 'type'
  };
  if (revers) {
    fields = invert(fields);
  }

  return fields[fieldName] !== undefined ? fields[fieldName] : fieldName;
}

export function setEntityModelAttributes(
  attributes: DifferenceProperties[],
  entityModel: Entity | Relation,
  sender?: string
) {
  for (let j = 0; j < attributes.length; j++) {
    const attribute = attributes[j];
    entityModel.setFieldValue(changeFieldsName(attribute.type, true), attribute.value || '', sender);
  }
}

export function getModelValues(model: Models, modelId: string): CreateElement {
  const type = model === Models.Entity ? GraphElementResultTypes.vertex : GraphElementResultTypes.edge;

  try {
    const entityModel = getModelByKey(model, modelId);
    const fieldNames = entityModel.getFieldNames();
    const attributes: Attribute[] = [];

    for (let j = 0; j < fieldNames.length; j++) {
      const fieldName = fieldNames[j];
      const value = entityModel.getFieldValue(fieldName);

      if (!value) {
        continue;
      }

      attributes.push({ type: changeFieldsName(fieldName, false), value: value.toString() });
    }

    if (entityModel instanceof Entity || entityModel instanceof Relation) {
      if (!find(attributes, { type: 'type' })) {
        attributes.push({ type: changeFieldsName('type', false), value: entityModel.getMetaModel().getName() });
      }

      if (!find(attributes, { type: 'style' })) {
        const value = entityModel.getMetaModel().getCommonValue('style');

        if (value) {
          attributes.push({
            type: changeFieldsName('style', false),
            value: value.toString()
          });
        }
      }
    }

    const edge: Edge = {
      source: null,
      target: null
    };

    if (entityModel instanceof Relation) {
      const { metaSourceName, metaTargetName, sourceValue, targetValue } = getEnds(entityModel);

      if (metaSourceName !== undefined && sourceValue !== undefined) {
        edge.source = {
          id: sourceValue.toString(),
          name: getModelByKey(Models.Entity, sourceValue.toString()).getFieldValue('__name')?.toString() || '',
          key: metaSourceName
        };
      }

      if (metaTargetName !== undefined && targetValue !== undefined) {
        edge.target = {
          id: targetValue.toString(),
          name: getModelByKey(Models.Entity, targetValue.toString()).getFieldValue('__name')?.toString() || '',
          key: metaTargetName
        };
      }
    }

    return { type, attributes, edge };
  } catch {
    return { type, attributes: [{ type: ID, value: modelId }], edge: { source: null, target: null } };
  }
}

export function getMetaModelValues(model: Models, modelName: string): CreateSidebarElement {
  const type = model === Models.EntityMetaModel ? GraphElementResultTypes.vertex : GraphElementResultTypes.edge;

  const entityModel = getModelByKey(model, modelName);

  const attributes: Attribute[] = [
    { type: 'label', value: modelName },
    { type: 'type', value: modelName },
    { type: 'style', value: entityModel.getCommonValue('style') || '' }
  ];

  return { type, attributes };
}
