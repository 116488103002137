import { Engine } from '../../engine/engine';
import { useEffect, useState } from 'react';
import { useBlock } from './useBlock';
import { CustomNode, CustomNodeValue } from '../blocks/customNode';

const engine = Engine.getInstance();

function getCustomNodeById(customNodeId: string | undefined) {
  return customNodeId ? engine.getDocument().getCustomNodeById(customNodeId) : undefined;
}

/**
 * TODO не учтено, что таблицы могут удаляться и добавляться
 * @param customNodeId
 */
export function useCustomNode(
  customNodeId: string | undefined
): ReturnType<typeof useBlock<CustomNode, CustomNodeValue | undefined>> {
  const [customNode, setCustomNode] = useState(getCustomNodeById(customNodeId));
  useEffect(() => {
    setCustomNode(getCustomNodeById(customNodeId));
  }, [customNodeId, setCustomNode]);
  const [customNodeBlock, setValue] = useBlock<CustomNode, CustomNodeValue | undefined>(customNode);
  return [customNodeBlock, setValue];
}
