import { Engine } from '../../engine/engine';
import { useEffect, useState } from 'react';
import { useBlock } from './useBlock';
import { Table, TableBlockValue } from '../blocks/table';

const engine = Engine.getInstance();

function getTableById(tableId: string | undefined) {
  return tableId ? engine.getDocument().getTableById(tableId) : undefined;
}

/**
 * TODO не учтено, что таблицы могут удаляться и добавляться
 * @param tableId
 */
export function useTable(tableId: string | undefined): ReturnType<typeof useBlock<Table, TableBlockValue | undefined>> {
  const [table, setTable] = useState(getTableById(tableId));
  useEffect(() => {
    setTable(getTableById(tableId));
  }, [tableId, setTable]);
  const [tableBlock, setValue] = useBlock<Table, TableBlockValue | undefined>(table);
  return [tableBlock, setValue];
}
