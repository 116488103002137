import { Schema } from 'yup';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
import { BaseBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/baseBlock';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { getJSON, replaceKeys } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/utils';

export interface JsonDocumentBlock {
  id: string;
  type: string;
  config: AnyObject;
}

export class Block<T extends AnyObject = AnyObject> extends BaseBlock<T> {
  constructor(valueSchema: Schema, type: string, id: string) {
    super(valueSchema, type, id);
  }

  public static override getConfigFromJSON(json: string | JsonDocumentBlock): JsonDocumentBlock['config'] {
    const jsonValue = getJSON(json);
    const config = jsonValue.config;

    return replaceKeys(config, camelCase);
  }

  public static override getValueInJSONForYAML(json: string | JsonDocumentBlock): JsonDocumentBlock {
    const jsonValue = getJSON(json);

    return {
      id: jsonValue.id,
      type: jsonValue.type,
      config: {
        ...replaceKeys(jsonValue.config, kebabCase)
      }
    };
  }

  public static override fromJSON(json: string | JsonDocumentBlock): Block {
    const jsonObj = getJSON(json);
    const block = new Block(Block.valueSchema, jsonObj.type, jsonObj.id);

    block.setValue(Block.getConfigFromJSON(jsonObj));

    return block;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()},type=${
      this.type
    }]\n;;; ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] *here be dragons* \n;;; \n:::`;
  }
}
