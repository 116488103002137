import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { SimpleValueType } from '@baseModel/types/simpleValueType';

export enum ActionType {
  Add = 'add',
  Update = 'update'
}

export enum FormFields {
  Fields = 'fields',
  Relations = 'relations'
}

export type ActionModel = Entity | Relation;

export interface FormData {
  [FormFields.Fields]: Record<string, SimpleValueType>;
  [FormFields.Relations]: Record<string, SimpleValueType>;
}

export interface NestedTable {
  relation: string;
  endSelfName: string;
  endTargetName?: string;
}
