import { JsonSource } from '../types/jsonDescription';
import { MetaModel } from './metaModel';
import { ModelError } from '../errors';
import { Models } from '../engine/types';
import { EmptyObject } from '../utils/dataJuggler';

export class RelationMetaModel extends MetaModel<JsonSource> {
  public readonly modelType = Models.RelationMetaModel;

  protected override validateRelation(relationName: string, value?: JsonSource) {
    // Разрешаем пустое значение
    if (!value || !this.engine) {
      return;
    }
    if (!this.engine.getMetaEntityByName(value.type)) {
      const message = `entity meta ${value.type} not found`;
      this.logger(message);
      throw new ModelError(message);
    }
  }

  public override setRelationValue(relationName: string, value: JsonSource, sender?: string | EmptyObject) {
    super.setRelationValue(relationName, value, sender);
  }

  /**
   * Проверим всю модель целиком
   */
  public override validateData() {
    super.validateData();
    this.getRelationNames().map((name) => {
      this.validateRelation(name, this.getRelationValue(name));
    });
  }
}
