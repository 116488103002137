import React, { forwardRef, memo } from 'react';
import { SvgIcon, SvgIconProps } from '@ui/components/svgIcon/svgIcon';

export function createSvgIcon(path: React.ReactElement<React.SVGProps<SVGSVGElement>>, displayName: string) {
  function Component(props: SvgIconProps, ref: React.ForwardedRef<SVGSVGElement>) {
    return (
      <SvgIcon ref={ref} {...props}>
        {path}
      </SvgIcon>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`;
  }

  return memo(forwardRef(Component));
}
