import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@smwb/summer-ui';
import { DRAW_IO_DEFAULT_SOURCE_PATH, DRAW_IO_SENDER } from '../const';
import { initDrawio } from '../plugins/init';
import { DrawioFunction } from '../types/plugins';
import { getDrawioMetaModelValues, getDrawioModelValues, useDrawioModel } from '@hooks/useDrawioModel';
import { Models } from '@baseModel/engine/types';
import { useDrawioMetaModel } from '@hooks/useDrawioMetaModel';
import { SidebarContainers } from '../plugins/createSidebarElements';
import { useDiagram } from '@baseModel/document/hooks/useDiagram';
import { useStateRef } from '@hooks/useStateRef';
import { ChangeEngineModel, ChangeModel } from '../plugins/graphElements/graphElementResult';

export function Editor({ dataId, onLoadGraph }: { dataId: string; onLoadGraph?: (drawio: DrawioFunction) => void }) {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [drawio, setDrawio, drawioRef] = useStateRef<DrawioFunction | null>(null);
  const [isGraphElementsLoad, setIsGraphElementsLoad] = React.useState<boolean>(false);
  const { theme } = useTheme();
  const { entityModelIds, relationModelIds } = useDrawioModel(dataId, onChangeModel, onChangeEngineModel);
  const { entityMetaModelNames, relationMetaMetaNames } = useDrawioMetaModel(
    dataId,
    onChangeMetaModel,
    onChangeEngineMetaModel
  );

  const [preview] = useDiagram(dataId, DRAW_IO_SENDER);

  const changeGraphElements = useCallback(() => {
    if (drawio) {
      drawio.refreshSidebarContainer(SidebarContainers.engineModel);
      drawio.refreshSidebarContainer(SidebarContainers.metaModel);

      drawio.changeGraphElements(
        getDrawioModelValues(entityModelIds, Models.Entity),
        getDrawioModelValues(relationModelIds, Models.Relation)
      );

      drawio.changeSidebarMetaElements(
        getDrawioMetaModelValues(entityMetaModelNames, Models.EntityMetaModel),
        getDrawioMetaModelValues(relationMetaMetaNames, Models.RelationMetaModel)
      );
    }
  }, [drawio, entityModelIds, relationModelIds, entityMetaModelNames, relationMetaMetaNames]);

  const onLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    const window = iframeRef.current?.contentWindow;
    window && initDrawio(window, (drawio: DrawioFunction) => setDrawio(drawio));
  };

  useEffect(() => {
    if (!isGraphElementsLoad && drawio) {
      if (onLoadGraph) {
        drawio.setPreview(preview?.base64);
        onLoadGraph(drawio);
      } else {
        drawio.setPreview();
      }

      changeGraphElements();
      setIsGraphElementsLoad(true);
    }
  }, [drawio, onLoadGraph, preview?.base64, isGraphElementsLoad, changeGraphElements]);

  useEffect(() => {
    drawio?.setTheme(theme);
    setTimeout(() => {
      changeGraphElements();
    }, 1000);
  }, [drawio, theme, changeGraphElements]);

  function onChangeModel(changeModel: ChangeModel) {
    drawioRef.current?.changeModelGraphElement(changeModel);
  }

  function onChangeEngineModel(changeEngineModel: ChangeEngineModel) {
    drawioRef.current?.changeEngineModelGraphElement(changeEngineModel);
  }

  function onChangeEngineMetaModel(changeEngineMetaModel: ChangeEngineModel) {
    drawioRef.current?.changeEngineMetaModelGraphElement(changeEngineMetaModel);
  }

  function onChangeMetaModel(changeMetaModel: ChangeModel) {
    drawioRef.current?.changeMetaModelGraphElement(changeMetaModel);
  }

  return (
    <iframe
      src={!process.env.REACT_APP_DRAW_IO_PATH ? DRAW_IO_DEFAULT_SOURCE_PATH : process.env.REACT_APP_DRAW_IO_PATH}
      onLoad={onLoad}
      ref={iframeRef}
      style={{ borderStyle: 'none', width: '100%', height: '100%' }}
    />
  );
}
