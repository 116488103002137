import { ProseMirrorView } from '../proseMirrorView';
import { DocumentEvent, DocumentEventData, MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';
import { INNER_TRANSACTION_META } from '../const';
import { EditorState } from 'prosemirror-state';
import { Node } from 'prosemirror-model';
import { docForEach } from '@components/markdownEditor/utils/docForEach';

function findNodeWithPositionById(
  state: EditorState,
  id: string
):
  | {
      nodes: Node[];
      position: number;
    }
  | undefined {
  let result:
    | {
        nodes: Node[];
        position: number;
      }
    | undefined = undefined;
  let searchForNewBlock = false;
  docForEach(state.doc, (node, startBlockPos) => {
    // Если дальше идет служебный блок, удалим его тоже
    if (result?.nodes && searchForNewBlock && node.attrs.id === MARKDOWN_NEW_BLOCK_ID) {
      result.nodes.push(node);
    }
    // Если блок уже нашли, завершаем обход
    if (result?.nodes) {
      return false;
    }
    if (node.attrs.id === id) {
      if (!result) {
        result = {
          nodes: [],
          position: 0
        };
      }
      result.nodes.push(node);
      result.position = startBlockPos;
      searchForNewBlock = true;
    }
    return true;
  });
  return result;
}

export function documentRemoveHandler(proseMirror: ProseMirrorView, data: DocumentEvent<DocumentEventData>): void {
  const state = proseMirror?.getState();
  const dispatch = proseMirror?.getDispatch();
  if (!state || !dispatch) {
    console.log('instance is empty');
    return;
  }
  const deletedNode = findNodeWithPositionById(state, data.id);
  if (!deletedNode) {
    console.error('Блок с ид ' + data.id + ' не найден в документе');
    return;
  }
  const size = deletedNode.nodes.reduce((sum, el) => sum + el.nodeSize, 0);
  const transaction = state.tr.replace(deletedNode.position, deletedNode.position + size);
  // Пометим, что такую транзакцию повторно обрабатывать не надо
  transaction.setMeta(INNER_TRANSACTION_META, true);
  dispatch(transaction);
}
