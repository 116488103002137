import { combineReducers } from 'redux';
import { drawioReducers } from '@components/drawio/redux';
import { metaTableViewReducers } from '@components/metaTableView/redux';
import { tableViewReducers } from '@components/tableView/redux';
import { tableConfigurationReducers } from '@components/tableConfiguration/redux';
import { yamlEditorReducers } from '@components/yamlEditor/redux';
import { markdownEditorReducers } from '@components/markdownEditor/redux';

export const appReducer = combineReducers({
  drawio: drawioReducers,
  metaTableView: metaTableViewReducers,
  tableView: tableViewReducers,
  tableConfiguration: tableConfigurationReducers,
  yamlEditor: yamlEditorReducers,
  markdownEditor: markdownEditorReducers
});
