import { isSeq, parseDocument, stringify } from 'yaml';
import { IRange } from 'monaco-editor';
import set from 'lodash/set';
import { TextModel } from '../types';
import { Paths } from '@commonTypes/index';
import { findRange } from './findRange';
import { findStartLine } from './findStartLine';
import { startSpaceRegExp } from './helpers';
import { addText } from '@components/yamlEditor/utils/addText';

export function insertText(model: TextModel, paths: Paths, value: unknown) {
  const { rangePaths } = findRange(model, paths, { includeEmptyLines: true });
  const lastRange = rangePaths.at(-1);

  if (lastRange === undefined) {
    addText(model, paths, value);
    return;
  }

  const restPaths = paths.slice(rangePaths.length);

  let data = value;

  // Если есть оставшиеся не найденные пути, то ним устанавливаем значение по ним.
  if (restPaths.length) {
    const modifyRestPaths = restPaths.map((path) => (Number.isInteger(path) ? 0 : path));
    const firstRestSegmentPath = modifyRestPaths.at(0);

    data = set(Number.isInteger(firstRestSegmentPath) ? [] : {}, modifyRestPaths, value);
  }

  const { indentSize } = model.getOptions();
  const EOL = model.getEOL();

  if (Number.isInteger(lastRange.path)) {
    // Определяем позицию, куда будет вставляться текст.
    const range: IRange = {
      startLineNumber: lastRange.range.startLineNumber,
      startColumn: lastRange.range.startColumn,
      endLineNumber: lastRange.range.startLineNumber,
      endColumn: lastRange.range.startColumn
    };
    const indentCount = lastRange.range.startColumn - 1;

    let text = stringify([data], { indent: indentSize });

    text = text.replace(/\n$/, '');
    text = text.replace(/\n/gm, EOL);
    text = text.replace(/\n/gm, `${EOL}${''.padEnd(indentCount)}`);
    text = `${text}${EOL}${''.padEnd(indentCount)}`;

    model.pushEditOperations(null, [{ range, text }], () => null);
  } else {
    const indentStart = model.getLineFirstNonWhitespaceColumn(lastRange.valueRange.startLineNumber) + 1;

    let rangeValue = model.getValueInRange(lastRange.valueRange);
    rangeValue = rangeValue.replace(new RegExp(`^${EOL}`), '');
    rangeValue = rangeValue.replace(startSpaceRegExp(indentStart), '');

    const parseValue = parseDocument(rangeValue);

    if (!isSeq(parseValue.contents)) {
      throw new Error(`insertText: The value type must be an array along the path ${JSON.stringify(paths)}.`);
    }

    const itemsCount = parseValue.contents.items.length;

    let range: IRange;

    // Если есть элементы, то берём диапазон конца массива, иначе диапазон значения, в данном случае литерал пустого массива.
    if (itemsCount) {
      // Получаем начальную линии позицию. Это сделано для того, что если есть над найденной позицией пустые строки,
      // то захватываем их.
      const startLineNumber = findStartLine(model, lastRange.range.startLineNumber, lastRange.range.endLineNumber);
      // Вычисляем последнюю колонку, после которой нужно перевести строку и вставить текст.
      const startColumn = model.getLineMaxColumn(startLineNumber);

      range = {
        startLineNumber,
        startColumn,
        endLineNumber: lastRange.range.endLineNumber,
        endColumn: lastRange.range.endColumn
      };
    } else {
      range = lastRange.valueRange;
    }

    const fullRange = model.getFullModelRange();
    const indentCount = lastRange.range.startColumn - 1 + indentSize;
    const firstRestSegmentPath = restPaths.at(0);

    let text = stringify(Number.isInteger(firstRestSegmentPath) ? data : [data], { indent: indentSize });
    // Удаляем перевод строки т.к. это добавляет ещё строку, для того чтобы к ней не добавлять излишние отступы.
    text = text.replace(/\n$/, '');
    // Добавляем отступы каждой строке, равному количеству отступов родительской секции с добавленным отступом для
    // вложенности.
    text = text.replace(/^/gm, ''.padEnd(indentCount, ' '));
    // Заменяем все переводы строк на установленные в модели редактора.
    text = text.replace(/\n/gm, EOL);
    // Переводим текстовый блок для создания вложенности, согласно структуре yaml.
    text = `${EOL}${text}`;

    // Добавляем последнюю пустую строку в конец документа, если последняя строка диапазона значения совпадает с полной.
    if (lastRange.range.endLineNumber === fullRange.endLineNumber) {
      text = `${text}${EOL}`;
    }

    model.pushEditOperations(null, [{ range, text }], () => null);
  }
}
