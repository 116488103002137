import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { JsonDocumentTableBlockColumn } from '@baseModel/types/jsonDescription';
import { TextFieldEntityBasic, TextFieldEntityBasicEditable } from '@baseModel/inputs/textFieldEntity';
import { ToggleFieldEntityBasic } from '@baseModel/inputs/toggleEntityField';
import { getColumnValue } from './utils';
import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { highlightModelFieldByPath } from '@components/yamlEditor/plugins/hightLight/highlightModelFieldByPath';
import { highlightDocumentBlockById } from '@components/yamlEditor/plugins/hightLight/highlightDocumentBlockById';

export interface RenderCellProps {
  model: Entity | Relation;
  column: JsonDocumentTableBlockColumn;
  index?: number;
}

export function RenderCell({ model, column, index }: RenderCellProps) {
  const [columnValue, setColumnValue] = useState(getColumnValue(model, column, index));

  useEffect(() => {
    setColumnValue(getColumnValue(model, column, index));
  }, [column, index, model]);

  useEffect(() => {
    return model.subscribeModel(() => {
      const newValue = getColumnValue(model, column, index);
      // Если подходящую модель удалось найти, то надо сравнивать только ее описание
      if (
        typeof newValue !== 'string' &&
        typeof newValue !== 'number' &&
        typeof columnValue !== 'string' &&
        typeof columnValue !== 'number'
      ) {
        if (!isEqual(newValue.meta, columnValue.meta)) {
          setColumnValue(newValue);
        }
      } else if (newValue !== columnValue) {
        setColumnValue(newValue);
      }
    });
  }, [column, columnValue, index, model]);

  if (typeof columnValue === 'string' || typeof columnValue === 'number') {
    return <span>{columnValue}</span>;
  }
  const meta = columnValue.meta;
  if (!meta.modelName) {
    return <span>model not found</span>;
  }

  const onTextFieldFocus = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    e.stopPropagation();
    const { dataStoreName, fieldName, modelType, modelName = '' } = meta;
    highlightModelFieldByPath(modelType, modelName, dataStoreName, fieldName);
    highlightDocumentBlockById();
  };

  if (!meta) {
    return <span>unknown error</span>;
  }
  if (meta.type === 'boolean') {
    return (
      <ToggleFieldEntityBasic
        fieldName={meta.fieldName}
        modelName={meta.modelName}
        dataStoreName={meta.dataStoreName}
        modelType={meta.modelType}
        onFocus={onTextFieldFocus}
      />
    );
  }
  if (localStorage.tableEditable !== '0') {
    return (
      <TextFieldEntityBasicEditable
        fullWidth
        inline
        fieldName={meta.fieldName}
        modelName={meta.modelName}
        dataStoreName={meta.dataStoreName}
        modelType={meta.modelType}
        onFocus={onTextFieldFocus}
      />
    );
  }
  return (
    <TextFieldEntityBasic
      fullWidth
      inline
      fieldName={meta.fieldName}
      modelName={meta.modelName}
      dataStoreName={meta.dataStoreName}
      modelType={meta.modelType}
      onFocus={onTextFieldFocus}
    />
  );
}
