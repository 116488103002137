import { Node } from 'prosemirror-model';
import { markdownExtendParser } from '../utils/markdownExtendParser';
import { ProseMirrorView } from '../proseMirrorView';
import { DocumentEvent, DocumentEventData } from '../../../baseModel/document/document';
import { DOCUMENT_BLOCK_TAG, INNER_TRANSACTION_META } from '../const';
import { BlockType } from '../../../baseModel/document/blocks/types';

export function documentUpdateHandler(proseMirror: ProseMirrorView, data: DocumentEvent<DocumentEventData>): void {
  const state = proseMirror?.getState();
  const dispatch = proseMirror?.getDispatch();
  if (!state || !dispatch) {
    console.error('instance is empty');
    return;
  }
  if (data.type !== BlockType.markdown) {
    return;
  }
  const id = data.id;
  const results: Node[] = [];
  let startPos = 0;
  state.doc.descendants((node, pos) => {
    if (node.attrs.id === id) {
      startPos = pos;
      results.push(node);
    }
  });
  if (results.length > 1) {
    console.error('found more than one node with id', id);
  }
  if (results.length === 0) {
    console.error('node with id not found', id);
  }
  const content = data.data;
  const wrappedContent = `\n::: ${DOCUMENT_BLOCK_TAG} [id=${id}]\n${content}\n:::`;
  const doc = markdownExtendParser.parse(wrappedContent);
  const node: Node = results[0];
  if (doc?.content) {
    // Пометим, что такую транзакцию повторно обрабатывать не надо
    const transaction = state.tr.replaceWith(startPos, startPos + node.nodeSize, doc.content);
    transaction.setMeta(INNER_TRANSACTION_META, true);
    dispatch(transaction);
  } else {
    console.error(`fragment error`);
  }
}
