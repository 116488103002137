import { DrawioContext } from '../types/window';
import {
  ChangeEngineModel,
  ChangeModel,
  CreateElement,
  CreateSidebarElement,
  GraphElementResultTypes,
  ID
} from './graphElements/graphElementResult';
import { loadEditElementByIdPlugin, loadFindElementByIdPlugin, loadSetAttributeByIdPlugin } from './edit';
import {
  loadCreateSidebarElementsPlugin,
  removeElementInListSidebarEngineModelElements,
  removeElementInListSidebarMetaModelElements,
  SidebarContainers
} from './createSidebarElements';
import find from 'lodash/find';
import { ModelEventTypes, Models } from '@baseModel/engine/types';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import { changeFieldsName, getMetaModelValues, getModelValues } from '@hooks/useDrawioModel';
import { loadCheckEdgeCellsPlugin, loadCheckEngineCellsPlugin, loadRemoveCellPlugin } from './errors';
import { Engine } from '@baseModel/engine/engine';
import { getEnds } from '@components/drawio/utils';
import { isArrowEnd } from '@components/drawio/plugins/eventDifference/eventDifferenceTerminal';

const engine = Engine.getInstance();

export function loadChangeGraphElementsPlugin(this: DrawioContext) {
  const changeGraphElement = loadChangeGraphElementPlugin.apply(this);
  const checkEngineCells = loadCheckEngineCellsPlugin.apply(this);

  return (entities: CreateElement[], relations: CreateElement[]) => {
    const elements = [...entities, ...relations];

    for (let i = 0; i < elements.length; i++) changeGraphElement(elements[i]);

    checkEngineCells(elements);
  };
}

export function loadChangeGraphElementPlugin(this: DrawioContext) {
  const findElementById = loadFindElementByIdPlugin.apply(this);
  const createSidebarElement = loadCreateSidebarElementsPlugin.apply(this);
  const setAttributeById = loadSetAttributeByIdPlugin.apply(this);
  const checkEdgeCells = loadCheckEdgeCellsPlugin.apply(this);
  const mxUtils = this.mxUtils;

  return (element: CreateElement) => {
    const attrId = element.attributes.find((attribute) => attribute.type === ID);
    if (attrId) {
      const cell = findElementById(attrId.value, element.type);
      if (cell) {
        checkEdgeCells(cell, element.edge.target?.id || null, element.edge.source?.id || null);

        for (let i = 0; i < element.attributes.length; i++) {
          const attr = element.attributes[i];
          setAttributeById(attrId.value, attr.type, attr.value, element.type);
        }
        const node = <HTMLElement>cell?.value;
        if (mxUtils.isNode(node)) {
          for (let i = 0; i < node.attributes.length; i++) {
            const name = node.attributes.item(i)?.name;
            if (name && !find(element.attributes, { type: name }))
              setAttributeById(attrId.value, name, null, element.type);
          }
        }

        return;
      }
    }

    createSidebarElement(element, SidebarContainers.engineModel);
  };
}

export function loadChangeSidebarMetaElementsPlugin(this: DrawioContext) {
  const changeSidebarMetaElement = loadChangeSidebarMetaElementPlugin.apply(this);

  return (entities: CreateSidebarElement[], relations: CreateSidebarElement[]) => {
    const elements = [...entities, ...relations];

    for (let i = 0; i < elements.length; i++) changeSidebarMetaElement(elements[i]);
  };
}

export function loadChangeSidebarMetaElementPlugin(this: DrawioContext) {
  const createSidebarElement = loadCreateSidebarElementsPlugin.apply(this);

  return (element: CreateSidebarElement) => {
    createSidebarElement(element, SidebarContainers.metaModel);
  };
}

export function loadChangeModelGraphElementPlugin(this: DrawioContext) {
  const setAttributeById = loadSetAttributeByIdPlugin.apply(this);
  const findElementById = loadFindElementByIdPlugin.apply(this);
  const checkEdgeCells = loadCheckEdgeCellsPlugin.apply(this);

  return (element: ChangeModel) => {
    const type = element.model === Models.Entity ? GraphElementResultTypes.vertex : GraphElementResultTypes.edge;
    switch (element.value.fieldType) {
      case DataStoreName.fields: {
        setAttributeById(
          element.modelKey,
          changeFieldsName(element.value.fieldName, false),
          element.value.value?.toString() || null,
          type
        );
        break;
      }
      case DataStoreName.relations: {
        const edge = findElementById(element.modelKey, GraphElementResultTypes.edge);

        if (!edge) break;

        const relationModel = engine.getRelationById(element.modelKey);
        const { metaTargetName, targetValue, sourceValue } = getEnds(relationModel);

        const isArrow = isArrowEnd(edge);
        const valueId = element.value.value?.toString() || '';

        if (isArrow) {
          const isTarget = element.value.fieldName === metaTargetName;

          const target = isTarget ? valueId : edge.target?.getAttribute(ID, null) || null;
          const source = isTarget ? edge.source?.getAttribute(ID, null) || null : valueId;

          checkEdgeCells(edge, target, source);
        } else {
          const targetId = edge.target?.getAttribute(ID, null) || null;

          checkEdgeCells(
            edge,
            (targetValue === targetId ? targetValue : sourceValue)?.toString() || null,
            (targetValue === targetId ? sourceValue : targetValue)?.toString() || null
          );
        }
        break;
      }
    }
  };
}

export function loadChangeEngineModelGraphElementPlugin(this: DrawioContext) {
  const editElementById = loadEditElementByIdPlugin.apply(this);
  const changeGraphElement = loadChangeGraphElementPlugin.apply(this);
  const removeCell = loadRemoveCellPlugin.apply(this);

  return (element: ChangeEngineModel) => {
    const type = element.model === Models.Entity ? GraphElementResultTypes.vertex : GraphElementResultTypes.edge;
    const sidebarElement = getModelValues(element.model, element.modelKey);
    switch (element.value.event) {
      case ModelEventTypes.add: {
        changeGraphElement(sidebarElement);
        break;
      }
      case ModelEventTypes.remove: {
        editElementById(element.modelKey, (cell) => removeCell(cell), type);
        removeElementInListSidebarEngineModelElements(sidebarElement);
        break;
      }
    }
  };
}

export function loadChangeEngineMetaModelGraphElementPlugin(this: DrawioContext) {
  const changeSidebarMetaElement = loadChangeSidebarMetaElementPlugin.apply(this);

  return (element: ChangeEngineModel) => {
    const sidebarElement = getMetaModelValues(element.model, element.modelKey);
    switch (element.value.event) {
      case ModelEventTypes.add: {
        changeSidebarMetaElement(sidebarElement);
        break;
      }
      case ModelEventTypes.remove: {
        removeElementInListSidebarMetaModelElements(sidebarElement);
        break;
      }
    }
  };
}

export function loadChangeMetaModelGraphElementPlugin(this: DrawioContext) {
  const changeSidebarMetaElement = loadChangeSidebarMetaElementPlugin.apply(this);

  return (element: ChangeModel) => {
    const sidebarElement = getMetaModelValues(element.model, element.modelKey);
    changeSidebarMetaElement(sidebarElement);
  };
}
