import { EventDifferenceAs, EventDifferenceResultsChild, getTypeCell } from './eventDifferenceResults';
import { ID } from '../graphElements/graphElementResult';
import { MxChild } from '../../types/mxChildChange';

export function getEventDifferenceChild(change: MxChild): EventDifferenceResultsChild {
  return {
    id: <string>change.child.id,
    _id: change.child.getAttribute(ID, null),
    type: getTypeCell(change.child),
    as: EventDifferenceAs.child,
    index: { value: change.index !== undefined ? change.index : null, previous: change.previousIndex },
    typeAttribute: change.child.getAttribute('type')
  };
}
