import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import {
  JsonDocumentTableBlockColumn,
  JsonDocumentTableBlockColumnTypes,
  JsonField
} from '@baseModel/types/jsonDescription';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';

import { Engine } from '@baseModel/engine/engine';
import { Models } from '@baseModel/engine/types';
import { SimpleValueType } from '@baseModel/types/simpleValueType';
import debounce from 'lodash/debounce';
import { ColumnSizingState } from '@tanstack/react-table';
import cloneDeep from 'lodash/cloneDeep';
import { Table, TableBlockValue } from '@baseModel/document/blocks/table';

const engine = Engine.getInstance();

export const REFLECTION_TAG = 'this';

export function getColumnHeader(metaModel: EntityMetaModel | RelationMetaModel, column: JsonDocumentTableBlockColumn) {
  if (column['display-name']) {
    return column['display-name'];
  }
  const pathStr = column.value;
  if (!pathStr) {
    return 'empty header';
  }
  const path = pathStr.split('.');
  if (path[0] !== REFLECTION_TAG) {
    return pathStr;
  }

  try {
    const ref = metaModel.getValueRefByPath(path.slice(1).join('.'));
    const value = ref.getter();

    if (value === undefined) {
      return 'Не найдено';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (value['display-name']) {
      return value['display-name'];
    }
    return JSON.stringify(value);
  } catch (e) {
    console.error(e);
    return 'Не найдено';
  }
}

export function getColumnValue(model: Entity | Relation, column: JsonDocumentTableBlockColumn, rowIndex?: number) {
  if (column.type === JsonDocumentTableBlockColumnTypes.autonumber) {
    if (rowIndex === undefined) {
      return -1;
    }
    return rowIndex + 1;
  }

  const pathStr = column.value;
  if (!pathStr) {
    return 'empty value';
  }
  const path = pathStr.split('.');
  if (path[0] !== REFLECTION_TAG) {
    return pathStr;
  }
  try {
    // TODO добавить текущую модель?? для релейшен не понимает где брать связанные значения
    return model.getValueRefByPath(path.splice(1).join('.'));
  } catch (e) {
    console.error(e);
    return 'Не найдено';
  }
}

export function validationField(config: JsonField) {
  return (value: SimpleValueType) => {
    if (config.required && !value) {
      return 'Обязательное поле.';
    }

    if (config.type === 'integer' && value && !/^\d*$/.test(value.toString())) {
      return 'Поле должно быть числом.';
    }

    return undefined;
  };
}

export enum ACTION_COLUMN {
  actionsLeft = 'actions_left',
  actionsRight = 'actions_right'
}

const DEBOUNCE_TIME = 1000;

function getCloneTableBlockValue(tableId: string): [Table, TableBlockValue] | [] {
  const tableBlock = engine.getDocument().getTableById(tableId);
  const tableBlockValue = tableBlock?.getValue();
  if (!tableBlock || !tableBlockValue) {
    console.error(`Таблица ${tableId} не найдена`);
    return [];
  }
  const tableBlockValueCopy = cloneDeep(tableBlockValue);
  return [tableBlock, tableBlockValueCopy];
}

// function getColumnIndex(index: string): number {
//   return +index.replace(/_.*/g, '');
// }

export const setColumnSizeDebounce = debounce((tableId: string, newSize: ColumnSizingState, depth: number) => {
  const [tableBlock, tableBlockValue] = getCloneTableBlockValue(tableId);
  if (!tableBlock || !tableBlockValue) {
    return;
  }
  let columns = tableBlockValue.columns;
  while (depth > 0) {
    depth--;
    const linkColumns = tableBlockValue.showLink?.table.columns;
    if (!linkColumns) {
      console.error(`showLink для depth ${depth} не существует`);
      return;
    }
    columns = linkColumns;
  }
  Object.keys(newSize).forEach((columnName) => {
    const columnIndexNumber = columns.findIndex(
      (el) => getColumnName(tableBlockValue.modelType, tableBlockValue.rowType, el) === columnName
    );
    if (columns[columnIndexNumber]) {
      columns[columnIndexNumber] = {
        ...columns[columnIndexNumber],
        size: newSize[columnName]
      };
    }
  });
  tableBlock.setValue(tableBlockValue);
}, DEBOUNCE_TIME);

export function getColumnName(
  modelType: Models.Entity | Models.Relation,
  rowType: string,
  column: JsonDocumentTableBlockColumn
): string {
  if (column.name) {
    return column.name;
  }
  const isEntity = modelType === Models.Entity;
  const metaModel = isEntity ? engine.getMetaEntityByName(rowType) : engine.getMetaRelationByName(rowType);
  return getColumnHeader(metaModel, column);
}

export const setColumnOrderDebounce = debounce((tableId: string, newOrder: string[], depth: number) => {
  const [tableBlock, tableBlockValue] = getCloneTableBlockValue(tableId);
  if (!tableBlock || !tableBlockValue) {
    return;
  }
  let columns = tableBlockValue.columns;

  while (depth > 0) {
    depth--;
    const linkColumns = tableBlockValue.showLink?.table.columns;
    if (!linkColumns) {
      console.error(`showLink для depth ${depth} не существует`);
      return;
    }
    columns = linkColumns;
  }
  const orderColumn = newOrder.filter(
    (el) => ![ACTION_COLUMN.actionsLeft, ACTION_COLUMN.actionsRight].find((actionColumn) => actionColumn === el)
  );
  const columnCpy: JsonDocumentTableBlockColumn[] = [];
  columns.forEach((el) => {
    columnCpy[orderColumn.indexOf(getColumnName(tableBlockValue.modelType, tableBlockValue.rowType, el))] = el;
  });
  columnCpy.forEach((el, i) => {
    columns[i] = el;
  });
  tableBlock.setValue(tableBlockValue);
}, DEBOUNCE_TIME);
