import { Models } from '@baseModel/engine/types';
import { getModelByKey } from './useChangeModelsSubscription';
import React, { useEffect } from 'react';

export function useDrawioMetaModelFieldNames(model: Models, key: string) {
  const entityModel = getModelByKey(model, key);

  const [fieldNames, setFieldNames] = React.useState<string[]>(entityModel.getFieldNames());
  const [relationNames, setRelationNames] = React.useState<string[]>(entityModel.getRelationNames());

  useEffect(() => {
    return entityModel.subscribeModel(() => {
      setFieldNames(entityModel.getFieldNames());
      setRelationNames(entityModel.getRelationNames());
    });
  }, [entityModel]);

  return { entityModel, fieldNames, relationNames };
}
