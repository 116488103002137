import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from './reducers/appReducer';
import { pageReducer } from './reducers/pageReducer';

export const store = configureStore({
  reducer: {
    app: appReducer,
    pages: pageReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
