import { DrawioContext, WindowDrawio } from '../types/window';
import { DrawioFunction } from '../types/plugins';
import { App, Draw, Graph } from '../types/draw';
import { themePlugin } from './theme';
import { MxUtils } from '../types/mxUtils';
import { MxCodec } from '../types/mxCodec';
import { MxEvent } from '../types/mxEvent';
import { MxRootChange } from '../types/mxRootChange';
import { MxStyleChange } from '../types/mxStyleChange';
import { MxValueChange } from '../types/mxValueChange';
import { MxCellClass } from '../types/mxCell';
import { MxGeometryClass } from '../types/mxGeometry';
import { MxPointClass } from '../types/mxPoint';
import { MxClient } from '../types/mxClient';
import { LocalFile } from '../types/localFile';
import { Editor } from '../types/editor';
import { previewPlugin } from './preview';
import { loadGetGraphElementsPlugin } from './graphElements';
import { loadEventsPlugin } from './events';
import { loadEditElementByIdPlugin, loadFindElementByIdPlugin, loadSetAttributeByIdPlugin } from './edit';
import { loadCreateSidebarElementsPlugin, loadRefreshSidebarContainerPlugin } from './createSidebarElements';
import {
  loadChangeEngineMetaModelGraphElementPlugin,
  loadChangeEngineModelGraphElementPlugin,
  loadChangeGraphElementPlugin,
  loadChangeGraphElementsPlugin,
  loadChangeMetaModelGraphElementPlugin,
  loadChangeModelGraphElementPlugin,
  loadChangeSidebarMetaElementPlugin,
  loadChangeSidebarMetaElementsPlugin
} from './changeGraphElements';
import { MxChildChange } from '../types/mxChildChange';
import { MxTerminalChange } from '../types/mxTerminalChange';
import { addListenerCellConnection, addListenerCellsInserted } from './eventListeners';
import { getTooltipForCellOverridePlugin } from './tooltip';
import { loadDataDialogPlugin } from './dataDialog';
import { MxResources } from '../types/mxResources';
import { MxConstants } from '../types/mxConstants';
import { MxLog } from '../types/mxLog';

export function initDrawio(window: WindowDrawio, onLoad?: (drawio: DrawioFunction) => void) {
  const draw = window.Draw;

  if (!draw) return;

  draw.loadPlugin((EditorUi: App) => {
    const context: DrawioContext = {
      Draw: <Draw>window.Draw,
      Graph: <Graph>window.Graph,
      mxUtils: <MxUtils>window.mxUtils,
      LocalFile: <LocalFile>window.LocalFile,
      mxCodec: <MxCodec>window.mxCodec,
      mxEvent: <MxEvent>window.mxEvent,
      mxRootChange: <MxRootChange>window.mxRootChange,
      mxChildChange: <MxChildChange>window.mxChildChange,
      mxStyleChange: <MxStyleChange>window.mxStyleChange,
      mxValueChange: <MxValueChange>window.mxValueChange,
      mxTerminalChange: <MxTerminalChange>window.mxTerminalChange,
      mxCell: <MxCellClass>window.mxCell,
      mxGeometry: <MxGeometryClass>window.mxGeometry,
      mxPoint: <MxPointClass>window.mxPoint,
      Editor: <Editor>window.Editor,
      mxClient: <MxClient>window.mxClient,
      mxResources: <MxResources>window.mxResources,
      mxConstants: <MxConstants>window.mxConstants,
      mxLog: <MxLog>window.mxLog,
      EditorUi: EditorUi,
      window
    };

    window.EditDataDialog = loadDataDialogPlugin(context);
    addListenerCellsInserted.apply(context);
    addListenerCellConnection.apply(context);
    getTooltipForCellOverridePlugin.apply(context);

    const createSidebarElement = loadCreateSidebarElementsPlugin.apply(context);
    const setTheme = themePlugin.apply(context);
    const setPreview = previewPlugin.apply(context);
    const getGraphElements = loadGetGraphElementsPlugin.apply(context);
    const getEventsListener = loadEventsPlugin.apply(context);
    const editElementById = loadEditElementByIdPlugin.apply(context);
    const setAttributeById = loadSetAttributeByIdPlugin.apply(context);
    const findElementById = loadFindElementByIdPlugin.apply(context);
    const changeGraphElements = loadChangeGraphElementsPlugin.apply(context);
    const changeGraphElement = loadChangeGraphElementPlugin.apply(context);
    const changeSidebarMetaElements = loadChangeSidebarMetaElementsPlugin.apply(context);
    const changeSidebarMetaElement = loadChangeSidebarMetaElementPlugin.apply(context);
    const changeModelGraphElement = loadChangeModelGraphElementPlugin.apply(context);
    const changeEngineModelGraphElement = loadChangeEngineModelGraphElementPlugin.apply(context);
    const changeEngineMetaModelGraphElement = loadChangeEngineMetaModelGraphElementPlugin.apply(context);
    const changeMetaModelGraphElement = loadChangeMetaModelGraphElementPlugin.apply(context);
    const refreshSidebarContainer = loadRefreshSidebarContainerPlugin.apply(context);

    setPreview();

    onLoad?.({
      setTheme,
      setPreview,
      getGraphElements,
      getEventsListener,
      editElementById,
      setAttributeById,
      findElementById,
      createSidebarElement,
      changeGraphElements,
      changeGraphElement,
      changeSidebarMetaElements,
      changeSidebarMetaElement,
      changeModelGraphElement,
      changeEngineModelGraphElement,
      changeEngineMetaModelGraphElement,
      changeMetaModelGraphElement,
      refreshSidebarContainer,
      window
    });
  });
}
