import { MarkdownSerializer, MarkdownSerializerState } from 'prosemirror-markdown';
import { Node, Mark } from 'prosemirror-model';

// Определяем функцию serializeCell, которая будет сериализовать ячейку.
function serializeCell(state: MarkdownSerializerState, cell: Node) {
  state.write(cell.textContent);
}

// Определяем функцию serializeRow, которая будет сериализовать ряд таблицы.
function serializeRow(state: MarkdownSerializerState, row: Node) {
  const cells = row.content;
  state.write('| ');
  cells.forEach((cell) => {
    serializeCell(state, cell);
    state.write(' |');
  });
  state.write('\n');
}

// Определяем функцию serializeTable, которая будет сериализовать всю таблицу.
function serializeTable(state: MarkdownSerializerState, table: Node) {
  const header = table.content.firstChild;
  const rows: Node[] = [];
  table.content.forEach((node, index) => {
    // Заголовок пропустим
    if (index !== 0) {
      rows.push(node);
    }
  });
  const colCount = header?.content.childCount || 0;

  // Записываем первую строку таблицы, содержащую заголовки столбцов.
  state.write('| ');
  header?.forEach((cell) => {
    serializeCell(state, cell);
    state.write(' | ');
  });
  state.write('\n');

  // Записываем разделительную строку между заголовком и содержимым таблицы.
  state.write('|');
  for (let i = 0; i < colCount; i++) {
    state.write(' --- |');
  }
  state.write('\n');

  // Записываем содержимое таблицы.
  rows.forEach((row) => serializeRow(state, row));
}

function isPlainURL(link: Mark, parent: Node, index: number) {
  if (link.attrs.title || !/^\w+:/.test(link.attrs.href as string)) return false;
  const content = parent.child(index);
  if (!content.isText || content.text != link.attrs.href || content.marks[content.marks.length - 1] != link)
    return false;
  return index == parent.childCount - 1 || !link.isInSet(parent.child(index + 1).marks);
}

function backticksFor(node: Node, side: number) {
  // eslint-disable-next-line prefer-const
  let ticks = /`+/g,
    m,
    len = 0;
  if (node.isText) while ((m = ticks.exec(<string>node.text))) len = Math.max(len, m[0].length);
  let result = len > 0 && side > 0 ? ' `' : '`';
  for (let i = 0; i < len; i++) result += '`';
  if (len > 0 && side < 0) result += ' ';
  return result;
}

export const markdownExtendSerializer = new MarkdownSerializer(
  {
    externalNode(state, node) {
      node.content.forEach((node2) => {
        node2.content.forEach((node3) => {
          state.write(node3.textContent);
          state.ensureNewLine();
        });
      });
    },
    blockquote(state, node) {
      state.wrapBlock('> ', null, node, () => state.renderContent(node));
    },
    table(state: MarkdownSerializerState, node: Node) {
      serializeTable(state, node);
    },
    table_row(state, node) {
      state.renderContent(node);
    },
    table_cell(state, node) {
      state.renderContent(node);
    },
    table_header(state, node) {
      state.renderContent(node);
    },

    code_block(state, node) {
      // Make sure the front matter fences are longer than any dash sequence within it
      const backticks = node.textContent.match(/`{3,}/gm);
      const fence = backticks ? backticks.sort().slice(-1)[0] + '`' : '```';

      state.write(fence + ((node.attrs.params as string) || '') + '\n');
      state.text(node.textContent, false);
      state.ensureNewLine();
      state.write(fence);
      state.closeBlock(node);
    },
    heading(state, node) {
      state.write(state.repeat('#', node.attrs.level as number) + ' ');
      state.renderInline(node);
      state.closeBlock(node);
    },
    horizontal_rule(state, node) {
      state.write((node.attrs.markup as string) || '---');
      state.closeBlock(node);
    },
    bullet_list(state, node) {
      state.renderList(node, '  ', () => ((node.attrs.bullet as string) || '*') + ' ');
    },
    ordered_list(state, node) {
      const start = (node.attrs.order as number) || 1;
      const maxW = String(start + node.childCount - 1).length;
      const space = state.repeat(' ', maxW + 2);
      state.renderList(node, space, (i) => {
        const nStr = String(start + i);
        return state.repeat(' ', maxW - nStr.length) + nStr + '. ';
      });
    },
    list_item(state, node) {
      state.renderContent(node);
    },
    paragraph(state, node) {
      state.renderInline(node);
      state.closeBlock(node);
    },

    image(state, node) {
      state.write(
        '![' +
          state.esc((node.attrs.alt as string) || '') +
          '](' +
          // eslint-disable-next-line no-useless-escape
          (node.attrs.src as string).replace(/[\(\)]/g, '\\$&') +
          ((node.attrs.title as string) ? ' "' + (node.attrs.title as string).replace(/"/g, '\\"') + '"' : '') +
          ')'
      );
    },
    hard_break(state, node, parent, index) {
      for (let i = index + 1; i < parent.childCount; i++)
        if (parent.child(i).type != node.type) {
          state.write('\\\n');
          return;
        }
    },
    text(state, node) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion
      state.text(node.text!, !(state as any).inAutolink);
    }
  },
  {
    em: { open: '*', close: '*', mixable: true, expelEnclosingWhitespace: true },
    strong: { open: '**', close: '**', mixable: true, expelEnclosingWhitespace: true },
    link: {
      open(state, mark, parent, index) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (state as any).inAutolink = isPlainURL(mark, parent, index);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        return (state as any).inAutolink ? '<' : '[';
      },
      close(state, mark) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
        const { inAutolink } = state as any;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (state as any).inAutolink = undefined;
        return inAutolink
          ? '>'
          : '](' +
              // eslint-disable-next-line no-useless-escape
              (mark.attrs.href as string).replace(/[\(\)"]/g, '\\$&') +
              (mark.attrs.title ? ` "${(mark.attrs.title as string).replace(/"/g, '\\"')}"` : '') +
              ')';
      },
      mixable: true
    },
    code: {
      open(_state, _mark, parent, index) {
        return backticksFor(parent.child(index), -1);
      },
      close(_state, _mark, parent, index) {
        return backticksFor(parent.child(index - 1), 1);
      },
      escape: false
    }
  }
);
