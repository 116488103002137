import { Block } from './block';

import { BlockType } from './types';
import { JsonDocumentDiagramBlock } from '../../types/jsonDescription';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
// import { MARKDOWN_NEW_LINE_HELPER } from '../document';
import { object, ObjectSchema, string, array } from 'yup';
import omit from 'lodash/omit';

export interface DiagramBlockValue extends Omit<JsonDocumentDiagramBlock, 'allowed-types' | 'type' | 'id'> {
  allowedTypes: string[];
}

export const diagramContainsSchema = array().of(object()).required();
export const diagramContainsItemSchema = array().of(string()).required();

const diagramSchema: ObjectSchema<DiagramBlockValue> = object().shape({
  allowedTypes: array().of(string().required()).required(),
  base64: string().defined(),
  contains: diagramContainsSchema
});

export class Diagram extends Block<DiagramBlockValue> {
  public override valueSchema = diagramSchema;

  constructor(id: string) {
    super(BlockType.diagram, id);
  }

  public static override getValueFromJSON(json: string | JsonDocumentDiagramBlock): DiagramBlockValue {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentDiagramBlock) : json;
    const allowedTypes = jsonObj['allowed-types'];
    return { ...omit(jsonObj, ['id', 'type']), allowedTypes };
  }

  public static override fromJSON(json: string | JsonDocumentDiagramBlock): Diagram {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentDiagramBlock) : json;
    const diagram = new Diagram(jsonObj.id);
    diagram.setValue(Diagram.getValueFromJSON(jsonObj));
    return diagram;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()},type=${
      this.type
    }]\n;;; ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] *here be dragons* \n;;; \n:::`;
    // Убрал, т.к. ломает много
    // return `\n::: ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] \n:::${MARKDOWN_NEW_LINE_HELPER}`;
  }
}
