import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { BlockControlsWidget } from '../widgets/blockControls';
import { Engine } from '@baseModel/engine/engine';
import { store } from '../../../redux/store';
import { Table } from '@baseModel/document/blocks/table';
import { editTableOpen } from '@components/tableConfiguration/redux/actions';
import { getWidget } from '@components/markdownEditor/dataDisplayWidgets/utils';
import { openConfigBlock } from '@components/markdownEditor/redux/markdownEditor';

const engine = Engine.getInstance();

export const blockControlsPluginKey = new PluginKey<DecorationSet>('blockControlsPlugin');

export interface AddIconActions {
  pos: number;
}

export interface BlockControlsActions {
  blockId: string;
  addIcon?: AddIconActions;
}

export const blockControlsPlugin = new Plugin({
  key: blockControlsPluginKey,
  state: {
    init: () => DecorationSet.empty,
    apply(tr, set) {
      const action = tr.getMeta(blockControlsPluginKey) as BlockControlsActions;
      if (action && action.addIcon) {
        const block = engine.getDocument().getBlockById(action.blockId);
        if (!block) {
          return DecorationSet.empty;
        }

        let onEditClick: () => void | undefined;

        if (block instanceof Table) {
          onEditClick = () => {
            store.dispatch(editTableOpen({ editableTableId: action.blockId }));
          };
        } else {
          const Widget = getWidget(block.type);

          if (Widget) {
            const widget = new Widget();

            if (widget.getConfigView() !== null) {
              onEditClick = () => {
                store.dispatch(openConfigBlock({ id: block.getId(), type: block.type }));
              };
            }
          }
        }

        const deco = Decoration.widget(
          action.addIcon.pos,
          () => new BlockControlsWidget().toDOM(action.blockId, block.type, onEditClick),
          { blockId: action.blockId }
        );
        const empty = DecorationSet.empty;
        return empty.add(tr.doc, [deco]);
      } else {
        return set;
      }
    }
  },
  props: {
    decorations(state) {
      return this.getState(state);
    }
  }
});
