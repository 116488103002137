import { Engine } from '../../engine/engine';
import { useEffect, useState } from 'react';
import { useBlock } from './useBlock';
import { Diagram, DiagramBlockValue } from '../blocks/diagram';

const engine = Engine.getInstance();

function getDiagramById(tableId: string | undefined) {
  return tableId ? engine.getDocument().getDiagramById(tableId) : undefined;
}

/**
 * TODO не учтено, что таблицы могут удаляться и добавляться
 * @param diagramId
 * @param customSender
 */
export function useDiagram(
  diagramId: string | undefined,
  customSender?: string
): ReturnType<typeof useBlock<Diagram, DiagramBlockValue | undefined>> {
  const [diagram, setDiagram] = useState(getDiagramById(diagramId));
  useEffect(() => {
    setDiagram(getDiagramById(diagramId));
  }, [diagramId, setDiagram]);
  const [diagramBlock, setValue] = useBlock<Diagram, DiagramBlockValue | undefined>(diagram, customSender);
  return [diagramBlock, setValue];
}
