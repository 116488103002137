import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import s from './contentList.module.less';
import { useAppDispatch } from '../../../redux/store';
import { highlightDocumentBlockById } from '@components/markdownEditor/redux/markdownEditor';

export interface ContentItemProps {
  id: string;
  text: string;
  level: number;
}

export function ContentItem({ id, text, level }: ContentItemProps) {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onClick = () => {
    setSearchParams((params) => {
      params.set('blockId', id);
      return params;
    });

    dispatch(highlightDocumentBlockById(id));
  };

  return (
    <li className={cn(s.cardListItem, level === 2 && s.cardListItemIndent)} onClick={onClick}>
      {text}
    </li>
  );
}
