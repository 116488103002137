import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTable } from '@baseModel/document/hooks/useTable';
import { useModels } from '@baseModel/hooks/useModels';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { AddOrUpdateShellForm } from './addOrUpdateShellForm/addOrUpdateShellForm';
import { Table } from './table/table';
import { useDiagram } from '@baseModel/document/hooks/useDiagram';
import intersection from 'lodash/intersection';

const engine = Engine.getInstance();

interface TableViewProps {
  id: string;
}

export const TableView = memo(({ id }: TableViewProps) => {
  const [tableConfig] = useTable(id);
  const [modelType, setModelType] = useState<Models.Entity | Models.Relation | undefined>(tableConfig?.modelType);
  const modelIds = useModels(tableConfig?.modelType, tableConfig?.rowType);
  // console.log("dd", modelIds, configId);
  const [diagram] = useDiagram(tableConfig?.filter?.diagram);

  const models = useMemo(() => {
    const diagramFilter = tableConfig?.filter?.diagram;
    let ids: string[] = [];
    if (!diagramFilter || !diagram) {
      ids = modelIds;
    } else {
      ids = intersection(diagram.contains[0]?.[tableConfig.rowType] || [], modelIds);
    }
    const isEntity = modelType === Models.Entity;
    return ids.map((modelId) => (isEntity ? engine.getEntityById(modelId) : engine.getRelationById(modelId))) as
      | Entity[]
      | Relation[];
  }, [tableConfig?.filter?.diagram, tableConfig?.rowType, diagram, modelType, modelIds]);

  useEffect(() => {
    setModelType(tableConfig?.modelType);
  }, [tableConfig?.modelType]);

  if (!tableConfig) {
    return <div>Table config is empty</div>;
  }

  return (
    <>
      <Table
        configId={id}
        modelType={modelType as Models.Entity | Models.Relation}
        rowType={tableConfig.rowType}
        columns={tableConfig.columns}
        showLink={tableConfig.showLink}
        models={models}
      />
      <AddOrUpdateShellForm configId={id} />
    </>
  );
});
