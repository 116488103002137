import { EventDifferenceResults } from '../plugins/eventDifference/eventDifferenceResults';

export interface Subscriber {
  event: DrawEvents;
  handler: (data: string | EventDifferenceResults[]) => void;
}

export interface EventsListener {
  readonly addListener: (subscriber: Subscriber) => Unsubscribe;
  readonly removeAllListener: () => void;
}

export type Unsubscribe = () => void;

export const enum DrawEvents {
  changeElementsXml = 'changeElementsXml',
  changeModelXml = 'changeModelXml',
  changeElementsBase64 = 'changeElementsBase64',
  changeModelBase64 = 'changeModelBase64',
  changeModelDifferenceJson = 'changeModelDifferenceJson'
}
