import { JsonDocumentTableBlockColumnTypes } from '@baseModel/types/jsonDescription';
import { TableColumn } from '../tableConfigurationForm';

export interface Props {
  column: TableColumn;
  index?: number;
}

export function CellRenderer({ column, index }: Props) {
  const displayValue =
    column.type === JsonDocumentTableBlockColumnTypes.autonumber
      ? index
      : column.relation
      ? `this.${column.relation}.${column.value}`
      : column.value
      ? `this.${column.value}`
      : '';

  return <span>{displayValue}</span>;
}
