import { JsonDescription } from '@baseModel/types/jsonDescription';
import { parse } from 'yaml';

export function getJsonObject(value: string | undefined): JsonDescription | undefined {
  if (typeof value !== 'string') {
    return;
  }

  try {
    return <JsonDescription>parse(value);
  } catch (e) {
    console.error(e);
    return;
  }
}
