import { useState, useEffect } from 'react';
import { Block } from '../blocks/block';

export function useBlock<T extends Block<K>, K>(
  block: T | undefined,
  customSender?: string
): [K | undefined, (value: K | undefined) => void];
export function useBlock(): [undefined, undefined];
export function useBlock<T extends Block<K>, K>(
  block?: T | undefined,
  customSender?: string
): [K | undefined, (value: K | undefined) => void] | [undefined, undefined] {
  const [value, setValue] = useState(block ? block.getValue() : undefined);
  const sender = customSender || `${block?.constructor.name}_${Math.random()}`;

  useEffect(() => {
    if (!block) {
      return;
    }

    return block.subscribe((newValue) => {
      setValue(newValue);
    }, sender);
  }, [block, sender, setValue]);

  useEffect(() => {
    if (!block) {
      return;
    }

    setValue(block.getValue());
  }, [block, setValue]);

  if (block === undefined) {
    return [undefined, undefined];
  }

  return [value, (value) => block.setValue(value, sender)];
}
