import { ProseMirrorView } from '../proseMirrorView';
import { EventType } from '@baseModel/document/document';
import { Engine } from '@baseModel/engine/engine';
import { Unsubscriber } from '@utils/observable';
import { EDITOR_SENDER } from '../const';
import { documentUpdateHandler } from './documentUpdateHandler';
import { documentAddHandler } from './documentAddHandler';
import { documentRemoveHandler } from './documentRemoveHandler';
import { documentSortChangeHandler } from '@components/markdownEditor/modelBind/documentSortChangeHandler';

const engine = Engine.getInstance();

/**
 *
 * @param proseMirror
 * @param forceUpdate хук для перенабора externalNodes
 */
export function baseModelSubscription(proseMirror: ProseMirrorView, forceUpdate: () => void): Unsubscriber {
  const document = engine.getDocument();
  const unsubscribers: Unsubscriber[] = [];
  unsubscribers.push(
    document.subscribe(EventType.update, documentUpdateHandler.bind(undefined, proseMirror), EDITOR_SENDER)
  );
  unsubscribers.push(document.subscribe(EventType.add, documentAddHandler.bind(undefined, proseMirror), EDITOR_SENDER));
  unsubscribers.push(
    document.subscribe(EventType.remove, documentRemoveHandler.bind(undefined, proseMirror), EDITOR_SENDER)
  );

  unsubscribers.push(
    document.subscribe(EventType.sortIndex, documentSortChangeHandler.bind(undefined, proseMirror), EDITOR_SENDER)
  );

  unsubscribers.push(document.subscribe(EventType.add, forceUpdate, EDITOR_SENDER));
  unsubscribers.push(document.subscribe(EventType.remove, forceUpdate, EDITOR_SENDER));

  return () => unsubscribers.forEach((el) => el());
}
