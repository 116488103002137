import React from 'react';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { Sheet } from '@smwb/summer-ui';

export interface BlockSheetProps {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  children?: React.ReactNode;
}

export function BlockSheet({ isOpen, onClose, children }: BlockSheetProps) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Sheet isOpen={isOpen} placement={isMobile ? 'bottom' : 'right'} onClose={onClose} size="auto" variant="modal">
      {children}
    </Sheet>
  );
}
