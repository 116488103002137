import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EditSheetState {
  dataId: string | undefined;
  open: boolean;
}

const initialState: EditSheetState = {
  open: false,
  dataId: undefined
};

export const editSheetSlice = createSlice({
  name: 'editSheet',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.dataId = action.payload;
    },
    close: (state) => {
      state.open = false;
      state.dataId = undefined;
    },
    toggle: (state, action: PayloadAction<string | undefined>) => {
      state.open = !state.open;
      if (state.open) {
        state.dataId = action.payload;
      } else {
        state.dataId = undefined;
      }
    }
  }
});

export const { open, close, toggle } = editSheetSlice.actions;

export default editSheetSlice.reducer;
