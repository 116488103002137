import { Sheet } from '@smwb/summer-ui';
import { TableConfigurationForm } from './tableConfigurationForm';
import st from './insertTableSheet.module.less';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useTypedSelector } from '../../redux/types';
import { useDispatch } from 'react-redux';
import { editTableClose } from '@components/tableConfiguration/redux/actions';

export function TableConfiguration() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch();
  const { open: isOpen, editableTableId } = useTypedSelector((state) => state.app.tableConfiguration.actions);

  const title = editableTableId ? 'Редактировать таблицу' : 'Создать таблицу';

  return (
    <Sheet
      isOpen={isOpen}
      placement={isMobile ? 'bottom' : 'right'}
      onClose={() => dispatch(editTableClose())}
      size="700px"
      variant="modal"
    >
      <div className={st.wrapper}>
        <div className={st.title}>{title}</div>
        <TableConfigurationForm />
      </div>
    </Sheet>
  );
}
