import React, { forwardRef } from 'react';
import { Button as SmwbButton, ButtonProps } from '@smwb/summer-ui';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Типы правильные, проблема в @smwb/summer-ui пакете
  return <SmwbButton ref={ref} {...props} disableRipple />;
});

export type { ButtonProps };
