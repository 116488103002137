import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import { GraphElementResultTypes } from '../graphElements/graphElementResult';
import { MxCell } from '../../types/mxCell';

export interface EventDifferenceResults {
  id: string;
  _id: string | null;
  type?: GraphElementResultTypes;
  as: EventDifferenceAs;
}

export interface EventDifferenceResultsStyles extends EventDifferenceResults {
  style: string;
  styles: DifferenceProperties[];
}

export interface EventDifferenceResultsValues extends EventDifferenceResults {
  attributes: DifferenceProperties[];
}

export interface EventDifferenceResultsId extends EventDifferenceResults {
  attributes: DifferenceProperties[];
}

export interface Edge {
  isSource: boolean;
  terminal: string | null;
  previous: string | null;
  isArrowEnd: boolean;
}

export interface EventDifferenceResultsTerminal extends EventDifferenceResults {
  edge: Edge;
}

export interface Index {
  value: number | null;
  previous: number | null;
}

export interface EventDifferenceResultsChild extends EventDifferenceResults {
  index: Index;
  typeAttribute: string;
}

export type Properties = Record<string, string>;

export type DifferenceProperties = {
  type: string;
  value: string | null;
  previous: string | null;
};

export enum EventDifferenceAs {
  styles = 'styles',
  attributes = 'attributes',
  id = 'id',
  child = 'child',
  terminal = 'terminal'
}

export function differenceObjResult(val: Properties, prev: Properties, isCheck = true): DifferenceProperties[] {
  const keys = merge(Object.keys(val), Object.keys(prev));

  return reduce(
    keys,
    function (result, key) {
      if (!isCheck || (isCheck && !isEqual(val[key], prev[key])))
        result.push({ type: key, value: val[key] ?? null, previous: prev[key] ?? null });

      return result;
    },
    <DifferenceProperties[]>[]
  );
}

export function getTypeCell(cell: MxCell) {
  let type: GraphElementResultTypes | undefined;

  if (cell.vertex) type = GraphElementResultTypes.vertex;
  else if (cell.edge) type = GraphElementResultTypes.edge;

  return type;
}
