import { IRange, Range } from 'monaco-editor';
import { TextModel } from '../types';
import { Paths } from '@commonTypes/index';
import { findEndLine } from './findEndLine';
import { findNextLine } from './findNextLine';

export function isPathIndexArray(path: unknown): path is number {
  return Number.isInteger(path);
}

export const arrayIndicatorRegexp = '^-\\s';

export function findRangeByScopeArray(model: TextModel, path: number, range: Range): Range | undefined {
  let foundRange: Range | undefined;
  let currentLine = range.startLineNumber;
  let indexArray = 0;

  while (range.endLineNumber >= currentLine) {
    const rangeScope: IRange = {
      startLineNumber: currentLine,
      startColumn: range.startColumn,
      endLineNumber: currentLine,
      endColumn: model.getLineMaxColumn(currentLine)
    };
    const [foundMatch] = model.findMatches(arrayIndicatorRegexp, rangeScope, true, true, null, false, 1);

    if (foundMatch !== undefined) {
      if (indexArray === path) {
        foundRange = foundMatch.range;

        break;
      }

      indexArray++;
    }

    currentLine++;
  }

  return foundRange;
}

export function getWordRegexp(word: string) {
  return `^${word}:`;
}

export interface Ranges {
  foundRange: Range;
  valueRange: Range;
}

export type RangesReturn = Ranges | undefined;

export function findRangeByScope(
  model: TextModel,
  path: string,
  range: Range,
  includeEmptyLines = false
): RangesReturn {
  let foundRange: Range | undefined;
  let valueRange: Range | undefined;
  let currentLine = range.startLineNumber;

  while (range.endLineNumber >= currentLine) {
    const rangeScope: IRange = {
      startLineNumber: currentLine,
      startColumn: range.startColumn,
      endLineNumber: currentLine,
      endColumn: model.getLineMaxColumn(currentLine)
    };
    const [foundMatch] = model.findMatches(getWordRegexp(path), rangeScope, true, true, null, false, 1);

    if (foundMatch !== undefined) {
      const { range } = foundMatch;
      const endLine = findEndLine(model, range.startLineNumber, range.startColumn, includeEmptyLines);
      const endLineEndColumn = model.getLineMaxColumn(endLine);

      foundRange = new Range(range.startLineNumber, range.startColumn, endLine, endLineEndColumn);
      valueRange = new Range(range.startLineNumber, range.endColumn, endLine, endLineEndColumn);

      break;
    }

    currentLine++;
  }

  if (foundRange === undefined || valueRange === undefined) {
    return undefined;
  }

  return { foundRange, valueRange };
}

export interface FindRangeOptions {
  includeEmptyLines?: boolean;
}

export interface RangePath {
  path: string | number;
  range: Range;
  valueRange: Range;
}

export type RangePaths = RangePath[];

export function findRange(model: TextModel, paths: Paths, { includeEmptyLines }: FindRangeOptions = {}) {
  let rangeScope = model.getFullModelRange();
  let ranges: RangesReturn;
  const rangePaths: RangePaths = [];

  for (const path of paths) {
    if (isPathIndexArray(path)) {
      const range = findRangeByScopeArray(model, path, rangeScope);

      if (!range) {
        ranges = undefined;
        continue;
      }

      const endLine = findEndLine(model, range.startLineNumber, range.startColumn, includeEmptyLines);
      const endLineEndColumn = model.getLineMaxColumn(endLine);

      rangeScope = new Range(range.startLineNumber, range.endColumn, endLine, endLineEndColumn);
      ranges = {
        foundRange: new Range(range.startLineNumber, range.startColumn, endLine, endLineEndColumn),
        valueRange: rangeScope
      };
    } else {
      ranges = findRangeByScope(model, path, rangeScope, includeEmptyLines);

      if (!ranges) {
        continue;
      }

      const nextLine = findNextLine(model, ranges.foundRange.startLineNumber);
      const nextLineStartColumn = model.getLineFirstNonWhitespaceColumn(nextLine);
      const endLine = findEndLine(model, nextLine, rangeScope.startColumn, includeEmptyLines);
      const endLineEndColumn = model.getLineMaxColumn(endLine);

      rangeScope = new Range(nextLine, nextLineStartColumn, endLine, endLineEndColumn);
    }

    if (!ranges) {
      break;
    }

    rangePaths.push({ path, range: ranges.foundRange, valueRange: ranges.valueRange });
  }

  return { foundRange: ranges?.foundRange, foundRangeValue: ranges?.valueRange, rangePaths };
}
