import { ReplaceStep, AddMarkStep, ReplaceAroundStep, RemoveMarkStep } from 'prosemirror-transform';
import { schema } from 'prosemirror-markdown';
import { Node } from 'prosemirror-model';
import { Transaction, Selection } from 'prosemirror-state';
import { Slice, Fragment } from 'prosemirror-model';
import { Engine } from '@baseModel/engine/engine';
import { DOCUMENT_BLOCK_TAG, EDITOR_SENDER, INNER_TRANSACTION_META } from '../const';
import { BlockType } from '@baseModel/document/blocks/types';
import { MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';
import { EditorView } from 'prosemirror-view';
import { markdownExtendSerializer } from '../utils/markdownExtendSerializer';
import { docForEach } from '@components/markdownEditor/utils/docForEach';
import { getAffectedBlocks } from '@components/markdownEditor/modelBind/utils/getAffectedBlocks';
import { moveNodes } from '@components/markdownEditor/modelBind/utils/moveNodes';
import { flattenContent } from '@components/markdownEditor/modelBind/utils/flattenContent';
import { createNewMarkdown } from '@components/markdownEditor/modelBind/utils/createNewMarkdown';
import { newBlockTypeCheck } from '@components/markdownEditor/modelBind/utils/newBlockTypeCkeck';

const document = Engine.getInstance().getDocument();

function deleteFromEngine(blocks: Node[]): void {
  blocks.forEach((block) => {
    document.removeBlockById(block.attrs.type as BlockType, block.attrs.id as string, EDITOR_SENDER);
  });
}

/**
 * Уведомление базовой модели об изменениях в редакторе
 * Если возвращает false, изменений в редактор не в носим
 * @param view
 * @param transaction
 */
export function baseModelCall(view: EditorView, transaction: Transaction): boolean {
  const isInner = !!transaction.getMeta(INNER_TRANSACTION_META);
  // Транзакции порожденные внутри редактора, не обрабатываем
  if (isInner) {
    return true;
  }
  if (!transaction.steps.length) {
    return true;
  }

  if (!newBlockTypeCheck(transaction)) {
    return false;
  }

  // TODO шагов может быть много, их надо пробовать мержить или так же инкрементами обрабатывать
  const step = transaction.steps[0];
  if (
    !(step instanceof ReplaceStep) &&
    !(step instanceof AddMarkStep) &&
    !(step instanceof RemoveMarkStep) &&
    !(step instanceof ReplaceAroundStep)
  ) {
    console.warn('unknown step ' + step.constructor.name, step);
    return false;
  }
  const { from, to } = step;

  const [blocksBefore, blocksAfter, deletedBlocks, blockForUpdate] = getAffectedBlocks(
    transaction.before,
    transaction.doc,
    from,
    to
  );
  deleteFromEngine(deletedBlocks);
  if (blockForUpdate.length > 1) {
    // TODO это бывает при редактировании таблицы, надо как то выключать запуск транзакций
    console.warn('Непредусмотренное поведение, блоков больше 1', {
      blocksBefore,
      blocksAfter,
      blockForUpdate,
      deletedBlocks,
      transaction
    });
    return false;
    // Блоки не менялись, изменилась только их позиция
  } else if (blockForUpdate.length === 0) {
    if (!moveNodes(transaction, document)) {
      console.warn('moveNodes', {
        blocksBefore,
        blocksAfter,
        blockForUpdate,
        deletedBlocks,
        transaction
      });
      return false;
    }
    return true;
  }
  const id = blockForUpdate[0].attrs.id as string | undefined;
  if (!id) {
    console.error('blocksAfter', blockForUpdate);
    throw new Error(`id пустой`);
  }
  const nodes: Node[] = [];
  if (step instanceof ReplaceStep) {
    step.slice.content.forEach((sliceNode) => {
      flattenContent(sliceNode, nodes);
    });
  }
  const fragment = Fragment.fromArray(nodes);

  // Из редактора мы можем создать только MARKDOWN
  if (id === MARKDOWN_NEW_BLOCK_ID) {
    createNewMarkdown(view, to, fragment, document);
    return false;
  }
  if (step instanceof ReplaceAroundStep || step instanceof AddMarkStep || step instanceof RemoveMarkStep) {
    // Пометим, что такую транзакцию повторно обрабатывать не надо
    transaction.setMeta(INNER_TRANSACTION_META, true);
    view.dispatch(transaction);
  } else if (step instanceof ReplaceStep) {
    const newSlice = new Slice(fragment, step.slice.openStart, step.slice.openEnd);
    const replaceTransaction = view.state.tr.replace(from, to, newSlice);
    if (transaction.selectionSet) {
      const selection = Selection.fromJSON(replaceTransaction.doc, transaction.selection.toJSON());
      replaceTransaction.setSelection(selection);
    }
    // Пометим, что такую транзакцию повторно обрабатывать не надо
    replaceTransaction.setMeta(INNER_TRANSACTION_META, true);
    view.dispatch(replaceTransaction);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-plus-operands
    console.error('unknown step ' + (step as any).toString());
    return false;
  }
  let newBlockValue = '';
  docForEach(
    view.state.doc,
    (node) => {
      if (node.type.name === DOCUMENT_BLOCK_TAG && node.attrs.id === id) {
        const updatedBlocks: Node[] = [];
        flattenContent(node, updatedBlocks);
        const updateDoc = schema.nodes.doc.create({}, Fragment.fromArray(updatedBlocks));
        newBlockValue += markdownExtendSerializer.serialize(updateDoc);
      }
      return true;
    },
    from,
    to
  );
  document.setBlockValue(BlockType.markdown, id, newBlockValue || '', EDITOR_SENDER);
  return false;
}
