import { TextModel } from '../types';

export function findNextLine(model: TextModel, startLine: number) {
  const fullRange = model.getFullModelRange();

  let currentLine = startLine;

  while (fullRange.endLineNumber > currentLine) {
    currentLine++;
    const nextLineStartColumn = model.getLineFirstNonWhitespaceColumn(currentLine);

    if (nextLineStartColumn !== 0) {
      break;
    }
  }

  if (model.getLineFirstNonWhitespaceColumn(currentLine) === 0) {
    return startLine;
  }

  return currentLine;
}
