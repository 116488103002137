import { Button } from '@smwb/summer-ui';
import React, { useState } from 'react';
import st from './stepper.module.less';

type StepperProps = {
  steps: { content: React.ReactNode; label?: string; cantNextStep?: boolean }[];
  onSubmit: () => void;
  onClose?: () => void;
};

function Stepper({ steps, onSubmit, onClose }: StepperProps) {
  const [activeStep, setActiveStep] = useState(0);
  const canNextStep = activeStep + 1 < steps.length && !steps[activeStep].cantNextStep;
  const canPrevStep = activeStep !== 0;

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleSubmit = () => {
    onSubmit();
    setActiveStep(0);
  };

  const currentStep = steps[activeStep];

  return (
    <div className={st.wrapper}>
      {currentStep.label && <div className={st.header}>{currentStep.label}</div>}
      <div className={st.content}>{currentStep.content}</div>
      <div className={st.footer}>
        {(canPrevStep || !onClose) && (
          <Button onClick={handlePrev} disabled={!canPrevStep} type="button">
            Назад
          </Button>
        )}
        {!canPrevStep && onClose && (
          <Button onClick={onClose} type="button">
            Закрыть
          </Button>
        )}
        <span>
          {activeStep + 1}/{steps.length}
        </span>
        {!canNextStep ? (
          <Button onClick={handleSubmit} type="button">
            Сохранить
          </Button>
        ) : (
          <Button onClick={handleNext} disabled={!canNextStep} type="button">
            Далее
          </Button>
        )}
      </div>
    </div>
  );
}

export default Stepper;
