// https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/index.ts
import { buildKeymap, buildInputRules } from 'prosemirror-example-setup';
import { Schema } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
// ***
// из prosemirror-example-setup
import { keymap } from 'prosemirror-keymap';
import { baseKeymap } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { topMenuView } from '@components/markdownEditor/plugins/topMenuView';
import { highlightBlockAndSendEventOnClickPlugin } from './highlightBlockAndSendEventOnClickPlugin';
import { blockHighlightPlugin } from '@components/markdownEditor/plugins/blockHighlight';
import { blockControlsPlugin } from '@components/markdownEditor/plugins/blockControls';
// ***

export function pluginsSet(options: {
  /// The schema to generate key bindings and menu items for.
  schema: Schema;

  /// Can be used to [adjust](#example-setup.buildKeymap) the key bindings created.
  mapKeys?: { [key: string]: string | false };

  /// Set to false to disable the history plugin.
  // history?: boolean;

  /// Set to false to make the menu bar non-floating.
  // floatingMenu?: boolean;
  topMenuOnUpdate: () => void;
}) {
  const plugins = [
    buildInputRules(options.schema),
    keymap(buildKeymap(options.schema, options.mapKeys)),
    keymap(baseKeymap),
    dropCursor(),
    gapCursor(),
    topMenuView(options.topMenuOnUpdate),
    highlightBlockAndSendEventOnClickPlugin,
    blockHighlightPlugin,
    blockControlsPlugin
  ];
  // if (options.history !== false) plugins.push(history());

  return plugins.concat(
    new Plugin({
      props: {
        attributes: { class: 'ProseMirror-example-setup-style' }
      }
    })
  );
}
