import { MxStyle } from '../../types/mxStyleChange';
import {
  differenceObjResult,
  EventDifferenceAs,
  EventDifferenceResultsStyles,
  getTypeCell,
  Properties
} from './eventDifferenceResults';
import { ID } from '../graphElements/graphElementResult';

export function getEventDifferenceStyles(change: MxStyle): EventDifferenceResultsStyles {
  const style = getStyleArray(change.style);
  const previous = getStyleArray(change.previous);

  return {
    id: <string>change.cell.id,
    _id: change.cell.getAttribute(ID, null),
    type: getTypeCell(change.cell),
    as: EventDifferenceAs.styles,
    style: change.style,
    styles: differenceObjResult(style, previous)
  };
}

export const getStyleArray = function (style: string): Properties {
  const res = <Properties>{};
  const arr = style.split(';');

  for (let i = 0; i < arr.length; i++)
    if (arr[i].indexOf('=') !== -1) {
      const [name, value] = arr[i].split('=');
      res[name] = value;
    } else if (arr[i]) res[arr[i]] = '';

  return res;
};
