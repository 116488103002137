import { Fragment, Node } from 'prosemirror-model';
import {
  DOCUMENT_BLOCK_TAG,
  EDITOR_SENDER,
  EXTERNAL_NODE_TAG,
  INNER_TRANSACTION_META,
  RESOLVE_POSITION_META
} from '@components/markdownEditor/const';
import { Document, MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';
import { schema } from 'prosemirror-markdown';
import { markdownExtendSerializer } from '@components/markdownEditor/utils/markdownExtendSerializer';
import { Markdown } from '@baseModel/document/blocks/markdown';
import { markdownExtendParser } from '@components/markdownEditor/utils/markdownExtendParser';
import { EditorView } from 'prosemirror-view';
import { docForEach } from '@components/markdownEditor/utils/docForEach';
import uniqueId from 'lodash/uniqueId';

export function createNewMarkdown(view: EditorView, to: number, fragment: Fragment, document: Document): void {
  let sortIndex = 0;
  let previousNode: Node | undefined;
  let insertPosition = 0;
  docForEach(
    view.state.doc,
    (node, pos) => {
      insertPosition = pos;
      if (
        (node.type.name === DOCUMENT_BLOCK_TAG || node.type.name === EXTERNAL_NODE_TAG) &&
        node.attrs.id !== MARKDOWN_NEW_BLOCK_ID
      ) {
        previousNode = node;
        insertPosition = pos + node.nodeSize;
      }
      return true;
    },
    0,
    to
  );

  if (previousNode) {
    const previousBlock = document.getBlockById(previousNode.attrs.id as string);
    if (previousBlock?.getSortIndex()) {
      sortIndex = previousBlock.getSortIndex() + 1;
    }
  }
  const updateDoc = schema.nodes.doc.create({}, fragment);
  const newText = markdownExtendSerializer.serialize(updateDoc);
  if (!newText) {
    return;
  }
  const newBlock = new Markdown(uniqueId('md-' + Date.now().toString()));
  newBlock.setValue(newText, EDITOR_SENDER);
  document.addBlock(newBlock, sortIndex, EDITOR_SENDER);

  const doc = markdownExtendParser.parse(newBlock.getMarkdown() || '');
  if (doc?.content) {
    insertPosition += 3;
    const replaceTransaction = view.state.tr.replaceRangeWith(insertPosition, insertPosition, doc);
    // Пометим, что такую транзакцию повторно обрабатывать не надо
    replaceTransaction.setMeta(INNER_TRANSACTION_META, true);
    // Попросим подвинуть курсор в новый блок
    replaceTransaction.setMeta(RESOLVE_POSITION_META, insertPosition + doc.content.size);
    view.dispatch(replaceTransaction);
  }
}
