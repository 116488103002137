import React, { useMemo } from 'react';
import { useForm, useFormState, FormRenderProps } from 'react-final-form';
import { Button, SelectField, SelectFieldProps } from '@smwb/summer-ui';
import { getRowTypeItems, modelTypes } from '@components/tableConfiguration/utils';
import { Models } from '@baseModel/engine/types';
import {
  getModelFieldsItems,
  getModelsItemsByMetaName
} from '@components/markdownEditor/dataDisplayWidgets/widgets/modelLink/utils';
import s from './configForm.module.less';

export interface FormFields {
  modelType: string;
  metaModelName: string;
  field: string;
  modelId: string;
}

export interface ConfigFormProps {
  onSubmit: FormRenderProps<FormFields>['handleSubmit'];
}

export function ConfigForm({ onSubmit: onSubmitProp }: ConfigFormProps) {
  const { values } = useFormState<FormFields>();
  const { batch, change } = useForm<FormFields>();

  const onChangeModelType: SelectFieldProps['onChange'] = (_, data) => {
    batch(() => {
      change('modelType', `${data.value}`);
      change('field', '');
      change('metaModelName', '');
      change('modelId', '');
    });
  };

  const onChangeMetaModel: SelectFieldProps['onChange'] = (_, data) => {
    batch(() => {
      change('metaModelName', `${data.value}`);
      change('field', '');
      change('modelId', '');
    });
  };

  const onChangeModelId: SelectFieldProps['onChange'] = (_, data) => {
    batch(() => {
      change('field', '');
      change('modelId', `${data.value}`);
    });
  };

  const onChangeFieldName: SelectFieldProps['onChange'] = (_, data) => {
    change('field', `${data.value}`);
  };

  const metaModelNameItems = useMemo(() => {
    return getRowTypeItems(values.modelType as Models);
  }, [values.modelType]);

  const modelItems = useMemo(() => {
    return getModelsItemsByMetaName(values.modelType, values.metaModelName);
  }, [values.modelType, values.metaModelName]);

  const modelFieldItems = useMemo(
    () => getModelFieldsItems(values.modelType, values.modelId),
    [values.modelId, values.modelType]
  );

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    void onSubmitProp(event);
  };

  return (
    <form className={s.root} onSubmit={onSubmit}>
      <SelectField
        items={modelTypes}
        label="Model Type"
        menuMaxHeight={250}
        value={values.modelType}
        onChange={onChangeModelType}
      />
      <SelectField
        items={metaModelNameItems}
        label="Metamodel Name"
        menuMaxHeight={250}
        value={values.metaModelName}
        onChange={onChangeMetaModel}
      />
      <SelectField
        items={modelItems}
        label="Model"
        menuMaxHeight={250}
        value={values.modelId}
        onChange={onChangeModelId}
      />
      <SelectField
        items={modelFieldItems}
        label="Model Field"
        menuMaxHeight={250}
        value={values.field}
        onChange={onChangeFieldName}
      />
      <Button className={s.submitButton} type="submit">
        Сохранить
      </Button>
    </form>
  );
}
