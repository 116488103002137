import { Attribute, ContextCreateElements } from './graphElementResult';
import { MxCell } from '../../types/mxCell';
import find from 'lodash/find';

export function createElementVertexCell(this: ContextCreateElements, attributes: Attribute[]): MxCell {
  const attributeStyle = find(attributes, { type: 'style' })?.value;

  const cell = new this.mxCell(
    '',
    new this.mxGeometry(0, 0, 80, 80),
    attributeStyle || 'whiteSpace=wrap;html=1;align=center;collapsible=0;container=1;recursiveResize=0;'
  );
  cell.vertex = true;

  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    if (attr.type === 'style') continue;

    this.graph.setAttributeForCell(cell, attr.type, attr.value);
  }

  return cell;
}
