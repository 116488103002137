import React from 'react';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { JsonDocumentTableBlockColumn } from '@baseModel/types/jsonDescription';
import { getColumnHeader } from './utils';

interface TableHeadProps {
  model: EntityMetaModel | RelationMetaModel;
  column: JsonDocumentTableBlockColumn;
}

export function TableHeader({ model, column }: TableHeadProps) {
  const [title, setTitle] = React.useState(getColumnHeader(model, column));

  React.useEffect(() => {
    setTitle(getColumnHeader(model, column));
  }, [column, model]);

  React.useEffect(() => {
    return model.subscribeModel(() => {
      const newTitle = getColumnHeader(model, column);

      if (title !== newTitle) {
        setTitle(newTitle);
      }
    });
  }, [column, model, title]);

  return <>{title}</>;
}
