import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Button, Icon, Menu } from '@smwb/summer-ui';
import { getBlockIdInCursorPosition } from '@components/markdownEditor/utils/getEndBlockCursorPosition';
import { Diagram } from '@baseModel/document/blocks/diagram';
import { Engine } from '@baseModel/engine/engine';
import { ProseMirrorView } from '@components/markdownEditor/proseMirrorView';
import style from './topMenu.module.less';
import { MenuItem } from '@components/markdownEditor/topMenu/menuItem';
import { Schema } from 'prosemirror-model';
import { MarkButton } from '@components/markdownEditor/topMenu/markButton';
import { MENU_ICON_SIZE } from '@components/markdownEditor/topMenu/const';
import { HeaderBlockTypeMenuItems } from '@components/markdownEditor/topMenu/blockTypeMenuItem';
import { wrapInList } from 'prosemirror-schema-list';
import { wrapIn } from 'prosemirror-commands';
import { useBooleanState } from '@hooks/useBooleanState';
import CreateTableForm from '../createTableForm/createTableForm';
import { Markdown } from '@baseModel/document/blocks/markdown';
import { useDispatch } from 'react-redux';
import { editTableOpen } from '@components/tableConfiguration/redux/actions';
import uniqueId from 'lodash/uniqueId';
import { getWidget, widgets } from '@components/markdownEditor/dataDisplayWidgets/utils';
import { Widget } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/types';
import { BlockSheet } from '@components/markdownEditor/topMenu/blockSheet/blockSheet';
import { closeConfigBlock, openConfigBlock } from '@components/markdownEditor/redux/markdownEditor';
import { useTypedSelector } from '../../../redux/types';

export interface TopMenuProps {
  menuNodes: NodeListOf<HTMLDivElement> | null;
  proseMirrorView: ProseMirrorView | null;
  schema: Schema;
}

const engine = Engine.getInstance();

function MenuBar({ proseMirrorView, schema }: Pick<TopMenuProps, 'proseMirrorView' | 'schema'>) {
  const dispatch = useDispatch();
  const anchorInsertMenuRef = useRef<HTMLButtonElement>(null);
  const anchorHeadMenuRef = useRef<HTMLButtonElement>(null);
  const anchorCreateTableMenuRef = useRef<HTMLButtonElement>(null);

  const [insertMenuOpen, setInsertMenuOpen] = useState(false);
  const [headMenuOpen, setHeadMenuOpen] = useState(false);
  const [, setUp] = useState(false);
  const {
    isTrue: isCreateTableMenuOpen,
    setFalse: onCloseCreateTableMenu,
    toggleValue: toggleCreateTablemenu
  } = useBooleanState();
  const configBlock = useTypedSelector((state) => state.app.markdownEditor.actions.configBlock);

  useEffect(() => {
    return proseMirrorView?.topMenuOnUpdateSubscribe(() => setUp((prev) => !prev));
  }, [proseMirrorView]);

  const findBlockIndex = (): [string, number | undefined] => {
    if (!proseMirrorView) {
      return ['', undefined];
    }
    const document = engine.getDocument();
    const blockInfo = getBlockIdInCursorPosition(proseMirrorView);
    const block = document.getBlockById(blockInfo.id);
    if (!block) {
      console.error(`Block ${blockInfo.type} ${blockInfo.id} не найден`);
    }
    return [uniqueId('md-' + Date.now().toString()), block ? block.getSortIndex() + 1 : undefined];
  };

  const onInsertDiagramClick = () => {
    if (!proseMirrorView) {
      return;
    }
    const [maxId, sortIndex] = findBlockIndex();

    const diagram = new Diagram(maxId);
    diagram.setValue({
      allowedTypes: [],
      base64: '',
      contains: []
    });
    const document = engine.getDocument();
    document.addBlock(diagram, sortIndex);
    setInsertMenuOpen(false);
  };

  const handleInsertTable = () => {
    const [, editableTableIndex] = findBlockIndex();
    dispatch(editTableOpen({ editableTableIndex }));
  };

  const handleInsertMdBlock = () => {
    if (!proseMirrorView) return;
    const [maxId, sortIndex] = findBlockIndex();
    const document = engine.getDocument();
    const markdown = new Markdown(maxId);
    markdown.setValue('новый блок ...');
    document.addBlock(markdown, sortIndex);
  };

  const onBlockMenuItemClick = (Widget: Widget) => () => {
    const [id, sortIndex] = findBlockIndex();
    const widget = new Widget(id, sortIndex);
    const hasConfig = widget.getConfigView() !== null;
    const isAskInitConfig = widget.askInitConfig();

    if ((hasConfig && !isAskInitConfig) || !hasConfig) {
      void widget.save();
    }

    dispatch(openConfigBlock({ id, sortIndex, type: widget.getType() }));
    setInsertMenuOpen(false);
  };

  const onBlockSheetClose = () => {
    dispatch(closeConfigBlock());
  };

  const insertMenuItems = (
    <>
      <MenuItem onClick={onInsertDiagramClick}>Диаграмма</MenuItem>
      <MenuItem onClick={handleInsertTable}>Таблица</MenuItem>
      <MenuItem onClick={handleInsertMdBlock}>Текстовый блок</MenuItem>
      {widgets.map((Widget) => {
        const widget = new Widget();

        return (
          <MenuItem key={widget.getType()} onClick={onBlockMenuItemClick(Widget)}>
            {widget.getMenuItem()}
          </MenuItem>
        );
      })}
    </>
  );

  const getConfigView = () => {
    let element: React.ReactNode;

    if (configBlock.isOpen) {
      const Widget = getWidget(configBlock.type);

      if (Widget === undefined) {
        return;
      }

      const widget = new Widget(configBlock.id, configBlock.sortIndex);
      element = widget.getConfigViewWrapper({ onSubmit: onBlockSheetClose });
    }

    return element;
  };

  if (!proseMirrorView) {
    return null;
  }

  return (
    <>
      <div className={style.container}>
        <MarkButton proseMirrorView={proseMirrorView} icon="format_bold" markType={schema.marks.strong} />
        <MarkButton proseMirrorView={proseMirrorView} icon="format_italic" markType={schema.marks.em} />
        <MarkButton proseMirrorView={proseMirrorView} icon="code" markType={schema.marks.code} />
        <Button
          className={style.button}
          variant={'outlined'}
          label={'Вставить'}
          ref={anchorInsertMenuRef}
          onClick={() => setInsertMenuOpen(() => !insertMenuOpen)}
        />
        <Menu
          anchorElement={anchorInsertMenuRef.current}
          isOpen={insertMenuOpen}
          onClose={() => setInsertMenuOpen(false)}
        >
          {insertMenuItems}
        </Menu>
        <Button
          className={style.button}
          variant={'outlined'}
          ref={anchorHeadMenuRef}
          icon={<Icon name="format_h1" size={MENU_ICON_SIZE} />}
          onClick={() => setHeadMenuOpen(() => !headMenuOpen)}
        />
        <Menu anchorElement={anchorHeadMenuRef.current} isOpen={headMenuOpen}>
          <HeaderBlockTypeMenuItems
            proseMirrorView={proseMirrorView}
            schema={schema}
            onClose={() => setHeadMenuOpen(false)}
          />
        </Menu>
        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="format_list_bulleted" size={MENU_ICON_SIZE} />}
          onClick={() =>
            wrapInList(schema.nodes.bullet_list)(
              proseMirrorView.getState(),
              proseMirrorView.getDispatch(),
              proseMirrorView.getEditor()
            )
          }
          disabled={!wrapInList(schema.nodes.bullet_list)(proseMirrorView.getState())}
        />
        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="format_list_numbered" size={MENU_ICON_SIZE} />}
          onClick={() =>
            wrapInList(schema.nodes.ordered_list)(
              proseMirrorView.getState(),
              proseMirrorView.getDispatch(),
              proseMirrorView.getEditor()
            )
          }
          disabled={!wrapInList(schema.nodes.ordered_list)(proseMirrorView.getState())}
        />
        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="format_quote" size={MENU_ICON_SIZE} />}
          onClick={() => {
            wrapIn(schema.nodes.blockquote)(proseMirrorView.getState(), proseMirrorView.getDispatch());
          }}
          disabled={!wrapIn(schema.nodes.blockquote)(proseMirrorView.getState())}
        />

        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="table" size={MENU_ICON_SIZE} />}
          onClick={toggleCreateTablemenu}
          ref={anchorCreateTableMenuRef}
        />
        <Menu
          anchorElement={anchorCreateTableMenuRef.current}
          isOpen={isCreateTableMenuOpen}
          onClose={onCloseCreateTableMenu}
        >
          <CreateTableForm proseMirrorView={proseMirrorView} />
        </Menu>
      </div>
      <BlockSheet isOpen={configBlock.isOpen} onClose={onBlockSheetClose}>
        {getConfigView()}
      </BlockSheet>
    </>
  );
}

export function TopMenu({ menuNodes, proseMirrorView, schema }: TopMenuProps) {
  if (!menuNodes?.length) {
    return null;
  }

  return createPortal(<MenuBar proseMirrorView={proseMirrorView} schema={schema} />, menuNodes[0]);
}
