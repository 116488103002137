import React from 'react';
import { Table } from './table/table';
import s from './metaTableView.module.less';
import { useModels } from '@baseModel/hooks/useModels';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { AddFieldShellForm } from './addFieldShellForm/addFieldShellForm';

const engine = Engine.getInstance();

export function MetaTableView() {
  const metaEntityNames = useModels(Models.EntityMetaModel);
  const metaRelationNames = useModels(Models.RelationMetaModel);
  const entityMetaModels = metaEntityNames.map((entityName) => engine.getMetaEntityByName(entityName));
  const relationMetaModels = metaRelationNames.map((relationName) => engine.getMetaRelationByName(relationName));
  const models = [...entityMetaModels, ...relationMetaModels];
  const tables = models.map((model) => <Table key={model.getName()} model={model} />);

  return (
    <>
      <div className={s.root}>{tables}</div>
      <AddFieldShellForm />
    </>
  );
}
