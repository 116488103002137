import { Models } from '@baseModel/engine/types';
import { ModelPaths } from '@components/yamlEditor/plugins/yamlBind/types';

export const EDITOR_SENDER = 'editor-sender';

export const modelPathsMapping = {
  [Models.EntityMetaModel]: ModelPaths.MetaEntities,
  [Models.RelationMetaModel]: ModelPaths.MetaRelations,
  [Models.Entity]: ModelPaths.Entities,
  [Models.Relation]: ModelPaths.Relations
};
