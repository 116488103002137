// Убирает из Node все EXTERNAL_NODE_TAG и ставит содержимое на 1 уровень
import { Node } from 'prosemirror-model';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';

export function flattenContent(node: Node, flatNodes: Node[]) {
  if (node.type.name === EXTERNAL_NODE_TAG || node.type.name === DOCUMENT_BLOCK_TAG) {
    node.content.forEach((innerNode) => {
      flattenContent(innerNode, flatNodes);
    });
  } else {
    flatNodes.push(node);
  }
}
