import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { Engine } from '@baseModel/engine/engine';
import { EventEditorMetaModelObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { JsonMetaRelation } from '@baseModel/types/jsonDescription';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import difference from 'lodash/difference';
import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';

const engine = Engine.getInstance();

export function editorMetaRelationsListener(event: EventEditorMetaModelObserver<JsonMetaRelation>) {
  const {
    changes: { after },
    meta
  } = event;

  if (meta.deleted) {
    engine.removeRelationMetaModel(meta.name, EDITOR_SENDER);
    return;
  }

  let isNew = false;
  let metaRelation: RelationMetaModel | undefined;
  try {
    metaRelation = engine.getMetaRelationByName(meta.name);
  } catch (e) {
    isNew = true;
    metaRelation = new RelationMetaModel(meta.name);
  }

  if (!after) {
    return;
  }

  const oldCommons = metaRelation.getCommonNames();
  const oldFields = metaRelation.getFieldNames();
  const oldRelations = metaRelation.getRelationNames();

  const newCommons = Object.keys(omit(after, ['fields', 'ends']));
  const newFields = Object.keys(after.fields ?? {});
  const newRelations = Object.keys(after.ends ?? {});

  const commonsDeleted = difference(oldCommons.map(kebabCase), newCommons);
  const fieldsDeleted = difference(oldFields, newFields);
  const relationsDeleted = difference(oldRelations, newRelations);

  commonsDeleted.forEach((el) => metaRelation?.removeCommon(el, EDITOR_SENDER));
  fieldsDeleted.forEach((el) => metaRelation?.removeField(el, EDITOR_SENDER));
  relationsDeleted.forEach((el) => metaRelation?.removeRelation(el, EDITOR_SENDER));

  for (const fieldName of Object.keys(after ?? {})) {
    if (fieldName === 'fields' || fieldName === 'ends') {
      continue;
    }
    if (metaRelation.getCommonValue(camelCase(fieldName)) === after[<keyof JsonMetaRelation>fieldName]) {
      continue;
    }
    try {
      if (after[<keyof JsonMetaRelation>fieldName] === undefined) {
        metaRelation.removeCommon(camelCase(fieldName), EDITOR_SENDER);
      } else {
        metaRelation.setCommonValue(
          camelCase(fieldName),
          after[<keyof Omit<JsonMetaRelation, 'fields' | 'ends'>>fieldName],
          EDITOR_SENDER
        );
      }
    } catch (e) {
      console.error(e);
      return;
    }
  }
  const fields = after.fields;
  if (fields) {
    for (const field in fields) {
      if (isEqual(metaRelation.getFieldValue(field), fields[field])) {
        continue;
      }
      try {
        if (fields[field] === undefined) {
          metaRelation.removeField(field, EDITOR_SENDER);
        } else {
          metaRelation.setFieldValue(field, fields[field], EDITOR_SENDER);
        }
      } catch (e) {
        console.error(e);
        return;
      }
    }
  }

  const ends = after.ends;
  if (ends) {
    for (const field in ends) {
      if (isEqual(metaRelation.getRelationValue(field), ends[field])) {
        continue;
      }
      try {
        if (ends[field] === undefined) {
          metaRelation.removeRelation(field, EDITOR_SENDER);
        } else {
          metaRelation.setRelationValue(field, ends[field], EDITOR_SENDER);
        }
      } catch (e) {
        console.error(e);
        return;
      }
    }
  }

  if (isNew) {
    engine.addRelationMetaModel(metaRelation, EDITOR_SENDER);
  }
}
