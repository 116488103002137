import React, { useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { ExternalNodeAttributes } from './utils/markdownSchemaWithExtraNodes';
import { JsonDocumentBlockType } from '@baseModel/types/jsonDescription';
import { TableView } from '../tableView/tableView';
import { Preview as DrawioPreview } from '../drawio/preview/preview';
import { CustomNode } from './customNode';
import { setReadyPrint } from '@components/markdownEditor/redux/markdownEditor';
import { useAppDispatch } from '../../redux/store';
import { getWidget } from '@components/markdownEditor/dataDisplayWidgets/utils';

export interface ExternalNodesViewProps {
  externalNodes: NodeListOf<HTMLDivElement> | null;
}

export function ExternalNodesView({ externalNodes }: ExternalNodesViewProps) {
  const { onDiagramLoad } = useLoadDiagrams({ externalNodes });

  const nodes = useMemo(() => {
    if (!externalNodes) {
      return null;
    }

    const nodes: ReturnType<typeof createPortal>[] = [];

    externalNodes.forEach((el) => {
      const type = el.getAttribute(ExternalNodeAttributes.externalNodeType);

      if (!type) {
        console.error('Внешний узел не имеет типа для элемента', el);
        return;
      }

      const id = el.getAttribute(ExternalNodeAttributes.externalNodeId);

      if (!id) {
        console.error(`Внешний узел типа ${type} не имеет идентификатора`);
        return null;
      }

      let node: React.ReactNode | null = null;

      switch (type) {
        case JsonDocumentBlockType.table:
          node = <TableView id={id} />;
          break;
        case JsonDocumentBlockType.diagram:
          node = <DrawioPreview id={id} onDiagramLoad={onDiagramLoad(id)} />;
          break;
        case JsonDocumentBlockType.customNode:
          node = <CustomNode id={id} />;
          break;
        default: {
          const Widget = getWidget(type);

          if (Widget) {
            const widget = new Widget(id);
            const editorView = widget.getEditorView();

            if (editorView !== null) {
              node = widget.getEditorViewWrapper();
            }
          }
        }
      }

      if (node === null) {
        return;
      }

      nodes.push(createPortal(node, el, id));
    });

    return nodes;
  }, [externalNodes, onDiagramLoad]);

  return <>{nodes}</>;
}

interface UseLoadDiagramsProps {
  externalNodes: NodeListOf<HTMLDivElement> | null;
}

function useLoadDiagrams({ externalNodes }: UseLoadDiagramsProps) {
  const dispatch = useAppDispatch();
  const [loadDiagramIds, setLoadDiagramIds] = useState<string[]>([]);

  useLayoutEffect(() => {
    if (!externalNodes) {
      return;
    }

    const diagramNodes = Array.from(externalNodes).filter((node) => {
      const type = node.getAttribute(ExternalNodeAttributes.externalNodeType);

      return type === JsonDocumentBlockType.diagram;
    });

    const isReady = diagramNodes.every((node) => {
      const id = node.getAttribute(ExternalNodeAttributes.externalNodeId);

      return id !== null && loadDiagramIds.includes(id);
    });

    dispatch(setReadyPrint(isReady));

    const diffIds = loadDiagramIds.filter((id) => {
      return !diagramNodes.find((node) => {
        const nodeId = node.getAttribute(ExternalNodeAttributes.externalNodeId);

        return id === nodeId;
      });
    });

    if (diffIds.length) {
      setLoadDiagramIds(loadDiagramIds.filter((id) => !diffIds.includes(id)));
    }
  }, [dispatch, externalNodes, loadDiagramIds]);

  const onDiagramLoad = useCallback(
    (id: string) => () => {
      setLoadDiagramIds((prevState) => {
        if (prevState.includes(id)) {
          return prevState;
        }

        return [...prevState, id];
      });
    },
    []
  );

  return { onDiagramLoad };
}
