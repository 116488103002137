import { Engine } from '../engine/engine';
import { ModelEventTypes, Models } from '../engine/types';
import { useEffect, useState } from 'react';

const engine: Engine = Engine.getInstance();

function getModelList(modelType: Models | undefined, metaModelName?: string) {
  if (modelType === Models.Entity) {
    if (metaModelName) {
      return engine.getEntitiesIdsByMetaName(metaModelName);
    }
    return engine.getEntitiesIds();
  }
  if (modelType === Models.Relation) {
    if (metaModelName) {
      return engine.getRelationsIdsByMetaName(metaModelName);
    }
    return engine.getRelationsIds();
  }
  if (modelType === Models.EntityMetaModel) {
    return engine.getEntityMetaModelsNames();
  }
  if (modelType === Models.RelationMetaModel) {
    return engine.getRelationMetaModelsNames();
  }
  return [];
}

/**
 * Получение списка доступных моделей по типу модели
 * @param modelType
 * @param metaModelName
 */
export function useModels(modelType?: Models, metaModelName?: string) {
  const [models, setModels] = useState(getModelList(modelType, metaModelName));
  useEffect(() => {
    if (!modelType) {
      return;
    }
    return engine.subscribe(
      modelType,
      ModelEventTypes.addOrRemove,
      () => {
        setModels(getModelList(modelType, metaModelName));
      },
      [modelType]
    );
  });

  useEffect(() => {
    if (!modelType) {
      return;
    }
    setModels(getModelList(modelType, metaModelName));
  }, [modelType, metaModelName]);

  return models;
}
