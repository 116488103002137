import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { Engine } from '@baseModel/engine/engine';
import { EventEditorModelObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { Entity } from '@baseModel/model/entity';
import { JsonEntity } from '@baseModel/types/jsonDescription';

const engine = Engine.getInstance();

export function editorEntitiesListener(event: EventEditorModelObserver<JsonEntity>) {
  const {
    changes: { after },
    meta
  } = event;

  if (!meta.id) {
    return;
  }

  if (meta.deleted) {
    engine.removeEntity(meta.id, EDITOR_SENDER);
    return;
  }

  let isNew = false;
  let entity: Entity | undefined;

  try {
    entity = engine.getEntityById(meta.id);
  } catch {
    const type = after?.__type;

    if (!type) {
      return;
    }

    const metaModel = engine.getMetaEntityByName(type);

    if (!metaModel) {
      return;
    }

    isNew = true;
    entity = new Entity(metaModel);
  }

  for (const field in after) {
    if (field === '__type' || entity.getFieldValue(field) === after[field]) {
      continue;
    }

    try {
      if (after[field] === undefined) {
        entity.removeField(field, EDITOR_SENDER);
      } else {
        entity.setFieldValue(field, after[field], EDITOR_SENDER);
      }
    } catch {
      return;
    }
  }

  if (isNew) {
    engine.addEntity(entity, EDITOR_SENDER);
  }
}
