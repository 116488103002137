import { Models } from '@baseModel/engine/types';
import { useChangeModelsSubscription } from './useChangeModelsSubscription';
import { ChangeEngineModel, ChangeModel } from '@components/drawio/plugins/graphElements/graphElementResult';

export function useDrawioMetaModel(
  dataId: string,
  changeModel?: (changeModel: ChangeModel) => void,
  changeEngineModel?: (changeEngineModel: ChangeEngineModel) => void
) {
  const entities = useChangeModelsSubscription(dataId, Models.EntityMetaModel, changeModel, changeEngineModel);
  const relations = useChangeModelsSubscription(dataId, Models.RelationMetaModel, changeModel, changeEngineModel);

  return { entityMetaModelNames: entities, relationMetaMetaNames: relations };
}
