import { CreateSidebarElement, SidebarContextCreateElements } from './graphElementResult';
import { createElementEdgeCell } from './createElementEdgeCell';
import { MxCell } from '../../types/mxCell';

export function createSidebarElementEdge(
  this: SidebarContextCreateElements,
  element: CreateSidebarElement,
  uuid: string
): { node: HTMLElement; cell: MxCell } {
  const edge = createElementEdgeCell.apply(this, [element.attributes]);
  edge.uuid = uuid;

  const node = this.sidebar.createEdgeTemplateFromCells([edge], 100, 0, '');

  return { node, cell: edge };
}
