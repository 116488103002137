import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Models } from '@baseModel/engine/types';
import { ActionType } from '../types';

export interface ActionsState {
  type?: ActionType;
  modelType?: Models.EntityMetaModel | Models.RelationMetaModel;
  modelName?: string;
}

const initialState: ActionsState = {
  type: undefined,
  modelType: undefined,
  modelName: undefined
};

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ActionsState>) => {
      state.type = action.payload.type;
      state.modelType = action.payload.modelType;
      state.modelName = action.payload.modelName;
    },
    reset: () => initialState
  }
});

export const { set, reset } = actionsSlice.actions;

export default actionsSlice.reducer;
