import { Loggable } from '../loggable';
import { Entity } from '../model/entity';
import { Relation } from '../model/relation';
import { ModelError } from '../errors';
import { EntityMetaModel } from '../metaModel/entityMetaModel';
import { RelationMetaModel } from '../metaModel/relationMetaModel';
import { Listener, Observable, Unsubscriber } from '@utils/observable';
import { Document } from '../document/document';
import { EmptyObject } from '../utils/dataJuggler';
import {
  ModelTypes,
  ModelEventTypes,
  Models,
  EngineEventAddOrRemoveData,
  EngineEventUpdateData,
  EngineEventData,
  EngineEvent,
  UnitTypes,
  JSONSerializeEngine
} from './types';

const ENGINE_SENDER = 'engine';

/**
 * Основной класс управления состоянием модели данных приложения
 */
export class Engine extends Loggable {
  private readonly entityMetaModels: Map<string, EntityMetaModel> = new Map();
  private readonly relationMetaModels: Map<string, RelationMetaModel> = new Map();
  private readonly entities: Map<string, Entity> = new Map();
  private readonly relations: Map<string, Relation> = new Map();
  private readonly document: Document = new Document(this);

  private static readonly mainInstanceName = 'engine';
  private static instance: Map<string, Engine> = new Map();

  private readonly listeners: Map<Models, Map<ModelEventTypes, Observable<EngineEvent<EngineEventData>>>> = new Map();

  // Диаграмма метамодели
  private static readonly version: Observable<string> = new Observable('version', '');

  public static getEngineVersion(): typeof Engine.version {
    return this.version;
  }

  private constructor() {
    super(ENGINE_SENDER);
  }

  public static getInstance(instanceId = Engine.mainInstanceName): Engine {
    let singletonInstance = Engine.instance.get(instanceId);
    if (singletonInstance) {
      return singletonInstance;
    }
    singletonInstance = new Engine();
    Engine.instance.set(instanceId, singletonInstance);
    if (instanceId === Engine.mainInstanceName) {
      // if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
      (window as any)['engine'] = singletonInstance;
    }
    return singletonInstance;
  }

  /**
   * Удаление вспомогательных инстансов
   * @param instanceId
   */
  public static destroyInstance(instanceId: string): void {
    const singletonInstance = Engine.instance.get(instanceId);
    if (!singletonInstance) {
      console.warn(`Instance ${singletonInstance} not found and can not be destroyed`);
      return;
    }
    if (instanceId === Engine.mainInstanceName) {
      console.warn(`Instance ${singletonInstance} is main instance and can not be destroyed`);
      return;
    }
    singletonInstance.removeAll();
    Engine.instance.delete(instanceId);
  }

  public addEntityMetaModel(model: EntityMetaModel, sender?: string | EmptyObject) {
    const name = model.getName();
    if (this.getEntityMetaModelsNames().includes(name)) {
      throw new ModelError(`entityMetaModels c name ${name} уже существует`);
    }
    model.linkToEngine(this);
    this.entityMetaModels.set(name, model);
    model.subscribeModel(this.modelObserve(Models.EntityMetaModel, undefined, name), ENGINE_SENDER);
    this.emit(Models.EntityMetaModel, ModelEventTypes.add, { name }, sender);
  }

  public addRelationMetaModel(model: RelationMetaModel, sender?: string | EmptyObject) {
    const name = model.getName();
    if (this.getRelationMetaModelsNames().includes(name)) {
      throw new ModelError(`RelationMetaModel c name ${name} уже существует`);
    }
    model.linkToEngine(this);
    this.relationMetaModels.set(name, model);
    model.subscribeModel(this.modelObserve(Models.RelationMetaModel, undefined, name), ENGINE_SENDER);
    this.emit(Models.RelationMetaModel, ModelEventTypes.add, { name: name }, sender);
  }

  public addEntity(model: Entity, sender?: string | EmptyObject) {
    const id = model.getId();
    if (this.getEntitiesIds().includes(id || '')) {
      throw new ModelError(`Entity c id ${id} уже существует`);
    }
    model.linkToEngine(this);
    // про ID мы уже знаем из linkToEngine
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.entities.set(id!, model);
    model.subscribeModel(this.modelObserve(Models.Entity, model.getId(), undefined), ENGINE_SENDER);
    this.emit(Models.Entity, ModelEventTypes.add, { id }, sender);
  }

  public addRelation(model: Relation, sender?: string | EmptyObject) {
    const id = model.getId();
    if (this.getRelationsIds().includes(id || '')) {
      throw new ModelError(`Relation c id ${id} уже существует`);
    }
    model.linkToEngine(this);
    // про ID мы уже знаем из linkToEngine
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.relations.set(id!, model);
    model.subscribeModel(this.modelObserve(Models.Relation, model.getId(), undefined), ENGINE_SENDER);
    this.emit(Models.Relation, ModelEventTypes.add, { id }, sender);
  }

  public removeEntityMetaModel(name: string, sender?: string | EmptyObject) {
    const model = this.getMetaEntityByName(name);
    model.deleteMe();
    this.entityMetaModels.delete(name);
    this.emit(Models.EntityMetaModel, ModelEventTypes.remove, { name }, sender);
  }

  public removeRelationMetaModel(name: string, sender?: string | EmptyObject) {
    const model = this.getMetaRelationByName(name);
    model.deleteMe();
    this.relationMetaModels.delete(name);
    this.emit(Models.RelationMetaModel, ModelEventTypes.remove, { name }, sender);
  }

  public removeEntity(id: string, senderId?: string | EmptyObject) {
    const model = this.getEntityById(id);
    model.deleteMe();
    this.entities.delete(id);
    this.emit(Models.Entity, ModelEventTypes.remove, { id }, senderId);
  }

  public removeRelation(id: string, sender?: string | EmptyObject) {
    const model = this.getRelationById(id);
    model.deleteMe();
    this.relations.delete(id);
    this.emit(Models.Relation, ModelEventTypes.remove, { id }, sender);
  }

  private getFromEngineById(id: string, modelType: Models): ModelTypes {
    let model: ModelTypes | undefined;
    if (modelType === Models.Relation) {
      model = this.relations.get(id);
    } else if (modelType == Models.Entity) {
      model = this.entities.get(id);
    } else if (modelType === Models.RelationMetaModel) {
      model = this.relationMetaModels.get(id);
    } else if (modelType == Models.EntityMetaModel) {
      model = this.entityMetaModels.get(id);
    } else {
      const error = `model type ${modelType} not found`;
      this.logger(error);
      throw new ModelError(error);
    }
    if (!model) {
      const error = `model with id ${id} in modelType ${modelType} not found`;
      this.logger(error);
      throw new ModelError(error);
    }
    return model;
  }

  // TODO || undefined
  public getEntityById(id: string): Entity {
    return this.getFromEngineById(id, Models.Entity) as Entity;
  }

  // TODO || undefined
  public getRelationById(id: string): Relation {
    return this.getFromEngineById(id, Models.Relation) as Relation;
  }

  // TODO || undefined
  public getMetaEntityByName(name: string): EntityMetaModel {
    return this.getFromEngineById(name, Models.EntityMetaModel) as EntityMetaModel;
  }

  // TODO || undefined
  public getMetaRelationByName(name: string): RelationMetaModel {
    return this.getFromEngineById(name, Models.RelationMetaModel) as RelationMetaModel;
  }

  private emit(modelType: Models, eventType: ModelEventTypes, data: EngineEventData, sender?: string | EmptyObject) {
    const mapEvents = this.listeners.get(modelType);
    this.logger(`modeType ${modelType} event ${eventType} data ${JSON.stringify(data)} sender ${sender}`);
    if (!mapEvents) {
      return;
    }
    const event: EngineEvent<EngineEventData> = {
      type: modelType,
      event: eventType,
      data
    };
    const observable = mapEvents.get(eventType);
    if (observable) {
      observable.setValue(event, sender);
    }
    if (eventType === ModelEventTypes.add || eventType === ModelEventTypes.remove) {
      const observableAddOrRemove = mapEvents.get(ModelEventTypes.addOrRemove);
      if (observableAddOrRemove) {
        observableAddOrRemove.setValue(event, sender);
      }
    }
    const observableAll = mapEvents.get(ModelEventTypes.all);
    if (observableAll) {
      observableAll.setValue(event, sender);
    }
  }

  private modelObserve(
    modelType: Models,
    id?: string,
    name?: string
  ): (updateData: EngineEventUpdateData['data']) => void {
    return (updateData, senderId?: string | EmptyObject) => {
      this.emit(modelType, ModelEventTypes.update, { id, name, data: updateData }, senderId);
    };
  }

  /**
   * Подписаться на изменения моделей
   * @param modelType
   * @param modelEvents
   * @param listener
   * @param sender
   */
  public subscribe(
    modelType: Models,
    modelEvents: ModelEventTypes.update,
    listener: Listener<EngineEvent<EngineEventUpdateData>>,
    sender?: string | EmptyObject
  ): Unsubscriber;
  public subscribe(
    modelType: Models,
    modelEvents: Omit<ModelEventTypes, 'update'>,
    listener: Listener<EngineEvent<EngineEventAddOrRemoveData>>,
    sender?: string | EmptyObject
  ): Unsubscriber;
  public subscribe(
    modelType: Models,
    modelEvents: ModelEventTypes,
    listener: Listener<EngineEvent<EngineEventUpdateData>> | Listener<EngineEvent<EngineEventAddOrRemoveData>>,
    sender?: string | EmptyObject
  ): Unsubscriber {
    let mapEvents = this.listeners.get(modelType);
    if (!mapEvents) {
      mapEvents = new Map();
      this.listeners.set(modelType, mapEvents);
    }
    let observable = mapEvents.get(modelEvents);
    if (!observable) {
      const defaultEvent: EngineEvent<EngineEventData> = {
        type: modelType,
        event: ModelEventTypes.none,
        data: {}
      };
      observable = new Observable<EngineEvent<EngineEventData>>(`engine_${modelType}_${modelEvents}`, defaultEvent);
    }
    mapEvents.set(modelEvents, observable);
    if (modelEvents === ModelEventTypes.update) {
      return (observable as Observable<EngineEvent<EngineEventUpdateData>>).subscribe(listener, sender);
    }
    return (observable as Observable<EngineEvent<EngineEventAddOrRemoveData>>).subscribe(
      listener as Listener<EngineEvent<EngineEventAddOrRemoveData>>,
      sender
    );
  }

  public getEntityMetaModelsNames(): string[] {
    return Array.from(this.entityMetaModels.keys());
  }

  public getRelationMetaModelsNames(): string[] {
    return Array.from(this.relationMetaModels.keys());
  }

  public getEntitiesIds(): string[] {
    return Array.from(this.entities.keys());
  }

  /**
   * Получить список id entity моделей, отфильтрованных, по названию их метамодели
   * @param metaName
   */
  public getEntitiesIdsByMetaName(metaName?: string): string[] | [] {
    const ids: string[] = [];
    this.entities.forEach((el) => {
      const id = el.getId();
      if (el.getMetaModel().getName() === metaName && id) {
        ids.push(id);
      }
    });
    return ids;
  }

  public getRelationsIds(): string[] {
    return Array.from(this.relations.keys());
  }

  /**
   * Получить список id relations моделей, отфильтрованных, по названию их метамодели
   * @param metaName
   */
  public getRelationsIdsByMetaName(metaName: string): string[] {
    const ids: string[] = [];
    this.relations.forEach((el) => {
      const id = el.getId();
      if (el.getMetaModel().getName() === metaName && id) {
        ids.push(id);
      }
    });
    return ids;
  }

  public removeAll() {
    this.entities.forEach((el) => {
      const id = el.getId();
      id && this.removeEntity(id);
    });
    this.entities.clear();
    this.relations.forEach((el) => {
      const id = el.getId();
      id && this.removeRelation(id);
    });
    this.relations.clear();
    this.entityMetaModels.forEach((el) => {
      const name = el.getName();
      name && this.removeEntityMetaModel(name);
    });
    this.entityMetaModels.clear();
    this.relationMetaModels.forEach((el) => {
      const name = el.getName();
      name && this.removeRelationMetaModel(name);
    });
    this.relationMetaModels.clear();
    this.document.removeAll();
  }

  public getDocument(): Document {
    return this.document;
  }

  // TODO toJSON и fromJSON оперируют с разными JSON, что неверно
  public toJSON(): JSONSerializeEngine<keyof UnitTypes> & {
    version: string;
  } {
    return {
      relationMetaModels: Array.from(this.relationMetaModels.values()).map((el) => el.toJSON()),
      entityMetaModels: Array.from(this.entityMetaModels.values()).map((el) => el.toJSON()),
      relations: Array.from(this.relations.values()).map((el) => el.toJSON()),
      entities: Array.from(this.entities.values()).map((el) => el.toJSON()),
      document: this.getDocument().toJSON(),
      version: Engine.getEngineVersion().value || ''
    };
  }
}
