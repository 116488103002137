import { DrawioContext } from '../types/window';
import { Theme } from '@smwb/summer-ui/dist/hooks/useTheme';

export function themePlugin(this: DrawioContext) {
  return (theme: Theme) => {
    const isCurrentDarkTheme = this.Editor.darkMode;
    const isNewDarkTheme = theme === Theme.dark;

    if (isCurrentDarkTheme !== isNewDarkTheme) {
      this.EditorUi.setAndPersistDarkMode(isNewDarkTheme);
      return true;
    }

    return false;
  };
}
