import { DrawioContext } from '../types/window';
import { CreateElement, ID } from './graphElements/graphElementResult';
import { MxCell } from '../types/mxCell';
import find from 'lodash/find';
import { checkType } from './edit';

export function loadCheckEdgeCellsPlugin(this: DrawioContext) {
  const checkEdgeCells = (edge: MxCell, target: string | null, source: string | null) => {
    if (!edge.edge || !edge.id) return;

    // const edgeTarget = edge.target?.getAttribute(ID, null) || null;
    // const edgeSource = edge.source?.getAttribute(ID, null) || null;

    if (this.EditorUi.editor.graph.model.endingUpdate) {
      setTimeout(() => checkEdgeCells(edge, target, source), 100);
      return;
    }

    // убрано по просьбе 30.08.2023
    // if (target !== edgeTarget || source !== edgeSource) {
    //   this.EditorUi.editor.graph.setCellStyles(this.mxConstants.STYLE_STROKECOLOR, '#FF0000', [edge]);
    // } else {
    //   this.EditorUi.editor.graph.setCellStyles(this.mxConstants.STYLE_STROKECOLOR, '#000000', [edge]);
    // }
  };

  return checkEdgeCells;
}

export function loadCheckEngineCellsPlugin(this: DrawioContext) {
  const removeCell = loadRemoveCellPlugin.apply(this);

  return (elements: CreateElement[]) => {
    const cells = this.EditorUi.editor.graph.model.cells;
    for (const id in cells) {
      const cell = cells[id];
      const _id = cell.getAttribute(ID, null);
      if (!_id) {
        removeCell(cell);
        continue;
      }

      const modelElement = find(elements, function (o) {
        return find(o.attributes, { type: ID })?.value === _id && checkType(cell, o.type);
      });
      if (!modelElement) removeCell(cell);
    }
  };
}

export function loadRemoveCellPlugin(this: DrawioContext) {
  const removeCell = (cell: MxCell) => {
    if (this.EditorUi.editor.graph.model.endingUpdate) {
      setTimeout(() => removeCell(cell), 100);
      return;
    }

    this.EditorUi.editor.graph.setCellStyles(
      this.mxConstants.STYLE_STROKECOLOR,
      this.Editor.darkMode ? '#444444' : '#666666',
      [cell]
    );
  };

  return removeCell;
}

export function loadErrorHandlerPlugin(this: DrawioContext) {
  return (e: unknown) => {
    const mxLog = this.mxLog;
    const isChromelessView = this.EditorUi.editor.isChromelessView();

    if ((typeof e === 'string' || e instanceof Error) && !isChromelessView) {
      if (!mxLog.isVisible()) mxLog.show();

      mxLog.warn(e);
    } else {
      console.error(e);
    }
  };
}
