import React, { useCallback, useEffect } from 'react';
import { Engine } from '@baseModel/engine/engine';
import { ModelEventTypes, Models } from '@baseModel/engine/types';
import { ChangeEngineModel } from '@components/drawio/plugins/graphElements/graphElementResult';

export function useEngineModelsSubscription(
  model: Models,
  changeEngineModelProp?: (changeEngineModel: ChangeEngineModel) => void
): string[] {
  const getKeys = useCallback(() => {
    const engine = Engine.getInstance();

    if (model === Models.Entity || model === Models.EntityMetaModel)
      return model === Models.Entity ? engine.getEntitiesIds() : engine.getEntityMetaModelsNames();
    else return model === Models.Relation ? engine.getRelationsIds() : engine.getRelationMetaModelsNames();
  }, [model]);

  const [keys, setKeys] = React.useState(getKeys());

  useEffect(() => {
    const engine = Engine.getInstance();

    return engine.subscribe(model, ModelEventTypes.addOrRemove, (value) => {
      const modelKey = value.data.id || value.data.name;
      modelKey && changeEngineModelProp && changeEngineModelProp({ model, modelKey, value });
      setKeys(getKeys());
    });
  }, [changeEngineModelProp, getKeys, model]);

  return keys;
}
