import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { App } from '../../../types/draw';
import { MxCell } from '../../../types/mxCell';
import { DrawioContext } from '../../../types/window';
import { ID } from '../../graphElements/graphElementResult';
import { Models } from '@baseModel/engine/types';
import { useDrawioMetaModelFieldNames } from '@hooks/useDrawioMetaModelFieldNames';
import { JsonField } from '@baseModel/types/jsonDescription';
import find from 'lodash/find';
import { loadSetAttributeByIdPlugin } from '../../edit';
import { getTypeCell } from '../../eventDifference/eventDifferenceResults';
import { changeFieldsName, getModelValues } from '@hooks/useDrawioModel';
import { getCellModelType, loadGetLabelByCellPlugin } from '../../graphElements';

interface DataDialogProps {
  context: DrawioContext;
  EditorUi: App;
  cell: MxCell;
}

export function DataDialog(props: DataDialogProps) {
  const { cell } = props;

  const type = cell.getAttribute('type', '');
  const modelType = getCellModelType(cell, type);

  if (!modelType) return <NotModelTDialogTable {...props} />;

  return <ModelTDialogTable {...props} modelType={modelType} />;
}

function DialogTable(
  props: DataDialogProps & {
    onSubmit?: () => void;
    disabled: boolean;
    children: React.ReactNode;
  }
) {
  const { EditorUi, context, onSubmit, disabled, children } = props;

  return (
    <div style={{ maxHeight: '100%', overflowX: 'hidden' }}>
      <div style={{ position: 'absolute', inset: '30px 30px 80px', overflowY: 'auto' }}>
        <table className="properties" style={{ width: '100%' }}>
          <tbody>{children}</tbody>
        </table>
      </div>
      <div
        style={{
          position: 'absolute',
          left: '30px',
          right: '30px',
          textAlign: 'right',
          bottom: '30px',
          height: '40px'
        }}
      >
        <button onClick={() => EditorUi.hideDialog(EditorUi)} className="geBtn">
          {context.mxResources.get('cancel')}
        </button>
        <button onClick={onSubmit} className="geBtn gePrimaryBtn" disabled={disabled}>
          {context.mxResources.get('apply')}
        </button>
      </div>
    </div>
  );
}

function ModelTDialogTable(
  props: DataDialogProps & {
    modelType: Models;
  }
) {
  const { cell, context, EditorUi, modelType } = props;

  const setAttributeById = loadSetAttributeByIdPlugin.apply(context);
  const getLabel = loadGetLabelByCellPlugin.apply(context);

  const type = cell.getAttribute('type', '');
  const _id = cell.getAttribute(ID, '');

  const [values, setValues] = useState<{
    [key: string]: {
      value: string;
    };
  }>({});
  const [errors, setErrors] = useState<{
    [key: string]: {
      isError: boolean;
    };
  }>({});
  const [isErrorForm, setIsErrorForm] = useState<boolean>(false);
  const model = useDrawioMetaModelFieldNames(modelType, type);

  useEffect(() => {
    setIsErrorForm(!!find(errors, { isError: true }));
  }, [errors]);

  const submit = () => {
    if (!isErrorForm) {
      for (const i in values) {
        setAttributeById(_id, i, values[i].value, getTypeCell(cell));
      }

      EditorUi.hideDialog(EditorUi);
    }
  };

  const setFormValues = useCallback(
    (value: string, drawioFieldName?: string) => {
      if (drawioFieldName) setValues((prevState) => ({ ...prevState, ...{ [drawioFieldName]: { value } } }));
    },
    [setValues]
  );

  const setFormError = useCallback(
    (isError: boolean, drawioFieldName?: string) => {
      if (drawioFieldName) setErrors((prevState) => ({ ...prevState, ...{ [drawioFieldName]: { isError } } }));
    },
    [setErrors]
  );

  const fieldNames = () => {
    return model.fieldNames?.map((fieldName) => {
      const drawioFieldName = changeFieldsName(fieldName, false);

      if ([ID].indexOf(drawioFieldName) === -1) {
        const fieldValue = model.entityModel.getFieldValue(fieldName) as JsonField | undefined;
        return (
          <EditProperties
            key={drawioFieldName}
            name={drawioFieldName}
            fieldValue={fieldValue}
            value={drawioFieldName === 'label' ? getLabel(cell) : cell.getAttribute(drawioFieldName)}
            setFormValues={setFormValues}
            setFormError={setFormError}
          />
        );
      }
    });
  };

  const relationNames = () => {
    if (modelType !== Models.RelationMetaModel) return;

    const valueEdge = getModelValues(Models.Relation, _id);

    return model.relationNames?.map((fieldName) => {
      const fieldValue = model.entityModel.getRelationValue(fieldName) as JsonField | undefined;
      const value = (() => {
        if (fieldName === valueEdge.edge.source?.key)
          return `${valueEdge.edge.source.name} (${valueEdge.edge.source.id})`;
        else if (fieldName === valueEdge.edge.target?.key)
          return `${valueEdge.edge.target.name} (${valueEdge.edge.target.id})`;
        else return '-';
      })();

      return <ReadonlyRelationProperties key={fieldName} name={fieldName} fieldValue={fieldValue} value={value} />;
    });
  };

  return (
    <DialogTable {...props} onSubmit={() => submit()} disabled={isErrorForm}>
      <ReadonlyProperties name={ID} value={_id} />
      <ReadonlyProperties name="type" value={type} />
      {fieldNames()}
      {relationNames()}
    </DialogTable>
  );
}

function NotModelTDialogTable(props: DataDialogProps) {
  const { context, EditorUi, cell } = props;

  const getLabel = loadGetLabelByCellPlugin.apply(context);
  const [label, setLabel] = useState<string>(getLabel(cell));
  const [errors, setErrors] = useState<{
    [key: string]: {
      isError: boolean;
    };
  }>({});
  const [isErrorForm, setIsErrorForm] = useState<boolean>(false);

  useEffect(() => {
    setIsErrorForm(!!find(errors, { isError: true }));
  }, [errors]);

  const submit = () => {
    EditorUi.editor.graph.setAttributeForCell(cell, 'label', label);
    EditorUi.hideDialog(EditorUi);
  };

  const setFormValues = useCallback((value: string) => setLabel(value), [setLabel]);

  const setFormError = useCallback(
    (isError: boolean) => setErrors((prevState) => ({ ...prevState, ...{ ['label']: { isError } } })),
    [setErrors]
  );

  return (
    <DialogTable {...props} onSubmit={() => submit()} disabled={isErrorForm}>
      <EditProperties
        name={'label'}
        value={label}
        fieldValue={{ type: 'label', 'display-name': 'Описание', required: true }}
        setFormValues={setFormValues}
        setFormError={setFormError}
      />
    </DialogTable>
  );
}

function ReadonlyProperties({ name, value }: { name: string; value: string }) {
  return (
    <tr>
      <td>{name}:</td>
      <td>
        <div style={{ width: '100%' }}>{value}</div>
      </td>
    </tr>
  );
}

function ReadonlyRelationProperties({
  name,
  fieldValue,
  value
}: {
  name: string;
  fieldValue?: JsonField;
  value: string;
}) {
  return (
    <tr>
      <td>{fieldValue?.['display-name'] || name}:</td>
      <td>
        <div style={{ width: '100%' }}>{value}</div>
      </td>
    </tr>
  );
}

function EditProperties({
  name,
  fieldValue,
  value,
  setFormValues,
  setFormError
}: {
  name: string;
  fieldValue?: JsonField;
  value: string;
  setFormValues: (value: string, name?: string) => void;
  setFormError: (isErrorForm: boolean, name?: string) => void;
}) {
  const [state, setState] = useState<string>(value);
  const [isError, setIsError] = useState<boolean>(false);
  const isRequired = fieldValue?.required;

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (fieldValue?.type === 'string') setState(event.target.value);
    else setState(event.target.value);
  };

  const editor = (() => {
    if (fieldValue?.type === 'string')
      return <textarea rows={1} style={{ width: '100%' }} value={state} onChange={onChange} />;
    else return <textarea rows={1} style={{ width: '100%' }} value={state} onChange={onChange} />;
  })();

  useEffect(() => {
    isRequired && setIsError(!state);
  }, [isRequired, state]);

  useEffect(() => {
    setFormError(isError, name);
  }, [isError, name, setFormError]);

  useEffect(() => {
    setFormValues(state, name);
  }, [state, name, setFormValues]);

  return (
    <tr>
      <td style={isError ? { color: 'red' } : {}}>
        {fieldValue?.['display-name'] || name}
        {isRequired && ' *'}:
      </td>
      <td>
        <div style={{ position: 'relative', paddingRight: '20px', boxSizing: 'border-box', width: '100%' }}>
          {editor}
        </div>
      </td>
    </tr>
  );
}
