import {
  EventDifferenceAs,
  Properties,
  differenceObjResult,
  EventDifferenceResultsValues,
  EventDifferenceResultsId,
  getTypeCell
} from './eventDifferenceResults';
import { MxValue } from '../../types/mxValueChange';
import { ID } from '../graphElements/graphElementResult';
import { MxCell } from '../../types/mxCell';
import find from 'lodash/find';

export function getEventDifferenceValue(
  change: MxValue,
  getLabel: (cell: MxCell) => string
): EventDifferenceResultsValues | EventDifferenceResultsId {
  const value = getAttributesArray(change.value, getLabel(change.cell));
  const previous = getAttributesArray(change.previous, '');
  const difference = differenceObjResult(value, previous);

  const res = {
    id: <string>change.cell.id,
    _id: change.cell.getAttribute(ID, null),
    type: getTypeCell(change.cell)
  };

  return find(difference, { type: ID })
    ? {
        ...res,
        as: EventDifferenceAs.id,
        attributes: differenceObjResult(value, previous, false)
      }
    : {
        ...res,
        as: EventDifferenceAs.attributes,
        attributes: difference
      };
}

export function getAttributesArray(value: HTMLElement | string, label?: string): Properties {
  const res = <Properties>{};

  if (typeof value === 'string') return { label: value };
  if (value === null) return { label: '' };

  const attributes = value.attributes;
  for (let i = 0; i < attributes.length; i++) {
    const { name, value } = attributes[i];

    if (name === 'label' && label !== undefined) {
      res[name] = label;
      continue;
    }

    res[name] = value;
  }

  return res;
}
