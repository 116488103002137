import { MxCell } from '../../types/mxCell';
import {
  GraphContextGetElements,
  GraphElementResultEdge,
  GraphElementResultTypes,
  GraphElementResultVertex,
  ID
} from './graphElementResult';
import { getAttributesArray } from '../eventDifference/eventDifferenceValue';
import { getStyleArray } from '../eventDifference/eventDifferenceStyles';
import { getGraphElementEdge } from './getGraphElementEdge';

export function getGraphElementVertex(this: GraphContextGetElements, cell: MxCell): GraphElementResultVertex {
  const edges = <GraphElementResultEdge[]>[];
  const cellEdges = cell.edges;

  if (cellEdges) for (let i = 0; i < cellEdges.length; i++) edges.push(getGraphElementEdge.apply(this, [cellEdges[i]]));

  return {
    id: <string>cell.id,
    _id: cell.getAttribute(ID, null),
    label: this.getLabel(cell),
    attributes: cell.value ? getAttributesArray(cell.value) : {},
    styles: cell.style ? getStyleArray(cell.style) : {},
    type: GraphElementResultTypes.vertex,
    edges: edges
  };
}
