import React from 'react';
import { Config as ConfigFinalForm } from 'final-form';
import { Form } from 'react-final-form';
import { ConfigViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { DiagramGenerationValue } from '@components/markdownEditor/dataDisplayWidgets/widgets/diagramGeneration/diagramGeneration';
import {
  ConfigForm,
  FormFields
} from '@components/markdownEditor/dataDisplayWidgets/widgets/diagramGeneration/components/configForm/configForm';
import s from './config.module.less';

export function Config({ initData, onSave, isNew }: ConfigViewProps<DiagramGenerationValue>) {
  const onSubmit: ConfigFinalForm<FormFields>['onSubmit'] = async ({ mapping }) => {
    try {
      await onSave({ mapping: mapping.trim() });
    } catch (error) {
      console.error(`Не удалось сохранить данные:`, error);
    }
  };

  const initialValues: FormFields = {
    mapping: initData.mapping
  };

  return (
    <div className={s.root}>
      <div className={s.header}>{`${isNew ? 'Создать' : 'Изменить'} Генерацию модели`}</div>
      <Form<FormFields>
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => <ConfigForm onSubmit={handleSubmit} />}
      ></Form>
    </div>
  );
}
