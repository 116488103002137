import { TextModel } from '../types';

export function findEndLine(
  model: TextModel,
  startLine: number,
  startColumn: number,
  includeEmptyLines = false
): number {
  const fullRange = model.getFullModelRange();

  let currentLine = startLine;
  let endLine = startLine;

  while (fullRange.endLineNumber > currentLine) {
    const nextLine = currentLine + 1;
    const nextLineStartColumn = model.getLineFirstNonWhitespaceColumn(nextLine);

    if (nextLineStartColumn > startColumn) {
      currentLine++;
      endLine = currentLine;
    } else if (nextLineStartColumn === 0) {
      currentLine++;
    } else {
      break;
    }
  }

  if (includeEmptyLines) {
    return currentLine;
  }

  return endLine;
}
