import React, { useCallback, useEffect } from 'react';
import { Editor } from '../editor/editor';
import { DrawEvents, EventsListener } from '../types/events';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useTypedSelector } from '../../../redux/types';
import { useDispatch } from 'react-redux';
import { close } from '../redux/editSheet';
import { Sheet } from '@smwb/summer-ui';
import { DrawioFunction } from '../types/plugins';
import { Engine } from '@baseModel/engine/engine';
import {
  EventDifferenceAs,
  EventDifferenceResults,
  EventDifferenceResultsChild,
  EventDifferenceResultsId,
  // EventDifferenceResultsStyles,
  EventDifferenceResultsTerminal,
  EventDifferenceResultsValues
} from '../plugins/eventDifference/eventDifferenceResults';
import { GraphElementResultTypes, SenderBaseModel } from '../plugins/graphElements/graphElementResult';
import { setEntityModelAttributes } from '@hooks/useDrawioModel';
import find from 'lodash/find';
import { useDiagram } from '@baseModel/document/hooks/useDiagram';
import { DRAW_IO_SENDER } from '../const';
import { Relation } from '@baseModel/model/relation';
import { Entity } from '@baseModel/model/entity';
import { getEnds } from '@components/drawio/utils';

const newEngineModels: EventDifferenceResultsId[] = [];

export function EditSheet() {
  const dispatch = useDispatch();
  const { open: isOpen, dataId } = useTypedSelector((state) => state.app.drawio.editSheet);
  const [drawioFuncs, setDrawioFuncs] = React.useState<DrawioFunction | null>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [preview, setDiagramData] = useDiagram(dataId, DRAW_IO_SENDER);
  const [base64, setBase64] = React.useState<string>(preview?.base64 || '');
  const [contains, setContains] = React.useState<Record<string, string[]>[] | undefined>(preview?.contains);

  const handleAddToContains = useCallback(
    (name: string, id: string) => {
      const oldContains = [...(contains || [])][0] || {};
      const modelDiagramItems = oldContains?.[name];

      if (modelDiagramItems && modelDiagramItems.includes(id)) return;
      modelDiagramItems ? modelDiagramItems.push(id) : (oldContains[name] = [id]);
      setContains([oldContains]);
    },
    [contains]
  );

  const handleRemoveFromContains = useCallback(
    (name: string, id: string) => {
      const oldContains = [...(contains || [])][0] || {};
      const modelDiagramItems = oldContains[name];
      if (!modelDiagramItems) return;

      const idIndex = modelDiagramItems.findIndex((containId) => containId === id);
      if (idIndex !== -1) {
        modelDiagramItems.splice(idIndex, 1);
      }
      setContains([oldContains]);
    },
    [contains]
  );

  const onSubscribeEvents = useCallback(
    (eventsListener: EventsListener) => {
      const engine = Engine.getInstance();

      const eventBase64Unsubscribe = eventsListener.addListener({
        event: DrawEvents.changeModelBase64,
        handler: (base64) => {
          if (dataId) {
            setBase64(base64.toString());
          } else {
            console.error('data is undefined');
          }
        }
      });
      const eventModelDifferenceUnsubscribe = eventsListener.addListener({
        event: DrawEvents.changeModelDifferenceJson,
        handler: (data) => {
          const sender = SenderBaseModel + (dataId || 'undefined');
          for (let i = 0; i < data.length; i++) {
            const event = data[i] as EventDifferenceResults;

            if (event._id && event.type) {
              switch (event.as) {
                case EventDifferenceAs.attributes: {
                  const eventValues = event as EventDifferenceResultsValues;
                  const entityModel =
                    event.type === GraphElementResultTypes.vertex
                      ? engine.getEntityById(event._id)
                      : engine.getRelationById(event._id);

                  setEntityModelAttributes(eventValues.attributes, entityModel, sender);
                  break;
                }
                // убрано по просьбе 30.08.2023
                // case EventDifferenceAs.styles: {
                //   const eventStyles = event as EventDifferenceResultsStyles;
                //   if (event.type === GraphElementResultTypes.vertex) {
                //     const entityModel = engine.getEntityById(event._id);
                //     entityModel.setFieldValue('style', eventStyles.style, sender);
                //   }
                //   break;
                // }
                case EventDifferenceAs.id: {
                  const eventId = event as EventDifferenceResultsId;
                  const attributeType = find(eventId.attributes, { type: 'type' })?.value;

                  if (event.type === GraphElementResultTypes.vertex) {
                    const entityName = attributeType || engine.getEntityMetaModelsNames()[0] || '';
                    const metaModel = engine.getMetaEntityByName(entityName);
                    const entityModel = new Entity(metaModel);
                    setEntityModelAttributes(
                      [{ type: 'type', value: entityName, previous: null }, ...eventId.attributes],
                      entityModel,
                      sender
                    );
                    handleAddToContains(entityName, event._id);
                    engine.addEntity(entityModel);
                  } else if (event.type === GraphElementResultTypes.edge) {
                    const relationName = attributeType || engine.getRelationMetaModelsNames()[0] || '';
                    const metaModel = engine.getMetaRelationByName(relationName);
                    const entityModel = new Relation(metaModel);
                    setEntityModelAttributes(
                      [{ type: 'type', value: relationName, previous: null }, ...eventId.attributes],
                      entityModel,
                      sender
                    );
                    handleAddToContains(relationName, event._id);
                    engine.addRelation(entityModel);
                  }

                  newEngineModels.push(eventId);
                  break;
                }
                case EventDifferenceAs.child: {
                  const eventChild = event as EventDifferenceResultsChild;
                  handleAddToContains(eventChild.typeAttribute, event._id);
                  if (eventChild.index.value === null) {
                    const findElement = find(newEngineModels, { id: eventChild.id });
                    handleRemoveFromContains(eventChild.typeAttribute, event._id);
                    if (findElement) {
                      event.type === GraphElementResultTypes.vertex
                        ? engine.removeEntity(event._id)
                        : engine.removeRelation(event._id);
                    }
                  } else {
                    handleAddToContains(eventChild.typeAttribute, event._id);
                  }
                  break;
                }
                case EventDifferenceAs.terminal: {
                  const eventTerminal = event as EventDifferenceResultsTerminal;
                  const relationModel = engine.getRelationById(event._id);
                  const { metaSourceName, metaTargetName } = getEnds(relationModel);

                  let relationName: string | undefined;

                  if (eventTerminal.edge.isArrowEnd) {
                    relationName = eventTerminal.edge.isSource ? metaSourceName : metaTargetName;
                  } else {
                    if (eventTerminal.edge.terminal !== null) {
                      const modelName = engine.getEntityById(eventTerminal.edge.terminal).getMetaModel().getName();

                      relationName =
                        modelName === metaSourceName
                          ? metaSourceName
                          : modelName === metaTargetName
                          ? metaTargetName
                          : undefined;
                    }
                  }

                  if (relationName === undefined) {
                    break;
                  }

                  const oldValue = relationModel.getRelationValue(relationName);

                  const findElement = find(newEngineModels, { id: eventTerminal.id });
                  if (!oldValue && findElement)
                    relationModel.setRelationValue(relationName, eventTerminal.edge.terminal || 0);
                  break;
                }
              }
            } else if (event.type && event._id === null) {
              switch (event.as) {
                case EventDifferenceAs.id: {
                  const eventId = event as EventDifferenceResultsId;
                  const attributeType = find(eventId.attributes, { type: 'type' })?.value;
                  const entityName =
                    event.type === GraphElementResultTypes.vertex
                      ? attributeType || engine.getEntityMetaModelsNames()[0] || ''
                      : event.type === GraphElementResultTypes.edge
                      ? attributeType || engine.getEntityMetaModelsNames()[0] || ''
                      : '';

                  const prevId = find(eventId.attributes, { type: '_id' })?.previous;
                  if (prevId === null || prevId === undefined) return;
                  handleRemoveFromContains(entityName, prevId);

                  const findElement = find(newEngineModels, { id: eventId.id });
                  if (findElement) {
                    event.type === GraphElementResultTypes.vertex
                      ? engine.removeEntity(prevId)
                      : engine.removeRelation(prevId);
                  }
                  break;
                }

                default: {
                  return;
                }
              }
            }
          }
        }
      });

      return () => {
        eventBase64Unsubscribe();
        eventModelDifferenceUnsubscribe();
      };
    },
    [handleAddToContains, dataId, handleRemoveFromContains]
  );

  const onLoadGraph = (drawio: DrawioFunction) => {
    const graphElems = drawio.getGraphElements();
    const diagramContains: Record<string, string[]> = {};

    graphElems.forEach((el) => {
      if (el._id === null || el._id === undefined) return;
      const modelType = el.attributes.type;
      if (modelType in diagramContains) {
        diagramContains[modelType].push(el._id);
      } else {
        diagramContains[modelType] = [el._id];
      }
    });

    setDrawioFuncs(drawio);
    setContains([diagramContains]);
    if (setDiagramData && preview) {
      setDiagramData({ ...preview, contains: [diagramContains] });
    }
  };

  useEffect(() => {
    if (drawioFuncs) return onSubscribeEvents(drawioFuncs.getEventsListener());
  }, [drawioFuncs, onSubscribeEvents]);

  useEffect(() => {
    if (!setDiagramData || !base64) return;

    preview
      ? setDiagramData({ ...preview, base64: base64, contains: contains || [] })
      : setDiagramData({ base64: base64, allowedTypes: [], contains: contains || [] });

    return () => setBase64('');
  }, [base64, preview, setDiagramData, contains]);

  return (
    <Sheet placement={isMobile ? 'bottom' : 'right'} isOpen={isOpen} onClose={() => dispatch(close())}>
      {!dataId && <span>dataId not found</span>}
      {dataId && <Editor dataId={dataId} onLoadGraph={onLoadGraph} />}
    </Sheet>
  );
}
