import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ConfigBlock {
  isOpen: boolean;
  id: string;
  type: string;
  sortIndex?: number;
}

export interface MarkdownEditorState {
  highlightedDocumentBlockId?: string;
  isReadyPrint?: boolean;
  configBlock: ConfigBlock;
}

const initialState: MarkdownEditorState = {
  highlightedDocumentBlockId: '',
  isReadyPrint: true,
  configBlock: {
    isOpen: false,
    id: '',
    type: '',
    sortIndex: undefined
  }
};

export const markdownEditorSlice = createSlice({
  name: 'markdownEditor',
  initialState,
  reducers: {
    highlightDocumentBlockById(state, action: PayloadAction<string | undefined>) {
      state.highlightedDocumentBlockId = action.payload;
    },
    setReadyPrint(state, action: PayloadAction<boolean>) {
      state.isReadyPrint = action.payload;
    },
    openConfigBlock(state, action: PayloadAction<{ id: string; type: string; sortIndex?: number }>) {
      state.configBlock.isOpen = true;
      state.configBlock.id = action.payload.id;
      state.configBlock.type = action.payload.type;
      state.configBlock.sortIndex = action.payload.sortIndex;
    },
    closeConfigBlock(state) {
      state.configBlock = initialState.configBlock;
    }
  }
});

export const { highlightDocumentBlockById, setReadyPrint, openConfigBlock, closeConfigBlock } =
  markdownEditorSlice.actions;
export default markdownEditorSlice.reducer;
