import React, { useCallback, useEffect, useRef } from 'react';
import { useTheme } from '@smwb/summer-ui';
import { DRAW_IO_DEFAULT_SOURCE_PATH } from '../const';
import { useDispatch } from 'react-redux';
import { open } from '../redux/editSheet';
import { DrawioFunction } from '../types/plugins';
import { initDrawio } from '../plugins/init';
import { getDrawioModelValues, useDrawioModel } from '@hooks/useDrawioModel';
import { Models } from '@baseModel/engine/types';
import { useDiagram } from '@baseModel/document/hooks/useDiagram';
import { ChangeEngineModel, ChangeModel } from '../plugins/graphElements/graphElementResult';
import { useStateRef } from '@hooks/useStateRef';

export interface PreviewProps {
  id: string;
  onDiagramLoad: () => void;
}

export function Preview({ id, onDiagramLoad }: PreviewProps) {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const wrapperRef = React.useRef<HTMLIFrameElement>(null);
  const [drawio, setDrawio, drawioRef] = useStateRef<DrawioFunction | null>(null);
  const [isGraphElementsLoad, setIsGraphElementsLoad] = React.useState<boolean>(false);
  const { theme } = useTheme();
  const { entityModelIds, relationModelIds } = useDrawioModel(id, onChangeModel, onChangeEngineModel);

  const dispatch = useDispatch();
  const editOpen = useCallback(() => dispatch(open(id)), [id, dispatch]);

  const dispatchMouseUpEvent = () => {
    const mouseUpEvent = new MouseEvent('mouseup', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    wrapperRef.current?.dispatchEvent(mouseUpEvent);
  };

  const [preview] = useDiagram(id);

  const changeGraphElements = useCallback(() => {
    drawio?.changeGraphElements(
      getDrawioModelValues(entityModelIds, Models.Entity),
      getDrawioModelValues(relationModelIds, Models.Relation)
    );
  }, [drawio, entityModelIds, relationModelIds]);

  const onLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    const window = iframeRef.current?.contentWindow;
    window && initDrawio(window, (drawio: DrawioFunction) => setDrawio(drawio));
  };

  const resizeIFrameToFitContent = useCallback(() => {
    if (!iframeRef.current) {
      return;
    }

    const height = iframeRef.current.contentWindow?.document.body.children.item(1)?.scrollHeight;

    if (height !== 0) {
      setTimeout(() => {
        onDiagramLoad();
      }, 100);
    }

    iframeRef.current.height = String(height) + 'px';
  }, [onDiagramLoad]);

  const timeId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timeId.current = setTimeout(() => {
      if (iframeRef.current) {
        resizeIFrameToFitContent();
      }
    }, 500);

    return () => {
      clearTimeout(timeId.current);
    };
  }, [drawio, resizeIFrameToFitContent]);

  useEffect(() => {
    drawio?.setPreview(preview?.base64);
  }, [drawio, preview?.base64]);

  useEffect(() => {
    if (!isGraphElementsLoad && drawio) {
      changeGraphElements();
      setIsGraphElementsLoad(true);
    }
  }, [drawio, isGraphElementsLoad, changeGraphElements]);

  useEffect(() => {
    drawio?.setTheme(theme) && changeGraphElements();
  }, [drawio, theme, changeGraphElements]);

  function onChangeModel(changeModel: ChangeModel) {
    drawioRef.current?.changeModelGraphElement(changeModel);
  }

  function onChangeEngineModel(changeEngineModel: ChangeEngineModel) {
    drawioRef.current?.changeEngineModelGraphElement(changeEngineModel);
  }

  useEffect(() => {
    if (drawio) {
      drawio.window.document.addEventListener('click', dispatchMouseUpEvent);
      drawio.window.document.addEventListener('dblclick', editOpen);
      drawio.window.document.addEventListener('touchstart', editOpen);

      return () => {
        drawio.window.document.removeEventListener('click', dispatchMouseUpEvent);
        drawio.window.document.removeEventListener('dblclick', editOpen);
        drawio.window.document.removeEventListener('touchstart', editOpen);
      };
    }
  }, [drawio, editOpen]);

  return (
    <div ref={wrapperRef}>
      <iframe
        src={`${
          !process.env.REACT_APP_DRAW_IO_PATH ? DRAW_IO_DEFAULT_SOURCE_PATH : process.env.REACT_APP_DRAW_IO_PATH
        }?lightbox=1`}
        onLoad={onLoad}
        ref={iframeRef}
        style={{ borderStyle: 'none', width: '100%', minHeight: '100px' }}
      />
    </div>
  );
}
