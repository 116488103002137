import omit from 'lodash/omit';
import { array, object, ObjectSchema, string, mixed, number } from 'yup';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
import {
  JsonDocumentTableBlock,
  JsonDocumentTableBlockColumnTypes,
  JsonDocumentTableBlockShowLink,
  ModelType,
  TableFilter
} from '../../types/jsonDescription';
import { Models } from '../../engine/types';
import { ModelError } from '../../errors';
import { Block } from './block';
import { BlockType } from './types';

export type ShowLink = Omit<JsonDocumentTableBlockShowLink, 'end-self' | 'end-target' | 'show-link'> & {
  endSelf: string;
  endTarget?: string;
  showLink?: ShowLink;
};

export interface TableBlockValue
  extends Omit<JsonDocumentTableBlock, 'model-type' | 'row-type' | 'id' | 'type' | 'show-link'> {
  modelType: Models.Entity | Models.Relation;
  rowType: string;
  showLink?: ShowLink;
  filter?: TableFilter;
}

const columnsValidate = array().of(
  object({
    name: string(),
    displayName: string(),
    value: string(),
    size: number(),
    type: mixed().oneOf(Object.values(JsonDocumentTableBlockColumnTypes))
  })
);

// Не очень понятно как сделать рекурсию с синхронной проверкой, пока ограничимся одним уровнем вложенности
// В теории можно сделать 2 объекта, ссылающиеся друг на друга, но пока не будем
const childShowLinkSchema = object({
  relation: string().required(),
  endSelf: string().required(),
  endTarget: string().notRequired(),
  table: object({
    columns: columnsValidate
  }).required()
})
  .default(null)
  .notRequired();

const showLinkSchema = object({
  relation: string().required(),
  endSelf: string().required(),
  endTarget: string().notRequired(),
  showLink: childShowLinkSchema,
  table: object({
    columns: columnsValidate
  }).required()
})
  .default(null)
  .notRequired();

// yup пока плохо понимает типы, которые сгенерил не он
const tableSchema: ObjectSchema<Omit<TableBlockValue, 'modelType' | 'columns' | 'showLink'>> = object({
  modelType: mixed().oneOf([Models.Entity, Models.Relation]).required(),
  rowType: string().required(),
  columns: columnsValidate,
  filter: object({
    diagram: string()
  }),
  showLink: showLinkSchema
});

export class Table extends Block<TableBlockValue> {
  public override valueSchema = tableSchema;

  constructor(id: string) {
    super(BlockType.table, id);
  }

  public static modelTypeFromJson(modelType?: string): Models.Entity | Models.Relation {
    if (modelType === ModelType.entities || modelType === Models.Entity) {
      return Models.Entity;
    } else if (modelType === ModelType.relations || modelType === Models.Relation) {
      return Models.Relation;
    }
    throw new ModelError(`model-type unknown ${modelType}`);
  }

  public override validateValue<T extends TableBlockValue>(value?: T) {
    if (!value) {
      return;
    }

    if (this.engine) {
      // TODO row-type проверки значений при добавлении в документ и изменении добавить
    }

    Table.modelTypeFromJson(value.modelType);
    super.validateValue(value);
  }

  public static override getValueFromJSON(json: string | JsonDocumentTableBlock): TableBlockValue {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentTableBlock) : json;
    const modelType = Table.modelTypeFromJson(jsonObj['model-type']);
    const rowType = jsonObj['row-type'];
    let showLink: ShowLink | undefined;
    const jsonShowLink = jsonObj['show-link'];
    if (jsonShowLink) {
      showLink = {
        endSelf: jsonShowLink['end-self'],
        endTarget: jsonShowLink['end-target'],
        table: jsonShowLink.table,
        relation: jsonShowLink.relation
      };

      const jsonChildShowLink = jsonShowLink['show-link'];
      if (jsonChildShowLink) {
        showLink.showLink = {
          endSelf: jsonChildShowLink['end-self'],
          endTarget: jsonChildShowLink['end-target'],
          table: jsonChildShowLink.table,
          relation: jsonChildShowLink.relation
        };
      }
    }
    const filter = jsonObj['filter'];

    return {
      ...omit(jsonObj, ['id', 'type', 'show-link', 'model-type', 'row-type']),
      modelType,
      rowType,
      showLink,
      filter
    };
  }

  public static override fromJSON(json: string | JsonDocumentTableBlock): Table {
    const jsonObj = typeof json === 'string' ? (JSON.parse(json) as JsonDocumentTableBlock) : json;
    const table = new Table(jsonObj.id);
    table.setValue(Table.getValueFromJSON(jsonObj));
    return table;
  }

  public getMarkdown(): string {
    return `\n::: ${DOCUMENT_BLOCK_TAG} [id=${this.getId()},type=${
      this.type
    }]\n;;; ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] *here be dragons* \n;;; \n:::`;
    // Убрал, т.к. ломает много
    // return `\n::: ${EXTERNAL_NODE_TAG} [id=${this.getId()},type=${this.type}] \n:::${MARKDOWN_NEW_LINE_HELPER}`;
  }
}
