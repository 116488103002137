import { SimpleValueType } from './simpleValueType';

export type JsonEnds = Record<string, JsonSource>;
export type JsonFields = Record<string, JsonField>;

export interface JsonEntity {
  __type: string;
  __id: string;

  [name: string]: SimpleValueType;
}

export interface JsonRelation {
  type: string;
  ends: Record<string, number>;
  fields: Record<string, SimpleValueType>;
}

export interface JsonMetaEntity {
  'display-name': string;
  fields: JsonFields;
  style: string;
}

export interface JsonMetaRelation extends JsonMetaEntity {
  ends: JsonEnds;
}

export interface JsonMetaEntities {
  [name: string]: JsonMetaEntity;
}

export interface JsonMetaRelations {
  [name: string]: JsonMetaRelation;
}

export interface JsonField {
  type: string;
  'primary-key'?: boolean;
  required?: boolean;
  unique?: boolean;
  'display-name': string;
  description?: string;

  [name: string]: string | boolean | number | undefined;
}

export interface JsonSource {
  type: string;
  'display-name': string;
  target?: boolean;
  required?: boolean;
  description?: string;

  [name: string]: string | boolean | number | undefined;
}

export enum JsonDocumentBlockType {
  markdown = 'markdown',
  table = 'table',
  diagram = 'diagram',
  customNode = 'customNode'
}

export interface JsonDocumentCustomNodeBlock {
  id: string;
  type: JsonDocumentBlockType.customNode;
  'entry-point'?: string;
  src?: string | null;
  value?: string | null;
}

export interface JsonDocumentMarkdownBlock {
  id: string;
  type: JsonDocumentBlockType.markdown;
  value: string | null;
}

export enum JsonDocumentTableBlockColumnTypes {
  autonumber = 'autonumber'
}

export interface JsonDocumentTableBlockColumn {
  type?: JsonDocumentTableBlockColumnTypes;
  'display-name'?: string;
  value?: string;
  name?: string;
  size?: number;
}

export interface JsonDocumentTableBlockShowLinkTable {
  columns: JsonDocumentTableBlockColumn[];
}

export interface JsonDocumentTableBlockShowLink {
  relation: string;
  'end-self': string;
  'end-target'?: string;
  table: JsonDocumentTableBlockShowLinkTable;
  'show-link'?: JsonDocumentTableBlockShowLink;
}

export interface JsonDocumentTableBlock {
  type: JsonDocumentBlockType.table;
  id: string;
  'model-type': ModelType;
  'row-type': string;
  columns: JsonDocumentTableBlockColumn[];
  'show-link'?: JsonDocumentTableBlockShowLink;
  filter?: TableFilter;
}

export interface TableFilter {
  diagram?: string;
}

export interface JsonDocumentDiagramBlock {
  type: JsonDocumentBlockType.diagram;
  id: string;
  base64: string;
  'allowed-types': string[];
  contains: Record<string, string[]>[];
}

export enum ModelType {
  entities = 'entities',
  relations = 'relations'
}

export type JsonDocumentBlocks =
  | JsonDocumentMarkdownBlock
  | JsonDocumentTableBlock
  | JsonDocumentDiagramBlock
  | JsonDocumentCustomNodeBlock;

export enum JsonRootBlock {
  version = 'version',
  metamodel = 'metamodel',
  model = 'model',
  document = 'document'
}

export interface JsonDescription {
  [JsonRootBlock.version]?: string;
  [JsonRootBlock.metamodel]: {
    [ModelType.entities]: JsonMetaEntities;
    [ModelType.relations]: JsonMetaRelations;
  };
  [JsonRootBlock.model]: {
    [ModelType.entities]: JsonEntity[];
    [ModelType.relations]: JsonRelation[];
  };
  [JsonRootBlock.document]: JsonDocumentBlocks[];
}
