import React, { forwardRef } from 'react';
import {
  TextField as SmwbTextField,
  TextFieldProps as SmwbTextFieldProps,
  TextFieldAdornment,
  TextFieldWrapper,
  TextFieldAdornmentProps,
  TextFieldWrapperProps
} from '@smwb/summer-ui';

export type TextFieldProps = Omit<SmwbTextFieldProps, 'variant'>;

export const TextField = forwardRef<HTMLButtonElement, TextFieldProps>(function TextField(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Типы правильные, проблема в @smwb/summer-ui пакете
  return <SmwbTextField ref={ref} {...props} variant="filled" />;
});

export { TextFieldWrapper, TextFieldAdornment };

export type { TextFieldWrapperProps, TextFieldAdornmentProps };
