import * as allWidgets from '@components/markdownEditor/dataDisplayWidgets';
import { Widget } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/types';
import { BaseWidget } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';

export const widgetMapping = Object.values(allWidgets).reduce((map, widget) => {
  if (!(widget?.prototype instanceof BaseWidget)) {
    return map;
  }

  const name = widget.prototype.getType();
  const widgetNames = Object.keys(map);

  if (widgetNames.includes(name)) {
    console.error(`Имя виджета \`${name}\` дублируется. Оно должно быть уникальным у каждого виджета.`);
  } else {
    map[name] = widget as Widget;
  }

  return map;
}, {} as Record<string, Widget>);

export const widgets = Object.values(widgetMapping);

export function getWidget(type: string): Widget | undefined {
  return widgetMapping[type];
}
