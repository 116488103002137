import { Markdown } from '@baseModel/document/blocks/markdown';
import { Engine } from '@baseModel/engine/engine';
import { Button } from '@smwb/summer-ui';
import React, { useState } from 'react';
import s from './createTableForm.module.less';
import { ProseMirrorView } from '../proseMirrorView';
import { getBlockIdInCursorPosition } from '../utils/getEndBlockCursorPosition';
import uniqueId from 'lodash/uniqueId';
import { TextField } from '@ui/components';

const engine = Engine.getInstance();

type Props = {
  proseMirrorView: ProseMirrorView;
};

const INITIAL_VALUE = '2';

function CreateTableForm({ proseMirrorView }: Props) {
  const [colsCount, setColsCount] = useState(INITIAL_VALUE);
  const [rowsCount, setRowsCount] = useState(INITIAL_VALUE);

  const handleChangeRowsCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsCount(e.target.value);
  };

  const handleChangeColsCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColsCount(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!colsCount || !rowsCount) return;

    const rows = parseInt(rowsCount);
    const cols = parseInt(colsCount);

    const document = engine.getDocument();
    const blockInfo = getBlockIdInCursorPosition(proseMirrorView);
    const block = document.getBlockById(blockInfo.id, blockInfo.type);
    if (!block) {
      console.error(`Block ${blockInfo.type} ${blockInfo.id} не найден`);
      return;
    }
    const tableMd = generateMdTable(rows, cols);
    const mark = new Markdown(uniqueId('md-' + Date.now().toString()));
    mark.setValue(tableMd);

    document.addBlock(mark, block.getSortIndex());
  };

  return (
    <form onSubmit={handleSubmit} className={s.form}>
      <TextField value={colsCount} type="number" label={'columns'} onChange={handleChangeColsCount} />
      <TextField value={rowsCount} type="number" label={'rows'} onChange={handleChangeRowsCount} />
      <Button type="submit" variant="contained">
        Ок
      </Button>
    </form>
  );
}

export default CreateTableForm;

const generateMdTable = (rows: number, cols: number) => {
  let table = '|';
  for (let i = 0; i < cols; i++) {
    table += ' Header |';
  }
  table += '\n|';
  for (let i = 0; i < cols; i++) {
    table += ' --- |';
  }
  table += '\n';
  for (let i = 0; i < rows; i++) {
    table += '|';
    for (let j = 0; j < cols; j++) {
      table += ' Cell |';
    }
    table += '\n';
  }
  return table;
};
