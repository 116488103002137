import { BlockType } from '@baseModel/document/blocks/types';
import { Engine } from '@baseModel/engine/engine';
import { Models } from '@baseModel/engine/types';
import { JsonDocumentTableBlockColumnTypes } from '@baseModel/types/jsonDescription';
import { SelectFieldProps } from '@smwb/summer-ui';
import uniqueId from 'lodash/uniqueId';
import { TableColumn } from './tableConfigurationForm';

const engine = Engine.getInstance();

export const modelTypes = [
  { key: '1', text: Models.Entity, value: Models.Entity },
  { key: '2', text: Models.Relation, value: Models.Relation }
];

export const columnTypeItems = [
  {
    text: JsonDocumentTableBlockColumnTypes.autonumber,
    value: JsonDocumentTableBlockColumnTypes.autonumber,
    key: '1'
  },
  {
    text: 'Нет',
    value: '',
    key: '2'
  }
];

export const getValueItems = (rowType?: string, modelType?: Models): SelectFieldProps['items'] => {
  if (!rowType) return [];
  if (modelType === Models.Entity) {
    const fields = engine.getMetaEntityByName(rowType).getFieldNames();
    return fields.map((f) => ({ text: f, value: f, key: uniqueId('md-' + Date.now().toString()) }));
  } else if (modelType === Models.Relation) {
    const metaRelation = engine.getMetaRelationByName(rowType);
    const fields = metaRelation.getFieldNames();
    const relations = metaRelation.getRelationNames();
    const fieldItems = fields.map((f) => ({ text: f, value: f, key: uniqueId('md-' + Date.now().toString()) }));
    const relationsItems = relations.map((f) => ({
      text: f,
      value: f,
      key: uniqueId('md-' + Date.now().toString()),
      helperText: 'Relation'
    }));
    return [...fieldItems, ...relationsItems];
  }
  return [];
};

export const getEntityFields = (name: string, relationName: string): SelectFieldProps['items'] => {
  const relation = engine.getMetaRelationByName(relationName).getRelationValue(name);
  if (!relation) return [];
  const fields = engine.getMetaEntityByName(relation.type).getFieldNames();
  if (!fields) return [];
  return fields.map((f, idx) => ({ key: idx, text: f, value: f }));
};

export const getRowTypeItems = (modelType?: Models): SelectFieldProps['items'] => {
  if (!modelType) return [];
  if (modelType === Models.Entity) {
    const entityNames = engine.getEntityMetaModelsNames();
    return entityNames.map((e, idx) => ({ key: idx, text: e, value: e }));
  } else {
    const relationNames = engine.getRelationMetaModelsNames();
    return relationNames.map((e, idx) => ({ key: idx, text: e, value: e }));
  }
};

/**
 * Получаем список доступных связей для селекта
 * @param relation выбранное имя мета связи
 * @param targetName возвращает только связи с таким типом, если указано
 */
export const getRelationEndsItems = (relation?: string, targetName?: string): SelectFieldProps['items'] => {
  if (!relation) {
    return [];
  }
  const metaRelation = engine.getMetaRelationByName(relation);
  const relationNames = metaRelation.getRelationNames();
  const permittedRelations = !targetName
    ? relationNames
    : relationNames.filter((relationName) => metaRelation.getRelationValue(relationName)?.type === targetName);
  return permittedRelations.map((end) => {
    return {
      key: end,
      value: end,
      text: end
    };
  });
};

/**
 * Возвращает список доступных имен meta relation
 * @param entityName только meta relation, которые имеют хотя бы одну связь данного типа
 */
export const getRelationModelsItems = (entityName?: string): SelectFieldProps['items'] => {
  if (!entityName) {
    return [];
  }
  const mModelsNames = engine.getRelationMetaModelsNames();
  return mModelsNames
    .filter((relation) => {
      const metaRelation = engine.getMetaRelationByName(relation);
      return metaRelation
        .getRelationNames()
        .find((relationName) => metaRelation.getRelationValue(relationName)?.type === entityName);
    })
    .map((m, idx) => ({ key: idx, value: m, text: m }));
};

export const formatToTableColumns = (columns: TableColumn[]) => {
  return columns.map((c) => {
    const value = c.relation ? `this.${c.relation}.${c.value}` : c.value ? `this.${c.value}` : undefined;
    return { 'display-name': c['display-name'], value: value, type: c.type || undefined, name: c.name, size: c.size };
  });
};

export const getDiagramIdsItems = (): SelectFieldProps['items'] => {
  const diagrams = engine.getDocument().getBlocks(BlockType.diagram);
  return [{ text: '', value: '', key: '' }].concat(
    diagrams.map((d) => ({
      text: d.getId(),
      value: d.getId(),
      key: d.getId()
    }))
  );
};
