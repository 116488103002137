import { DrawioContext } from '../types/window';
import { MxEventsData, MxEventsChange } from '../types/mxEvent';
import { DrawEvents, Subscriber } from '../types/events';
import { MxGraphModel } from '../types/mxGraphModel';
// import { getEventDifferenceStyles } from './eventDifference/eventDifferenceStyles';
import { getEventDifferenceValue } from './eventDifference/eventDifferenceValue';
import { EventDifferenceAs, EventDifferenceResults } from './eventDifference/eventDifferenceResults';
import { loadGetLabelByCellPlugin } from './graphElements';
import { getEventDifferenceChild } from './eventDifference/eventDifferenceChild';
import { getEventDifferenceTerminal } from './eventDifference/eventDifferenceTerminal';
import orderBy from 'lodash/orderBy';
import { loadErrorHandlerPlugin } from './errors';

export function loadEventsPlugin(this: DrawioContext) {
  return () => {
    const mxCodec = this.mxCodec;
    const mxEvent = this.mxEvent;
    const mxUtils = this.mxUtils;
    const mxRootChange = this.mxRootChange;
    const mxChildChange = this.mxChildChange;
    // const mxStyleChange = this.mxStyleChange;
    const mxValueChange = this.mxValueChange;
    const mxTerminalChange = this.mxTerminalChange;

    const graph = this.EditorUi.editor.graph;
    const model = graph.model;
    const getLabel = loadGetLabelByCellPlugin.apply(this);
    const errorHandler = loadErrorHandlerPlugin.apply(this);

    const codec = new mxCodec();
    codec.lookup = (id: string) => model.getCell(id);

    model.addListener(mxEvent.CHANGE, function (sender: MxGraphModel, evt: MxEventsData) {
      const changes = evt.getProperty('changes');
      const changeEvents = changes.filter((change: MxEventsChange): boolean => {
        const isMxRootChange = change instanceof mxRootChange;
        const isMxChildChange = change instanceof mxChildChange;

        return (!!change?.cell?.id || isMxChildChange) && !isMxRootChange;
      });

      if (changeEvents.length === 0) return false;

      const nodeElements = codec.encode(changeEvents);
      const nodeElementsXml = mxUtils.getXml(nodeElements);
      startEventListener(DrawEvents.changeElementsXml, nodeElementsXml);
      startEventListener(DrawEvents.changeElementsBase64, graph.compress(nodeElementsXml));

      const nodeModel = codec.encode(model);
      const nodeModelXml = mxUtils.getXml(nodeModel);
      startEventListener(DrawEvents.changeModelXml, nodeModelXml);
      startEventListener(DrawEvents.changeModelBase64, graph.compress(nodeModelXml));

      const arrayDifference = [];
      for (let i = 0; i < changeEvents.length; i++) {
        const change = changeEvents[i];

        // убрано по просьбе 30.08.2023
        // if (change instanceof mxStyleChange) arrayDifference.push(getEventDifferenceStyles(change));
        // else
        if (change instanceof mxValueChange) arrayDifference.push(getEventDifferenceValue(change, getLabel));
        else if (change instanceof mxChildChange) arrayDifference.push(getEventDifferenceChild(change));
        else if (change instanceof mxTerminalChange) arrayDifference.push(getEventDifferenceTerminal(change));
        else {
          // console.log(change);
        }
      }
      startEventListener(
        DrawEvents.changeModelDifferenceJson,
        orderBy(arrayDifference, [(event) => event.as === EventDifferenceAs.id], ['desc'])
      );
    });

    let eventSubscribers: Array<Subscriber> = [];

    const startEventListener = (event: DrawEvents, param: string | EventDifferenceResults[]): void => {
      try {
        for (const subscriber of eventSubscribers) if (subscriber.event === event) subscriber.handler(param);
      } catch (e) {
        errorHandler(e);
      }
    };

    return {
      addListener: (eventSubscriber: Subscriber) => {
        eventSubscribers.push(eventSubscriber);

        return () => {
          eventSubscribers = eventSubscribers.filter(
            (subscriber: Subscriber): boolean => subscriber !== eventSubscriber
          );
        };
      },
      removeAllListener: () => {
        eventSubscribers = [];
      }
    };
  };
}
