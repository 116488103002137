import { Engine } from '@baseModel/engine/engine';
import { ProseMirrorView } from '@components/markdownEditor/proseMirrorView';
import { useEffect, useState } from 'react';
import { EventType } from '@baseModel/document/document';
import { BlockType } from '@baseModel/document/blocks/types';

const engine = Engine.getInstance();

export interface Heading {
  id: string;
  text: string;
  level: number;
}

export type Headings = Heading[];

export function getHeadings(proseMirror: ProseMirrorView) {
  const proseMirrorState = proseMirror.getState();
  const headings: Headings = [];

  proseMirrorState.doc.forEach((nodeBlock) => {
    nodeBlock.forEach((node) => {
      if (node.isTextblock && node.type.name === 'heading') {
        const level = node.attrs.level as number;

        if (level === 1 || level === 2) {
          const id = nodeBlock.attrs.id as string;
          headings.push({ id: id, text: node.textContent, level });
        }
      }
    });
  });

  return headings;
}

export function useHeadings(proseMirror: ProseMirrorView | null) {
  const [headings, setHeadings] = useState<Headings>([]);

  useEffect(() => {
    if (!proseMirror) {
      return;
    }

    setHeadings(getHeadings(proseMirror));

    const document = engine.getDocument();
    const unsubscribe = document.subscribe(
      EventType.all,
      (value) => {
        if (value.type !== BlockType.markdown) {
          return;
        }

        setHeadings(getHeadings(proseMirror));
      },
      'useHeadings'
    );

    return () => {
      unsubscribe();
    };
  }, [proseMirror]);

  return headings;
}
