import { TextModel } from '../types';

export function findStartLine(model: TextModel, startLine: number, endLine: number) {
  let start = endLine;

  while (start > startLine) {
    if (model.getLineFirstNonWhitespaceColumn(start) > 0) {
      break;
    }

    start--;
  }

  return start;
}
