import { DataDialogClass } from '../types/dialog';
import { App } from '../types/draw';
import { MxCell } from '../types/mxCell';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { DataDialog } from './components/dataDialog/dataDialog';
import { DrawioContext } from '../types/window';

export function loadDataDialogPlugin(context: DrawioContext): DataDialogClass {
  return class {
    container = document.createElement('div');
    init = () => false;
    constructor(EditorUi: App, cell: MxCell) {
      createRoot(this.container).render(<DataDialog EditorUi={EditorUi} cell={cell} context={context} />);
    }
  };
}
