import { Button, ButtonProps, Icon } from '@smwb/summer-ui';
import React from 'react';
import { markActive } from '@components/markdownEditor/topMenu/utils';
import { ProseMirrorView } from '@components/markdownEditor/proseMirrorView';
import { MENU_ICON_SIZE } from '@components/markdownEditor/topMenu/const';
import { MarkType } from 'prosemirror-model';
import { toggleMark } from 'prosemirror-commands';
import cs from 'classnames';
import style from './topMenu.module.less';
interface MarkButtonProps extends ButtonProps {
  proseMirrorView: ProseMirrorView;
  markType: MarkType;
  icon?: string;
}

export function MarkButton({ markType, proseMirrorView, icon, ...buttonProps }: MarkButtonProps) {
  return (
    <Button
      className={cs(style.button, { [style.active]: markActive(proseMirrorView.getState(), markType) })}
      variant={'outlined'}
      icon={icon ? <Icon name={icon} size={MENU_ICON_SIZE} /> : undefined}
      onMouseDown={() => {
        toggleMark(markType)(proseMirrorView.getState(), proseMirrorView.getDispatch(), proseMirrorView.getEditor());
      }}
      {...buttonProps}
    />
  );
}
