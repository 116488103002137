import { MenuItem } from '@components/markdownEditor/topMenu/menuItem';
import React from 'react';
import { ProseMirrorView } from '@components/markdownEditor/proseMirrorView';
import { blockActive } from '@components/markdownEditor/topMenu/utils';
import { Schema } from 'prosemirror-model';
import { setBlockType } from 'prosemirror-commands';

export interface HeaderBlockTypeMenuItemsProps {
  proseMirrorView: ProseMirrorView;
  schema: Schema;
  onClose: () => void;
}

interface MenuItemSimplifyProps extends HeaderBlockTypeMenuItemsProps {
  level: number;
}

function MenuItemSimplify({ level, schema, proseMirrorView, onClose }: MenuItemSimplifyProps) {
  return (
    <MenuItem
      icon={`format_h${level}`}
      selected={blockActive(proseMirrorView.getState(), schema.nodes.heading, { level })}
      onClick={() => {
        setBlockType(schema.nodes.heading, { level })(
          proseMirrorView.getState(),
          proseMirrorView.getDispatch(),
          proseMirrorView.getEditor()
        );
        onClose();
      }}
    />
  );
}

export function HeaderBlockTypeMenuItems({ proseMirrorView, schema, onClose }: HeaderBlockTypeMenuItemsProps) {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((el) => (
        <MenuItemSimplify key={el} level={el} schema={schema} proseMirrorView={proseMirrorView} onClose={onClose} />
      ))}
    </>
  );
}
