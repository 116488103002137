import { Engine } from '@baseModel/engine/engine';
import { Models } from '@baseModel/engine/types';
import { SelectFieldProps } from '@smwb/summer-ui';

const engine = Engine.getInstance();

export const getModelsItemsByMetaName = (modelType: string, metaModelName: string): SelectFieldProps['items'] => {
  const items: SelectFieldProps['items'] = [];

  if (modelType === Models.Entity) {
    const ids = engine.getEntitiesIdsByMetaName(metaModelName);
    ids.forEach((id) => {
      const entity = engine.getEntityById(id);
      if (entity) {
        items.push({ text: `${id}`, value: id, helperText: `${entity.getFieldValue('__name') || ''}` });
      }
    });
  } else if (modelType === Models.Relation) {
    const ids = engine.getRelationsIdsByMetaName(metaModelName);
    ids.forEach((id) => {
      const relation = engine.getRelationById(id);
      if (relation) {
        items.push({ text: `${id}`, value: id, helperText: `${relation.getFieldValue('__name') || ''}` });
      }
    });
  }

  return items;
};

export const getModelFieldsItems = (modelType: string, id: string): SelectFieldProps['items'] => {
  if (!id) return [];
  const fieldsItems: SelectFieldProps['items'] = [];
  if (modelType === Models.Entity) {
    const fields = engine.getEntityById(id)?.getFieldNames() || [];
    fields.forEach((f, idx) => {
      if (f !== '__type') {
        fieldsItems.push({ text: f, value: f, key: idx });
      }
    });
  } else if (modelType === Models.Relation) {
    const fields = engine.getRelationById(id)?.getFieldNames() || [];
    fields.forEach((f, idx) => {
      if (f !== '__type') {
        fieldsItems.push({ text: f, value: f, key: idx });
      }
    });
  }

  return fieldsItems;
};

interface ModelLinkValue {
  modelType: string;
  metaModelName: string;
  modelId: string;
  field: string;
}

export function getModelLinkValue({ modelType, metaModelName, modelId, field }: ModelLinkValue) {
  return `${modelType}:${metaModelName}:${modelId}:${field}`;
}

export function parseModelLinkValue(value?: string) {
  if (!value) {
    return {};
  }

  const [modelType, metaModelName, modelId, field] = value.split(':');

  return { modelType, metaModelName, modelId, field };
}
