import { Models } from '@baseModel/engine/types';
import { JsonDocumentTableBlockColumnTypes } from '@baseModel/types/jsonDescription';
import Stepper from '@components/basic/stepper/stepper';
import { useMemo, useState } from 'react';
import { EditColumnField } from './editColumnField';
import { TableColumn } from '../tableConfigurationForm';
import { columnTypeItems, getEntityFields, getValueItems } from '../utils';
import { Engine } from '@baseModel/engine/engine';

interface Props {
  currentColumn: TableColumn;
  modelType: Models.Entity | Models.Relation | undefined;
  onSubmit: (tableColumn: TableColumn) => void;
  onClose: () => void;
  rowType: string;
}

const engine = Engine.getInstance();

export const EditColumnStepper = ({ currentColumn, modelType, onSubmit, onClose, rowType }: Props) => {
  const [editColumnValue, setEditColumnValue] = useState<TableColumn>(currentColumn);

  const availableValues = useMemo(() => getValueItems(rowType, modelType as Models), [modelType, rowType]);
  const isAutoNumColumn = editColumnValue?.type === JsonDocumentTableBlockColumnTypes.autonumber;

  const handleChangeColumnField = (fieldName: keyof TableColumn, value?: string) => {
    if (
      fieldName === 'relation' &&
      (modelType !== Models.Relation || !engine.getMetaRelationByName(rowType).getRelationValue(value || ''))
    ) {
      setEditColumnValue((prev) => ({ ...prev, value, relation: '' }));
      return;
    }
    setEditColumnValue((prev) => ({ ...prev, [fieldName]: value }));
  };

  const steps = [
    {
      label: 'Выберите type',
      content: (
        <>
          <EditColumnField
            items={columnTypeItems}
            name="type"
            onChange={handleChangeColumnField}
            value={editColumnValue.type || ''}
            label="Type"
          />
        </>
      )
    },

    {
      label: 'Введите display-name и name',
      cantNextStep: isAutoNumColumn,
      content: (
        <>
          <EditColumnField
            name="display-name"
            onChange={handleChangeColumnField}
            value={editColumnValue['display-name'] || ''}
            isTextField
            label="Display-name"
          />
          <EditColumnField
            name="name"
            label="Name"
            onChange={handleChangeColumnField}
            value={editColumnValue.name || ''}
            isTextField
          />
        </>
      )
    },

    {
      label: 'Установите value',
      content: (
        <>
          <EditColumnField
            items={availableValues}
            name={'relation'}
            label="Value or relation"
            onChange={handleChangeColumnField}
            value={editColumnValue.relation ? editColumnValue.relation : editColumnValue.value || ''}
          />
          {editColumnValue.relation && (
            <EditColumnField
              items={getEntityFields(editColumnValue.relation, rowType)}
              name="value"
              onChange={handleChangeColumnField}
              value={editColumnValue.value || ''}
              label="Value"
            />
          )}
        </>
      )
    }
  ];

  return <Stepper steps={steps} onSubmit={() => onSubmit(editColumnValue)} onClose={onClose} />;
};
