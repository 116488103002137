import { Plugin } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export const MENU_ATTRIBUTE_ID = 'MENU_ATTRIBUTE';

class MenuView {
  public readonly dom = document.createElement('div');

  constructor(private readonly editorView: EditorView, private readonly onUpdate: () => void) {
    this.dom.setAttribute(MENU_ATTRIBUTE_ID, 'true');
    this.dom.id = 'markdown-menu';
    this.update();
  }

  update() {
    this.onUpdate();
  }

  destroy() {
    console.log('destroy');
  }
}

export function topMenuView(onUpdate: () => void) {
  return new Plugin({
    view(editorView) {
      if (!editorView.dom.parentNode) {
        throw new Error('editorView.dom.parentNode is null');
      }
      const menuView = new MenuView(editorView, onUpdate);
      editorView.dom.parentNode.insertBefore(menuView.dom, editorView.dom);
      return menuView;
    }
  });
}
