import React, { useEffect, useState } from 'react';
import { useCustomNode } from '@baseModel/document/hooks/useCustomNode';

export interface CustomNodeProps {
  id: string;
}

export interface CustomNodeObject {
  update: (id: string, data: unknown) => void;
  load: (id: string, nodeRoot: HTMLDivElement, data?: unknown) => void;
  destroy: (id: string, nodeRoot: HTMLDivElement) => void;
}

function getCustomNodeObject(name: string): CustomNodeObject | undefined {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const func = (window as never)[name] as CustomNodeObject;
  if (!func) {
    console.error(`Внешний модуль с именем ${name} не найден`);
    return undefined;
  }
  if (!func.load) {
    console.error('Внешний модуль не содержит метода load');
    return undefined;
  }
  if (!func.destroy) {
    console.error('Внешний модуль не содержит метода destroy');
    return undefined;
  }
  return func;
}

function useCustomNodeCall(
  customNodeConfig: ReturnType<typeof useCustomNode>[0],
  configId: string,
  ref: HTMLDivElement | null
) {
  useEffect(() => {
    if (!configId || !ref) {
      return;
    }
    let script: HTMLScriptElement;
    const entryPoint = customNodeConfig?.entryPoint;

    if (entryPoint && customNodeConfig.src) {
      script = document.createElement('script');
      document.head.appendChild(script);
      script.onload = function () {
        const customHandler = getCustomNodeObject(entryPoint);
        customHandler?.load(configId, ref, customNodeConfig.value);
      };
      script.src = customNodeConfig.src;
    }
    return () => {
      if (entryPoint && ref) {
        const customHandler = getCustomNodeObject(entryPoint);
        customHandler?.destroy(configId, ref);
        try {
          script && document.head.removeChild(script);
        } catch (e) {
          console.error('Ошибка поиска скрипта custom', e);
        }
      }
    };
  }, [configId, customNodeConfig, ref]);

  useEffect(() => {
    if (customNodeConfig?.entryPoint) {
      const customHandler = getCustomNodeObject(customNodeConfig.entryPoint);
      customHandler?.update(configId, customNodeConfig.value);
    }
  }, [configId, customNodeConfig]);
}

export function CustomNode({ id }: CustomNodeProps) {
  const [customNodeConfig] = useCustomNode(id);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  useCustomNodeCall(customNodeConfig, id, ref);
  return <div ref={setRef} />;
}
