import { Models } from '@baseModel/engine/types';
import { JsonDocumentTableBlockColumn } from '@baseModel/types/jsonDescription';
import { SelectFieldProps } from '@smwb/summer-ui';
import { useMemo, useState } from 'react';
import pick from 'lodash/pick';
import { TableColumn } from '../tableConfigurationForm';
import { getRowTypeItems } from '../utils';
import { useTable } from '@baseModel/document/hooks/useTable';
import { ShowLink } from '@baseModel/document/blocks/table';

const tableColumns = (columns: JsonDocumentTableBlockColumn[] = []) => {
  return columns.map((c, idx) => {
    const valArr = c.value?.split('.');
    const relation = valArr && valArr?.length > 2 ? valArr[1] : undefined;
    const value = valArr && valArr.pop();
    return { ...c, id: idx, relation, value };
  });
};

export type HierarchyRelation = Pick<ShowLink, 'relation' | 'endSelf' | 'endTarget'>;

function useHierarchy(
  showLink?: ShowLink
): [
  HierarchyRelation | undefined,
  (hierarchyRelation?: HierarchyRelation) => void,
  TableColumn[],
  (tableColumn: TableColumn[]) => void
] {
  const [hierarchy, setHierarchy] = useState(
    showLink ? pick(showLink, ['relation', 'endSelf', 'endTarget']) : (undefined as HierarchyRelation | undefined)
  );
  const [hierarchyColumns, setHierarchyColumns] = useState<TableColumn[]>(tableColumns(showLink?.table.columns));
  return [hierarchy, setHierarchy, hierarchyColumns, setHierarchyColumns];
}

export const useEditTableData = (editableTableId?: string) => {
  const [tableData] = useTable(editableTableId);
  const [columns, setColumns] = useState<TableColumn[]>(tableColumns(tableData?.columns));
  const [modelType, setModelType] = useState<Models.Entity | Models.Relation | undefined>(tableData?.modelType);
  const [rowType, setRowType] = useState(tableData?.rowType);
  const [hierarchy, setHierarchy, hierarchyColumns, setHierarchyColumns] = useHierarchy(tableData?.showLink);
  // TODO Зарефакторить дублирование
  const prevColumns = tableColumns(tableData?.columns);
  const prevHierarchyColumns = tableColumns(tableData?.showLink?.table?.columns);
  const prevChildHierarchyColumns = tableColumns(tableData?.showLink?.showLink?.table?.columns);
  // СМ комментарий к yup в table block
  const [childHierarchy, setChildHierarchy, childHierarchyColumns, setChildHierarchyColumns] = useHierarchy(
    tableData?.showLink?.showLink
  );
  const [filter, setFilter] = useState(tableData?.filter?.diagram);

  const availableRowTypes = useMemo(() => getRowTypeItems(modelType as Models), [modelType]);

  const handleChangeModelType = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setModelType(data.value as Models.Entity | Models.Relation);
    setRowType('');
    setColumns([]);
    setHierarchyColumns([]);
    setChildHierarchy();
    setChildHierarchyColumns([]);
  };

  const handleChangeRowType = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setRowType((data.value as string) || '');
    setColumns([]);
    setHierarchyColumns([]);
    setChildHierarchy();
    setChildHierarchyColumns([]);
  };

  const handleChangeFilter: SelectFieldProps['onChange'] = (e, data) => {
    setFilter((data.value as string) || '');
  };

  const handleChangeHierarchy = (newHierarchy?: HierarchyRelation) => {
    setHierarchy(newHierarchy);
    setHierarchyColumns([]);
    setChildHierarchy();
    setChildHierarchyColumns([]);
  };

  return {
    availableRowTypes,
    handleChangeModelType,
    modelType,
    handleChangeRowType,
    rowType,
    handleChangeFilter,
    filter,
    columns,
    prevColumns,
    prevHierarchyColumns,
    prevChildHierarchyColumns,
    setColumns,
    handleChangeHierarchy,
    hierarchy,
    hierarchyColumns,
    setHierarchyColumns,
    childHierarchy,
    setChildHierarchy,
    childHierarchyColumns,
    setChildHierarchyColumns
  };
};
