import { JsonDocumentBlocks } from '@baseModel/types/jsonDescription';

export enum EmitType {
  Add = 'add',
  Update = 'update',
  Remove = 'remove',
  Insert = 'insert'
}

export enum ModelPaths {
  MetaEntities = 'metamodel.entities',
  MetaRelations = 'metamodel.relations',
  Entities = 'model.entities',
  Relations = 'model.relations',
  Document = 'document',
  Version = 'version'
}

export type JSONUnit = string | number | null | boolean;

export type JSONValue = JSONUnit | JSONArray;

export type JSONArray = JSONValue[];

export interface EventEditorDocumentObserver {
  root: string;
  changes: {
    after?: JsonDocumentBlocks;
  };
  meta: {
    id: string;
    changedSortIndex?: number;
    deleted?: boolean;
  };
}

export interface EventEditorMetaModelObserver<T> {
  changes: {
    after?: T;
  };
  meta: {
    name: string;
    deleted?: boolean;
  };
}

export interface EventEditorModelObserver<T> {
  changes: {
    after?: T;
  };
  meta: {
    id: string;
    deleted?: boolean;
  };
}

export interface EventEditorStringObserver {
  changes: {
    before?: string;
    after?: string;
  };
}

export type Listener<T> = (event: T) => void;
