import { IRange } from 'monaco-editor';
import { isCollection, isSeq, parseDocument } from 'yaml';
import { TextModel } from '../types';
import { Paths } from '@commonTypes/index';
import { findRange } from './findRange';
import { startSpaceRegExp } from './helpers';
import { findNextLine } from './findNextLine';

/**
 * Для коллекций типа объекта и массива если удаляется последний элемент, то устанавливается пустой литерал
 * соответствующего типа, определяемый по типу элемента.
 * @param model Текстовая модель документа monaco-editor.
 * @param paths Пусть поиска значения.
 */

export function deleteText(model: TextModel, paths: Paths) {
  const { foundRange, foundRangeValue, rangePaths } = findRange(model, paths);

  if (foundRange === undefined || foundRangeValue === undefined) {
    console.error('deleteText: Could not be found on path:', paths);
    return false;
  }

  const prevRange = rangePaths.at(-2);

  if (prevRange === undefined) {
    const nextLine = findNextLine(model, foundRange.endLineNumber);

    const range = {
      startLineNumber: foundRange.startLineNumber,
      startColumn: 0,
      endLineNumber: nextLine,
      endColumn: foundRange.endLineNumber === nextLine ? foundRange.endColumn : 0
    };

    model.pushEditOperations(null, [{ range, text: null }], () => null);
  } else {
    const EOL = model.getEOL();

    let rangeValue = model.getValueInRange(prevRange.valueRange);
    rangeValue = rangeValue.replace(new RegExp(`^${EOL}`), '');
    rangeValue = rangeValue.replace(startSpaceRegExp(prevRange.range.startColumn + 1), '');

    const parseValue = parseDocument(rangeValue);

    if (isCollection(parseValue.contents)) {
      let range: IRange;
      let text: string | null = null;

      if (parseValue.contents.items.length > 1) {
        const nextLine = findNextLine(model, foundRange.endLineNumber);
        const column = model.getLineFirstNonWhitespaceColumn(nextLine);

        range = {
          startLineNumber: foundRange.startLineNumber,
          startColumn: column,
          endLineNumber: nextLine,
          endColumn: foundRange.endLineNumber === nextLine ? foundRange.endLineNumber : column
        };
      } else {
        range = prevRange.valueRange;
        text = isSeq(parseValue.contents) ? '[]' : '{}';

        if (!Number.isInteger(prevRange.path)) {
          text = ` ${text}`;
        }
      }

      model.pushEditOperations(null, [{ range, text }], () => null);
    }
  }
}
