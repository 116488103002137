import { Sender } from '@utils/observable';
import { editorObservable } from '@components/yamlEditor/plugins/yamlBind/editor/editorObservable';
import { getJsonObject } from '@components/yamlEditor/utils/getJsonObject';

export function editorEmit(value: string | undefined, sender?: Sender) {
  const json = getJsonObject(value);

  if (json === undefined) {
    return;
  }

  editorObservable.setValue(json, sender);
}
