import { IRange } from 'monaco-editor';
import { isCollection, isMap, isSeq, parseDocument, stringify } from 'yaml';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { TextModel } from '../types';
import { Paths } from '@commonTypes/index';
import { findRange } from './findRange';
import { findStartLine } from './findStartLine';
import { startSpaceRegExp } from './helpers';

export function addText(model: TextModel, paths: Paths, value: unknown) {
  if (Number.isInteger(paths.at(0))) {
    throw new Error(`addText: The path ${JSON.stringify(paths)} cannot start at an array element index.`);
  }

  const { rangePaths } = findRange(model, paths, { includeEmptyLines: true });
  const restPaths = paths.slice(rangePaths.length);
  const isRestPathsEmpty = isEmpty(restPaths);
  const fullRange = model.getFullModelRange();
  const { indentSize } = model.getOptions();
  const EOL = model.getEOL();

  let data = value;

  if (!isRestPathsEmpty) {
    const modifyRestPaths = restPaths.map((path) => (Number.isInteger(path) ? 0 : path));
    const firstRestPath = restPaths.at(0);

    data = set(Number.isInteger(firstRestPath) ? [] : {}, modifyRestPaths, value);
  }

  let range: IRange;
  let text: string;

  if (rangePaths.length) {
    const target = rangePaths[rangePaths.length - 1];
    const indentStart = model.getLineFirstNonWhitespaceColumn(target.range.startLineNumber) + 1;

    let rangeValue = model.getValueInRange(target.valueRange);
    rangeValue = rangeValue.replace(new RegExp(`^${EOL}`), '');
    rangeValue = rangeValue.replace(startSpaceRegExp(indentStart), '');

    const parseValue = parseDocument(rangeValue);

    if (!isCollection(parseValue.contents)) {
      throw new Error(`addText: Cannot add value to scalar type in path ${JSON.stringify(paths)}.`);
    }

    if (isMap(parseValue.contents)) {
      if (isRestPathsEmpty) {
        throw new Error(
          `addText: For an object type value, the path ${JSON.stringify(
            paths
          )} must contain at least one nonexistent path segment.`
        );
      }

      if (Number.isInteger(restPaths.at(0))) {
        throw new Error(
          `addText: Incorrect path for setting value by path: ${JSON.stringify(
            paths
          )}. After the found match of path fragments ${JSON.stringify(
            paths.slice(0, rangePaths.length)
          )} comes an index denoting an element of the array.`
        );
      }
    }

    if (isSeq(parseValue.contents)) {
      data = [data];
    }

    // Отступ модели monaco редактора.
    const { indentSize } = model.getOptions();
    // Прибавляем количество отступов родительской секции и прибавляем отступ для создания вложенности.
    const indentCount = target.range.startColumn - 1 + indentSize;

    text = stringify(data, { indent: indentSize });
    // Удаляем перевод строки т.к. это добавляет ещё строку, для того чтобы к ней не добавлять излишние отступы.
    text = text.replace(/\n$/, '');
    // Добавляем отступы каждой строке, равному количеству отступов родительской секции с добавленным отступом для
    // вложенности.
    text = text.replace(/^/gm, ''.padEnd(indentCount));
    // Заменяем все переводы строк на установленные в модели редактора.
    text = text.replace(/\n/gm, EOL);
    // Переводим текстовый блок для создания вложенности, согласно структуре yaml.
    text = `${EOL}${text}`;

    // Добавляем последнюю пустую строку в конец документа, если последняя строка диапазона значения совпадает с полной.
    if (target.range.endLineNumber === fullRange.endLineNumber) {
      text = `${text}${EOL}`;
    }

    if (parseValue.contents.items.length) {
      const startLine = findStartLine(model, target.range.startLineNumber, target.range.endLineNumber);

      range = {
        startLineNumber: startLine,
        startColumn: model.getLineMaxColumn(startLine),
        endLineNumber: target.range.endLineNumber,
        endColumn: target.range.endColumn
      };
    } else {
      range = target.valueRange;
    }
  } else {
    const startLine = findStartLine(model, fullRange.startLineNumber, fullRange.endLineNumber);
    const startColumnStartLine = model.getLineFirstNonWhitespaceColumn(startLine);

    range = {
      startLineNumber: startLine,
      startColumn: startColumnStartLine ? model.getLineMaxColumn(startLine) : 0,
      endLineNumber: fullRange.endLineNumber,
      endColumn: model.getLineMaxColumn(fullRange.endLineNumber)
    };

    text = stringify(data, { indent: indentSize });

    // Переводим текстовый блок для создания вложенности, согласно структуре yaml.
    if (startColumnStartLine) {
      text = `${EOL}${text}`;
    }
  }

  model.pushEditOperations(null, [{ range, text }], () => null);
}
