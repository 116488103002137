import { JsonEntity, JsonRelation, JsonRootBlock, ModelType } from '@baseModel/types/jsonDescription';
import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { Listener, Unsubscriber } from '@utils/observable';
import type { EventEditorModelObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { YamlModelState } from '@components/yamlEditor/yamlModelState';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { editorObservable } from '@components/yamlEditor/plugins/yamlBind/editor/editorObservable';
import { getId } from '@components/yamlEditor/utils/getId';

export interface EntityDiffElement<T extends JsonEntity | JsonRelation> {
  id: string;
  changedValue?: T;
  deleted?: boolean;
}

function getEntitiesDiff<T extends JsonEntity | JsonRelation>(before: T[], after: T[]): Set<EntityDiffElement<T>> {
  const diff: Set<EntityDiffElement<T>> = new Set();
  const deleted = differenceBy(before, after, getId);

  const different = differenceWith(after, before, isEqual);
  deleted.forEach((el) => {
    diff.add({ deleted: true, id: getId(el) });
  });
  different.forEach((el) => {
    diff.add({ id: getId(el), changedValue: el });
  });

  return diff;
}

export function editorModelsSubscriber<T extends JsonEntity | JsonRelation>(
  modelType: ModelType.entities | ModelType.relations,
  listener: Listener<EventEditorModelObserver<T>>
): Unsubscriber {
  return editorObservable.subscribe((newValue) => {
    const before =
      modelType === ModelType.entities
        ? YamlModelState.getInstance().getEntities()
        : YamlModelState.getInstance().getRelations();
    const after = newValue[JsonRootBlock.model][modelType];
    if (modelType === ModelType.entities) {
      YamlModelState.getInstance().setEntities(<JsonEntity[]>after);
    } else {
      YamlModelState.getInstance().setRelations(<JsonRelation[]>after);
    }
    // Проверить приходит ли новый объект
    // TODO он всегда новый, т.к. сериализуется, полное сравнение делать дольше пока
    if (after === before) {
      return;
    }

    if (!Array.isArray(after) || !Array.isArray(before)) {
      console.error(`${JsonRootBlock.model} ${[ModelType.entities]} должен быть массив, получено ${before} ${after}`);
      return;
    }

    const diff = getEntitiesDiff(<T[]>before, <T[]>after);

    diff.forEach((el) => {
      listener({
        changes: { after: <T>el.changedValue },
        meta: {
          id: el.id,
          deleted: el.deleted
        }
      });
    });
  }, EDITOR_SENDER);
}
