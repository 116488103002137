import { EngineLoader } from '@baseModel/engine/engineLoader';
import { Engine } from '@baseModel/engine/engine';
import { useEffect, useState } from 'react';
import modelExample from '@baseModel/templates/empty.json';
import { JsonDescription } from '@baseModel/types/jsonDescription';
import axios from 'axios';
import { parse } from 'yaml';
import config from '../../config';

const engineLoader = new EngineLoader();
const engine = Engine.getInstance();

let firstProjectLoad = true;

export function useEngineLoad(reloadIfExist = true, fileId?: string): [boolean, string] {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!reloadIfExist && engine.getDocument().getBlocksCount()) {
      return;
    }
    setIsLoading(true);
    if (!fileId && firstProjectLoad) {
      try {
        engine.removeAll();
        engineLoader.schemaLoad(modelExample as unknown as JsonDescription);
        // Загрузку примера делаем только один раз
        firstProjectLoad = false;
      } finally {
        setIsLoading(false);
      }
    } else if (fileId) {
      engine.removeAll();
      axios
        .get<string>(`${config.apiS3Endpoint}/${fileId}`)
        .then((response) => {
          if (response.status === axios.HttpStatusCode.Ok) {
            engineLoader.schemaLoad(parse(response.data) as unknown as JsonDescription);
          } else {
            throw new Error(`Ошибка загрузки файла fileId: ${fileId}`);
          }
        })
        .catch((error: Error) => {
          console.error(error);
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [fileId, reloadIfExist]);

  return [isLoading, error];
}
