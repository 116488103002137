import { confirmDialog } from '@components/confirmDialog/confirmDialog';
import { Engine } from '@baseModel/engine/engine';
import { BlockType } from '@baseModel/document/blocks/types';
import { getWidget } from '@components/markdownEditor/dataDisplayWidgets/utils';

const googleIconsClass = 'material-symbols-outlined';
const engine = Engine.getInstance();
const engineDocument = engine.getDocument();

export class BlockControlsWidget {
  toDOM(blockId: string, blockType: BlockType | string, onEditClick?: () => void) {
    const container = document.createElement('div');
    container.classList.add('markdown-actions');
    container.style.position = 'absolute';
    container.style.display = 'flex';
    container.style.zIndex = '1';
    container.style.height = '1px';
    container.style.justifyContent = 'flex-end';
    container.style.width = '100%';
    container.style.maxWidth = '900px';
    const div = document.createElement('div');
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.gap = '15px';
    div.style.paddingTop = '10px';
    container.appendChild(div);

    const block = engineDocument.getBlockById(blockId);

    if (!block) {
      console.warn(`block ${blockId} не найден`);
      return div;
    }

    const sortIndex = block.getSortIndex();

    if (sortIndex) {
      const moveUpIcon = createIcon('text_select_move_up');
      div.appendChild(moveUpIcon);
      moveUpIcon.onclick = () => engineDocument.setBlockSortIndex(blockId, sortIndex - 1, 'menu');
    }
    if (sortIndex < engineDocument.getBlocksCount() - 1) {
      const moveDownIcon = createIcon('text_select_move_down');
      div.appendChild(moveDownIcon);
      moveDownIcon.onclick = () => engineDocument.setBlockSortIndex(blockId, sortIndex + 1, 'menu');
    }

    const deleteIcon = createIcon('delete');
    div.appendChild(deleteIcon);

    deleteIcon.onclick = () =>
      confirmDialog({
        message: 'Безвозвратное удаление. Вы действительно хотите удалить?',
        accept: () => {
          const Widget = getWidget(block.type);

          if (Widget) {
            const widget = new Widget(blockId);

            if (widget.getConfigViewWrapper() !== null) {
              void widget.delete();
            }
          } else {
            engineDocument.removeBlockById(blockType, blockId, 'menu');
          }
        }
      });

    if (onEditClick) {
      const editIcon = createIcon('edit');
      div.appendChild(editIcon);
      editIcon.onclick = onEditClick;
    }

    return container;
  }
}

function createIcon(name: string) {
  const span = document.createElement('span');
  span.className = googleIconsClass;
  span.style.cursor = 'pointer';
  span.style.color = 'var(--smui-primaryColor)';
  span.innerHTML = name;

  return span;
}
