import markdownit from 'markdown-it';
import Token from 'markdown-it/lib/token';
import markdownItContainer from 'markdown-it-container';
import { markdownItTable } from 'markdown-it-table';
import { MarkdownParser } from 'prosemirror-markdown';
import { markdownSchemaWithExtraNodes } from './markdownSchemaWithExtraNodes';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '../const';

const DOCUMENT_BLOCK_TAG_REGEXP = new RegExp(`^${DOCUMENT_BLOCK_TAG}\\s+(.*)$`);
const EXTERNAL_NODE_TAG_REGEXP = new RegExp(`^${EXTERNAL_NODE_TAG}\\s+(.*)$`);

const md = markdownit('commonmark', { html: false })
  .use(markdownItContainer, EXTERNAL_NODE_TAG, {
    validate: function (params: string) {
      return params.trim().match(EXTERNAL_NODE_TAG_REGEXP);
    },
    marker: ';'
  })
  .use(markdownItContainer, DOCUMENT_BLOCK_TAG, {
    validate: function (params: string) {
      return params.trim().match(DOCUMENT_BLOCK_TAG_REGEXP);
    }
  })
  .use(markdownItTable, { multiline: true, rowspan: false, headerless: false, multibody: true, autolabel: true });

function listIsTight(tokens: Token[], i: number) {
  while (++i < tokens.length) if (tokens[i].type != 'list_item_open') return tokens[i].hidden;
  return false;
}

/**
 * Ожидаем такой вид строки `:::: externalNode [id=${el.id},type=${JsonDocumentBlockType.table}] \n:::`;
 * @param info
 */
function getAttrsExternalNodeFromInfo(info: string) {
  return info
    .match(/\[.*\]/)
    ?.map((el: string) => el.replace(/[[\]]/g, '').split(','))[0]
    .reduce<{ [name: string]: string }>((atrr, el) => {
      const [name, value] = el.split('=');
      atrr[name] = value;
      return atrr;
    }, {});
}

export const markdownExtendParser = new MarkdownParser(markdownSchemaWithExtraNodes, md, {
  container_externalNode: {
    block: EXTERNAL_NODE_TAG,
    getAttrs: (token: Token) => getAttrsExternalNodeFromInfo(token.info) || null
  },
  container_documentBlock: {
    block: DOCUMENT_BLOCK_TAG,
    getAttrs: (token: Token) => getAttrsExternalNodeFromInfo(token.info) || null
  },
  table: { block: 'table' },
  tr: { block: 'table_row' },
  td: { block: 'table_cell' },
  th: { block: 'table_header' },
  blockquote: { block: 'blockquote' },
  paragraph: { block: 'paragraph' },
  list_item: { block: 'list_item' },
  bullet_list: { block: 'bullet_list', getAttrs: (_, tokens: Token[], i) => ({ tight: listIsTight(tokens, i) }) },
  ordered_list: {
    block: 'ordered_list',
    getAttrs: (tok: Token, tokens: Token[], i) => ({
      order: +(tok.attrGet('start') || 1),
      tight: listIsTight(tokens, i)
    })
  },
  heading: { block: 'heading', getAttrs: (tok: Token) => ({ level: +tok.tag.slice(1) }) },
  code_block: { block: 'code_block', noCloseToken: true },
  fence: { block: 'code_block', getAttrs: (tok: Token) => ({ params: tok.info || '' }), noCloseToken: true },
  hr: { node: 'horizontal_rule' },
  image: {
    node: 'image',
    getAttrs: (tok: Token) => ({
      src: tok.attrGet('src'),
      title: tok.attrGet('title') || null,
      alt: (tok.children?.[0] && tok.children[0].content) || null
    })
  },
  hardbreak: { node: 'hard_break' },

  em: { mark: 'em' },
  strong: { mark: 'strong' },
  link: {
    mark: 'link',
    getAttrs: (tok: Token) => ({
      href: tok.attrGet('href'),
      title: tok.attrGet('title') || null
    })
  },
  code_inline: { mark: 'code', noCloseToken: true }
});
