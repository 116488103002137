import { DrawioContext } from '../types/window';
import {
  CreateSidebarElement,
  SidebarContextCreateElements,
  GraphElementResultTypes,
  ID
} from './graphElements/graphElementResult';
import { createSidebarElementVertex } from './graphElements/createSidebarElementVertex';
import { createSidebarElementEdge } from './graphElements/createSidebarElementEdge';
import { MxCell } from '../types/mxCell';
import find from 'lodash/find';
import { checkType } from './edit';

export enum SidebarContainers {
  engineModel = 'engineModel',
  metaModel = 'metaModel'
}

const className = 'draw.io-custom-sidebar-block';
const div = document.createElement('div');
div.className = className;
const containerEngineModel = createContainerContainer();
const containerMetaModel = createContainerContainer();
div.appendChild(containerMetaModel);
div.appendChild(containerEngineModel);

let listSidebarEngineModelElements: { [key: string]: { node: HTMLElement; cell: MxCell } } = {};
let listSidebarMetaModelElements: { [key: string]: { node: HTMLElement; cell: MxCell } } = {};

function createContainerContainer() {
  const div = document.createElement('div');
  div.className = 'geSidebar';
  div.style.backgroundColor = 'white';
  div.style.borderTop = '1px solid';
  div.style.borderBottom = '1px solid';

  return div;
}

export function loadRefreshSidebarContainerPlugin(this: DrawioContext) {
  return (container: SidebarContainers) => {
    if (container === SidebarContainers.engineModel) {
      containerEngineModel.innerHTML = '';
      listSidebarEngineModelElements = {};
    } else if (container === SidebarContainers.metaModel) {
      containerMetaModel.innerHTML = '';
      listSidebarMetaModelElements = {};
    }
  };
}

export function loadCreateSidebarElementsPlugin(this: DrawioContext) {
  const editor = this.Editor;
  const mxCell = this.mxCell;
  const mxGeometry = this.mxGeometry;
  const mxPoint = this.mxPoint;
  const mxClient = this.mxClient;

  const graph = this.EditorUi.editor.graph;
  const sidebar = this.EditorUi.sidebar;
  const sidebarContext = <SidebarContextCreateElements>{
    mxCell,
    mxGeometry,
    mxPoint,
    sidebar,
    graph
  };

  mxClient.IS_POINTER && (containerEngineModel.style.touchAction = 'none');
  mxClient.IS_POINTER && (containerMetaModel.style.touchAction = 'none');

  return (element: CreateSidebarElement, container: SidebarContainers) => {
    if (!sidebar || !sidebar.wrapper) return;

    if (sidebar.wrapper.getElementsByClassName(className).length === 0)
      sidebar.wrapper.insertBefore(div, sidebar.wrapper.firstChild);

    const isEngineModelContainer = container === SidebarContainers.engineModel;
    const isMetaModelContainer = container === SidebarContainers.metaModel;

    if (isEngineModelContainer) removeElementInListSidebarEngineModelElements(element);
    else if (isMetaModelContainer) removeElementInListSidebarMetaModelElements(element);

    const uuid = editor.guid();
    switch (element.type) {
      case GraphElementResultTypes.vertex: {
        addContainer(createSidebarElementVertex.apply(sidebarContext, [element, uuid]));
        break;
      }
      case GraphElementResultTypes.edge: {
        addContainer(createSidebarElementEdge.apply(sidebarContext, [element, uuid]));
        break;
      }
    }

    function addContainer({ node, cell }: { node: HTMLElement; cell: MxCell }) {
      if (isEngineModelContainer) {
        containerEngineModel.appendChild(node);
        listSidebarEngineModelElements[uuid] = { node, cell };
      } else if (isMetaModelContainer) {
        containerMetaModel.appendChild(node);
        listSidebarMetaModelElements[uuid] = { node, cell };
      }
    }
  };
}

export function removeElementInListSidebarEngineModelElements(element: CreateSidebarElement) {
  const _id = find(element.attributes, { type: ID })?.value;
  if (_id)
    for (const uuid in listSidebarEngineModelElements) {
      const cell = listSidebarEngineModelElements[uuid].cell;
      if (cell.getAttribute(ID, '') !== _id) continue;

      if (checkType(cell, element.type)) removeChildContainerByCell(cell);
    }
}

export function removeElementInListSidebarMetaModelElements(element: CreateSidebarElement) {
  const type = find(element.attributes, { type: 'type' })?.value;
  if (type)
    for (const uuid in listSidebarMetaModelElements) {
      const cell = listSidebarMetaModelElements[uuid].cell;
      if (cell.getAttribute('type', '') !== type) continue;

      if (checkType(cell, element.type)) removeChildContainerByCell(cell, true);
    }
}

export function removeChildContainerByCell(cell: MxCell, isCreateElement = false) {
  if (!cell.uuid) return;

  if (listSidebarEngineModelElements[cell.uuid] !== undefined) {
    containerEngineModel.removeChild(listSidebarEngineModelElements[cell.uuid].node);
    delete listSidebarEngineModelElements[cell.uuid];
  } else if (listSidebarMetaModelElements[cell.uuid] !== undefined && isCreateElement) {
    containerMetaModel.removeChild(listSidebarMetaModelElements[cell.uuid].node);
    delete listSidebarMetaModelElements[cell.uuid];
  }
}
