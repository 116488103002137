import React from 'react';
import { Button, Modal } from '@smwb/summer-ui';
import s from './confirmDialog.module.less';
import { Observable } from '@utils/observable';

interface ConfirmDialogProps {
  message?: React.ReactNode;
  accept?: () => void;
  reject?: () => void;
  isOpen?: boolean;
}

const observable = new Observable<ConfirmDialogProps>('confirm_dialog', {});

export function confirmDialog(props: ConfirmDialogProps = {}) {
  props = { ...props, ...{ isOpen: props.isOpen === undefined ? true : props.isOpen } };

  if (props.isOpen) {
    observable.setValue(props);
  }

  const open = (updatedProps: ConfirmDialogProps = {}) => {
    observable.setValue({ ...props, ...updatedProps, isOpen: true });
  };

  const close = () => {
    observable.setValue({ isOpen: false });
  };

  return { open, close };
}

export function ConfirmDialog() {
  const [dialogProps, setDialogProps] = React.useState<ConfirmDialogProps>({});

  React.useEffect(() => {
    return observable.subscribe((props) => {
      setDialogProps(props);
    });
  }, []);

  const onAccept = () => {
    if (typeof dialogProps.accept === 'function') {
      dialogProps.accept();
    }

    onClose();
  };

  const onReject = () => {
    if (typeof dialogProps.reject === 'function') {
      dialogProps.reject();
    }

    onClose();
  };

  const onClose = () => {
    setDialogProps({ ...dialogProps, isOpen: false });
  };

  return (
    <Modal className={s.root} open={Boolean(dialogProps.isOpen)} onClose={onClose} size="small" closeOnOutsideClick>
      <Modal.Header>{dialogProps.message}</Modal.Header>
      <Modal.Footer className={s.footer}>
        <Button variant="contained" onClick={onAccept}>
          Да
        </Button>
        <Button variant="contained" onClick={onReject}>
          Отмена
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
