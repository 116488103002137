import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { showErrorOnChange } from '@ui/connects/rff/utils';
import s from './mappingField.module.less';

export interface MappingFieldProps extends React.HTMLProps<HTMLTextAreaElement> {
  name: string;
  helperText?: string;
  fieldProps?: Partial<FieldProps<string, FieldRenderProps<string, HTMLElement, string>>>;
}

export const MappingField = forwardRef<HTMLTextAreaElement, MappingFieldProps>(function MappingField(props, ref) {
  const { name, helperText, className, fieldProps, ...rest } = props;

  return (
    <Field
      {...fieldProps}
      name={name}
      render={({ input, meta }) => {
        const isError = showErrorOnChange({ meta });
        const error = (meta.error || meta.submitError) as string | undefined;

        return (
          <div className={cn(s.root, className)}>
            <textarea ref={ref} className={cn(s.field, className, isError && s.isError)} {...input} {...rest} />
            {(helperText || error) && (
              <div className={cn(s.helperText, isError && s.isError)}>{isError ? error : helperText}</div>
            )}
          </div>
        );
      }}
    />
  );
});
