import find from 'lodash/find';
import uniqueId from 'lodash/uniqueId';
import { getEnds } from '@components/drawio/utils';
import { Engine } from '@baseModel/engine/engine';
import { DrawioContext } from '../types/window';
import { MxGraphModel } from '../types/mxGraphModel';
import { MxEventObject, MxEventObjectProperty } from '../types/mxEvent';
import { ID } from './graphElements/graphElementResult';
import { removeChildContainerByCell } from './createSidebarElements';
import { loadCheckEdgeCellsPlugin, loadRemoveCellPlugin } from './errors';
import { isArrowEnd } from '@components/drawio/plugins/eventDifference/eventDifferenceTerminal';

const engine = Engine.getInstance();

export function addListenerCellsInserted(this: DrawioContext) {
  if (this.EditorUi.editor.isChromelessView()) return;

  const graph = this.EditorUi.editor.graph;
  const removeCell = loadRemoveCellPlugin.apply(this);

  graph.addListener(this.mxEvent.CELLS_ADDED, (sender: MxGraphModel, evt: MxEventObject) => {
    const cells = evt.getProperty(MxEventObjectProperty.cells);
    if (cells instanceof this.mxCell) return;

    for (let i = 0; i < cells.length; i++) removeChildContainerByCell(cells[i]);
  });

  graph.addListener(this.mxEvent.CELLS_ADDED, (sender: MxGraphModel, evt: MxEventObject) => {
    const cells = evt.getProperty(MxEventObjectProperty.cells);
    if (cells instanceof this.mxCell) return;

    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i];

      if (!cell.value || !(cell.value as HTMLElement)['getAttribute']) continue;

      const modelType = (cell.value as HTMLElement).getAttribute('type');
      if (!modelType) {
        throw new Error('Model type not found');
      }
      if (!cell.getAttribute(ID, null)) {
        if (cell.getAttribute('type', null)) {
          const newId = uniqueId('drawio-' + Date.now().toString());
          graph.setAttributeForCell(cell, ID, newId);
          if (cell.vertex) {
            const metaEntity = engine.getMetaEntityByName(modelType);
            graph.setAttributeForCell(cell, 'label', metaEntity.getDisplayName() || modelType);
          } else if (cell.edge) {
            const metaRelation = engine.getMetaRelationByName(modelType);
            graph.setAttributeForCell(cell, 'label', metaRelation.getDisplayName() || modelType);
          }
        } else {
          removeCell(cell);
        }
      }
    }
  });
}

export function addListenerCellConnection(this: DrawioContext) {
  if (this.EditorUi.editor.isChromelessView()) return;

  const checkEdgeCells = loadCheckEdgeCellsPlugin.apply(this);
  const graph = this.EditorUi.editor.graph;

  graph.addListener(this.mxEvent.CELL_CONNECTED, (sender: MxGraphModel, evt: MxEventObject) => {
    const edge = evt.getProperty(MxEventObjectProperty.edge);

    if (edge instanceof this.mxCell) {
      const _id = edge.getAttribute(ID, '');
      if (!_id || !edge.edge) return;

      const relationModel = engine.getRelationById(_id);
      const { sourceValue, targetValue } = getEnds(relationModel);
      const isArrow = isArrowEnd(edge);

      if (isArrow) {
        checkEdgeCells(edge, targetValue?.toString() || null, sourceValue?.toString() || null);
      } else {
        const targetId = edge.target?.getAttribute(ID, null) || null;

        checkEdgeCells(
          edge,
          (targetValue === targetId ? targetValue : sourceValue)?.toString() || null,
          (targetValue === targetId ? sourceValue : targetValue)?.toString() || null
        );
      }
    }
  });

  graph.addListener(this.mxEvent.CELL_CONNECTED, (sender: MxGraphModel, evt: MxEventObject) => {
    const edge = evt.getProperty(MxEventObjectProperty.edge);
    const isSource = evt.getProperty(MxEventObjectProperty.source);
    if (edge instanceof this.mxCell) {
      const _id = edge.getAttribute(ID, '');
      if (!_id || !edge.edge) return;

      if (edge.children) {
        const isExistEdgeLabel = find(edge.children, (o) => {
          const isGeometry =
            o.geometry &&
            o.geometry.y === 0 &&
            o.geometry.width === 0 &&
            o.geometry.height === 0 &&
            o.geometry.relative &&
            o.vertex;

          if (isSource) return isGeometry && o?.geometry?.x === -1;
          return isGeometry && o?.geometry?.x === 1;
        });

        if (isExistEdgeLabel) return;
      }

      // const edgeLabel = isSource
      //   ? new this.mxCell(
      //       'link1',
      //       new this.mxGeometry(-1, 0, 0, 0),
      //       'edgeLabel;resizable=0;html=1;align=left;verticalAlign=top;'
      //     )
      //   : new this.mxCell(
      //       'link2',
      //       new this.mxGeometry(1, 0, 0, 0),
      //       'edgeLabel;resizable=0;html=1;align=right;verticalAlign=top;'
      //     );
      //
      // edgeLabel.geometry && (edgeLabel.geometry.relative = true);
      // edgeLabel.setConnectable(false);
      // edgeLabel.vertex = true;
      // edge.insert(edgeLabel);
    }
  });
}
