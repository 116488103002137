import { EditorState, NodeSelection } from 'prosemirror-state';
import { MarkType, NodeType, Attrs } from 'prosemirror-model';

export function markActive(state: EditorState, type: MarkType): boolean {
  const { from, $from, to, empty } = state.selection;
  if (empty) return !!type.isInSet(state.storedMarks || $from.marks());
  else return state.doc.rangeHasMark(from, to, type);
}

export function blockActive(state: EditorState, nodeType: NodeType, attrs: Attrs): boolean {
  const { $from, to, node } = state.selection as NodeSelection;
  if (node) return node.hasMarkup(nodeType, attrs);
  return to <= $from.end() && $from.parent.hasMarkup(nodeType, attrs);
}
