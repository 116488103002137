import { getModifyTargetPath, getPath } from '@utils/getPath';
import { ModelPaths } from '@components/yamlEditor/plugins/yamlBind/types';
import { JsonDescription, JsonEntity, JsonRelation } from '@baseModel/types/jsonDescription';
import { TargetPath } from '@commonTypes/index';
import get from 'lodash/get';

export function getPathInEditorByValuePath(modelPath: ModelPaths, targetPath: TargetPath, parseYaml: JsonDescription) {
  const collection = get(parseYaml, modelPath, []);

  if (!Array.isArray(collection)) {
    return;
  }
  const modifyTargetPath = getModifyTargetPath(targetPath);
  const id = modifyTargetPath[0];

  const index = collection.findIndex((entity) => {
    if (!(typeof entity === 'object' && entity !== null)) {
      return false;
    }

    let entityId: string | number | undefined;

    if (modelPath === ModelPaths.Entities) {
      entityId = (entity as JsonEntity).__id;
    } else if (modelPath === ModelPaths.Relations) {
      entityId = (entity as JsonRelation).fields?.__id as string;
    }

    if (entityId === undefined) {
      return false;
    }

    return String(entityId) === String(id);
  });

  if (index === -1) {
    console.error('baseModelObserver:', `Entity with \`id\` \`${id}\` not found.`);
    return;
  }

  modifyTargetPath[0] = index;
  return getPath(modelPath, modifyTargetPath);
}
