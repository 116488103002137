import React from 'react';
import { Button } from '@smwb/summer-ui';
import s from './table/table.module.less';

interface ActionsCellProps {
  onDelete: React.MouseEventHandler;
  onUpdate: React.MouseEventHandler;
}

export function ActionsCell({ onDelete, onUpdate }: ActionsCellProps) {
  return (
    <div className={s.actions}>
      <Button icon="edit" variant="text" className={s.iconButton} onClick={onUpdate} />
      <Button icon="delete" variant="text" className={s.iconButton} onClick={onDelete} />
    </div>
  );
}
