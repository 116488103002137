import { DrawioContext } from '../types/window';
import { GraphContextGetElements, GraphElement } from './graphElements/graphElementResult';
import { getGraphElementVertex } from './graphElements/getGraphElementVertex';
import { getGraphElementEdge } from './graphElements/getGraphElementEdge';
import { MxCell } from '../types/mxCell';
import { Models } from '@baseModel/engine/types';

export function loadGetGraphElementsPlugin(this: DrawioContext) {
  const getLabel = loadGetLabelByCellPlugin.apply(this);
  const graph = this.EditorUi.editor.graph;
  const model = graph.model;

  const getGraphElements = (): GraphElement[] => {
    const elements = <GraphElement[]>[];

    for (const id in model.cells) {
      const cell = model.cells[id];
      if (!cell.id) continue;

      if (cell.vertex) elements.push(getGraphElementVertex.apply(graphContext, [cell]));
      else if (cell.edge) elements.push(getGraphElementEdge.apply(graphContext, [cell]));
    }

    return elements;
  };

  const graphContext = <GraphContextGetElements>{
    getLabel
  };

  return getGraphElements;
}

export function loadGetLabelByCellPlugin(this: DrawioContext) {
  const graph = this.EditorUi.editor.graph;

  return (cell: MxCell): string => {
    let label = graph.getLabel(cell);

    function anonymizeHtml(label: string): string {
      const div = document.createElement('div');
      div.innerHTML = label;
      return div.innerText;
    }

    if (graph.isHtmlLabel(cell)) label = anonymizeHtml(label);

    return label;
  };
}

export function getCellModelType(cell: MxCell, type: string): Models | false {
  if (type && cell.vertex) return Models.EntityMetaModel;
  else if (type && cell.edge) return Models.RelationMetaModel;

  return false;
}
