import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@smwb/summer-ui';
import { SchemasSettings, setDiagnosticsOptions } from 'monaco-yaml';
import * as monaco from 'monaco-editor';
import { editor } from 'monaco-editor';
import { highlightDocumentBlockById } from '@components/markdownEditor/redux/markdownEditor';
import { Engine } from '@baseModel/engine/engine';
import { store, useAppDispatch } from '../../redux/store';
import { useTypedSelector } from '../../redux/types';
import { YamlBind } from './plugins/yamlBind/yamlBind';
import { getOrCreateModel } from './utils/getOrCreateModel';
import { findDocumentBlockIdByLineNumber } from './utils/findDocumentBlockIdByLineNumber';
import { findRange } from './utils/findRange';
import schema from './schema.json';
import s from './yamlEditor.module.less';
import { YamlModelState } from '@components/yamlEditor/yamlModelState';
import { EngineLoader } from '@baseModel/engine/engineLoader';
import { parse } from 'yaml';
import { JsonDescription } from '@baseModel/types/jsonDescription';
import { getPathInEditorByValuePath } from '@components/yamlEditor/utils/getPathInEditorByValuePath';
import { ModelPaths } from '@components/yamlEditor/plugins/yamlBind/types';

window.MonacoEnvironment = {
  getWorker(_, label) {
    switch (label) {
      case 'editorWorkerService':
        return new Worker(new URL('monaco-editor/esm/vs/editor/editor.worker', import.meta.url));
      case 'yaml':
        return new Worker(new URL('monaco-yaml/yaml.worker', import.meta.url));
      default:
        throw new Error(`Unknown label ${label}`);
    }
  }
};

const modelPath = 'monaco-yaml.yaml';

const defaultSchema: SchemasSettings = {
  uri: 'https://myserver.com/schema.json',
  // @ts-expect-error TypeScript can’t narrow down the type of JSON imports
  schema,
  fileMatch: [modelPath]
};

setDiagnosticsOptions({
  schemas: [defaultSchema]
});

const yamlBind = new YamlBind();
const engine = Engine.getInstance();

function useHighlightedDecorations(
  editorRef?: monaco.editor.IStandaloneCodeEditor
): [() => void, (range: monaco.Range, scrollToRange?: boolean) => void] {
  const [highlightedDecorationsCollection, setHighlightedDecorationsCollection] =
    useState<editor.IEditorDecorationsCollection>();
  useEffect(() => {
    if (editorRef) {
      const decorationsCollection = editorRef.createDecorationsCollection([]);
      setHighlightedDecorationsCollection(decorationsCollection);
    }
  }, [editorRef]);

  const clearHighlight = useCallback(
    () => highlightedDecorationsCollection?.clear(),
    [highlightedDecorationsCollection]
  );

  return [
    clearHighlight,
    (range, scrollToRange = true) => {
      const decoration: editor.IModelDeltaDecoration = {
        range,
        options: { className: s.highlighted, isWholeLine: true }
      };
      highlightedDecorationsCollection?.set([decoration]);
      if (scrollToRange && editorRef) {
        editorRef.revealLineInCenter(range.startLineNumber, editor.ScrollType.Smooth);
      }
    }
  ];
}

const getOrCreateYamlModel = () => getOrCreateModel('', 'yaml', modelPath);

export function YamlEditor() {
  const { theme } = useTheme();
  const [editorInstance, setEditorInstance] = useState<monaco.editor.IStandaloneCodeEditor | undefined>(undefined);
  const [mountRef, setMountRef] = useState<HTMLDivElement | null>(null);

  const themeMode = theme === 'light' ? 'light' : 'vs-dark';

  useEffect(() => {
    if (!editorInstance && mountRef) {
      const model = getOrCreateYamlModel();

      setEditorInstance(
        monaco.editor.create(mountRef, {
          model,
          automaticLayout: true,
          tabSize: 2,
          theme: themeMode
        })
      );
    }
  }, [editorInstance, mountRef, themeMode]);

  useEffect(() => {
    monaco.editor.setTheme(themeMode);
  }, [themeMode]);

  const [clearHighlight, setHighlight] = useHighlightedDecorations(editorInstance);

  const { highlightedDocumentBlockId, highlightedModelFieldPath } = useTypedSelector(
    (state) => state.app.yamlEditor.actions
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const model = editorInstance?.getModel();
    if (!editorInstance || !model) {
      return;
    }

    if (highlightedDocumentBlockId !== undefined) {
      const blockIdx = engine.getDocument().getBlockById(highlightedDocumentBlockId)?.getSortIndex();
      if (blockIdx === -1 || blockIdx === undefined) {
        return;
      }

      const docPath = 'document';
      const { foundRange } = findRange(model, [docPath, blockIdx]);
      if (foundRange) {
        setHighlight(foundRange);
      }
    } else if (highlightedModelFieldPath) {
      const parseYaml = parse(model.getValue()) as JsonDescription;
      const foundPath = getPathInEditorByValuePath(
        highlightedModelFieldPath.slice(0, 2).join('.') as ModelPaths,
        highlightedModelFieldPath.slice(2),
        parseYaml
      );
      if (!foundPath) {
        console.error('path nor found', highlightedModelFieldPath);
        return;
      }
      const { foundRange } = findRange(model, foundPath);
      if (foundRange) {
        setHighlight(foundRange);
      } else {
        clearHighlight();
        return;
      }
    } else {
      clearHighlight();
      return;
    }
  }, [clearHighlight, highlightedDocumentBlockId, setHighlight, highlightedModelFieldPath, dispatch, editorInstance]);

  const editorContextRef = React.useRef<monaco.editor.IContextKey<boolean>>();

  React.useEffect(() => {
    const model = editorInstance?.getModel();

    if (!editorInstance || !model) {
      return;
    }

    model.setValue(YamlModelState.getInstance().getYaml());
    const engineLoaderUnsubscriber = EngineLoader.onModelLoaded(() => {
      console.log('reload yaml state from engine');
      YamlModelState.getInstance().reloadStateFromEngine();
      model.setValue(YamlModelState.getInstance().getYaml());
    });

    const onDidChangeCursorPositionUnsubscribe = editorInstance.onDidChangeCursorPosition((position) => {
      clearHighlight();
      const documentStartRange = findRange(model, ['document']);
      let id: string | undefined;
      if ((documentStartRange.foundRange?.startLineNumber || 0) < position.position.lineNumber) {
        id = findDocumentBlockIdByLineNumber(model, position.position.lineNumber);
      }
      store.dispatch(highlightDocumentBlockById(id));
    });

    const editorChangeUnsubscribe = yamlBind.editorInternalChangesSubscriber(model, editorContextRef.current);

    const editorUnsubscribe = yamlBind.editorSubscribe();
    const modelUnsubscribe = yamlBind.baseModelSubscribe();

    return () => {
      onDidChangeCursorPositionUnsubscribe?.dispose();
      editorChangeUnsubscribe();
      editorUnsubscribe();
      modelUnsubscribe();
      engineLoaderUnsubscriber();
    };
  }, [clearHighlight, editorInstance]);

  React.useEffect(() => {
    const model = editorInstance?.getModel();

    if (!model) {
      return;
    }

    editorContextRef.current = editorInstance?.createContextKey('model', false);

    model.onDidChangeContent((event) => {
      // TODO при установке значения модели, эвенты тоже попадают, но не надо бы
      const contextValue = editorContextRef.current?.get();
      if (contextValue || event.isFlush) {
        return;
      }
      yamlBind.callEditorModelChange(model.getValue());
    });
  }, [editorInstance]);

  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <div ref={setMountRef} className={s.editor} />
      </div>
    </div>
  );
}
