import { CreateSidebarElement, SidebarContextCreateElements } from './graphElementResult';
import { createElementVertexCell } from './createElementVertexCell';
import { MxCell } from '../../types/mxCell';

export function createSidebarElementVertex(
  this: SidebarContextCreateElements,
  element: CreateSidebarElement,
  uuid: string
): { node: HTMLElement; cell: MxCell } {
  const cell = createElementVertexCell.apply(this, [element.attributes]);
  cell.uuid = uuid;

  const node = this.sidebar.createVertexTemplateFromCells([cell], 80, 80, '');

  return { node, cell };
}
