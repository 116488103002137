import { Icon, MenuItem as SMWBMenuItem, MenuItemIcon, MenuItemProps as SMWBMenuItemProps } from '@smwb/summer-ui';
import React from 'react';
import { MENU_ICON_SIZE } from '@components/markdownEditor/topMenu/const';

export interface MenuItemProps extends SMWBMenuItemProps {
  icon?: string;
}

export function MenuItem({ icon, children, ...menuProps }: MenuItemProps) {
  return (
    <SMWBMenuItem {...menuProps}>
      {icon && (
        <MenuItemIcon>
          <Icon name={icon} size={MENU_ICON_SIZE} />
        </MenuItemIcon>
      )}
      {children}
    </SMWBMenuItem>
  );
}
