import React, { useMemo } from 'react';
import cn from 'classnames';
import { Button } from '@smwb/summer-ui';
import { Models } from '@baseModel/engine/types';
import { JsonDocumentTableBlockColumn } from '@baseModel/types/jsonDescription';
import { set as setActions } from '@components/tableView/redux/actions';
import { ActionType, NestedTable } from '@components/tableView/types';
import { TableHeader } from '@components/tableView/tableHeader';
import { RenderCell } from '@components/tableView/renderCell';
import { ActionsCell } from '@components/tableView/actionsCell';
import { ExpandTable } from '@components/tableView/expandTable/expandTable';
import { Engine } from '@baseModel/engine/engine';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { confirmDialog } from '@components/confirmDialog/confirmDialog';
import { useAppDispatch } from '../../../redux/store';
import s from './table.module.less';
import { ShowLink } from '@baseModel/document/blocks/table';
import { ColumnDef, ColumnSizingState, Row } from '@tanstack/react-table';
import { Table as RTable } from '@smwb/summer-ui/dist/connects/rt/table';
import {
  ACTION_COLUMN,
  getColumnName,
  setColumnOrderDebounce,
  setColumnSizeDebounce
} from '@components/tableView/utils';

const engine = Engine.getInstance();

export class EntityRelation {
  constructor(readonly relationId: string, readonly model: Entity) {}
}

export interface TableProps {
  className?: string;
  configId: string;
  modelType: Models.Entity | Models.Relation;
  rowType: string;
  columns: JsonDocumentTableBlockColumn[];
  showLink?: ShowLink;
  models: Entity[] | Relation[] | EntityRelation[];
  title?: React.ReactNode;
  modelId?: string;
  // id модель на 2 уровня выше иерархии, что бы не показывать циклы
  hiddenModelId?: string;
  nestedTable?: NestedTable;
  depth?: number;
}

const onDeleteRelation = (id: string) => () => {
  confirmDialog({
    message: 'Безвозвратное удаление. Вы действительно хотите удалить связь?',
    accept: () => {
      try {
        engine.removeRelation(id);
      } catch (error) {
        console.error(error);
      }
    }
  });
};

const onModelDelete = (model: Entity | Relation) => () => {
  const modelId = model.getId();

  if (modelId === undefined) {
    return;
  }

  confirmDialog({
    message: 'Безвозвратное удаление. Вы действительно хотите удалить модель?',
    accept: () => {
      try {
        if (model instanceof Entity) {
          engine.removeEntity(modelId);
        } else {
          engine.removeRelation(modelId);
        }

        model.deleteMe();
      } catch (error) {
        console.error(error);
      }
    }
  });
};

function getCurrentModelId(element: EntityRelation | Entity | Relation) {
  const model = element instanceof EntityRelation ? element.model : element;
  return (model instanceof EntityRelation ? model.model : model).getId() as string;
}

export function Table({
  className,
  configId: tableId,
  modelType,
  rowType,
  columns,
  showLink,
  models,
  title,
  modelId,
  hiddenModelId,
  nestedTable,
  depth = 0
}: TableProps) {
  const dispatch = useAppDispatch();

  const isEntity = modelType === Models.Entity;
  const modelNames = isEntity ? engine.getEntityMetaModelsNames() : engine.getRelationMetaModelsNames();

  const metaModel = isEntity ? engine.getMetaEntityByName(rowType) : engine.getMetaRelationByName(rowType);

  const rtColumns: ColumnDef<typeof models[number]>[] = useMemo(() => {
    const metaModelName = nestedTable && !nestedTable?.endTargetName ? nestedTable.relation : rowType;

    function dispatchAdd() {
      dispatch(
        setActions({
          type: ActionType.Add,
          modelType: modelType,
          metaModelName,
          tableId,
          modelId,
          nestedTable
        })
      );
    }

    const actionColumn: ColumnDef<typeof models[number]> = {
      id: ACTION_COLUMN.actionsLeft,
      header: () => (
        <div className={s.buttons}>
          <Button className={s.iconButton} icon="add" onClick={dispatchAdd} />
        </div>
      ),
      cell: (row) =>
        row.row.getCanExpand() ? (
          <Button
            {...{
              onClick: row.row.getToggleExpandedHandler()
            }}
            className={s.iconButton}
            variant="text"
            icon={row.row.getIsExpanded() ? 'expand_less' : 'expand_more'}
            iconProps={{ size: 24 }}
          />
        ) : null,
      accessorFn: (row) => row,
      meta: {
        cellProps: {
          className: s.expandCell,
          style: showLink
            ? {}
            : {
                display: 'none'
              }
        }
      },
      size: 36,
      enableResizing: false
    };
    return [actionColumn]
      .concat(
        columns.map((column, i) => {
          return {
            id: getColumnName(modelType, rowType, column),
            header: () => <TableHeader model={metaModel} column={columns[i]} />,
            cell: (row) => {
              const rowValue = row.getValue();
              if (rowValue instanceof Entity || rowValue instanceof Relation) {
                return <RenderCell model={rowValue} column={column} index={i} />;
              } else if (rowValue instanceof EntityRelation) {
                return <RenderCell model={rowValue.model} column={column} index={i} />;
              } else {
                return <span>{`${'unknown model type'} ${rowValue}`}</span>;
              }
            },
            accessorFn: (row) => row,
            meta: {
              cellProps: {
                colSpan: i === 0 && !showLink ? 2 : 1
              },
              draggableColumn: true
            },
            size: column.size,
            enableResizing: i < columns.length - 1
          };
        })
      )
      .concat([
        {
          id: ACTION_COLUMN.actionsRight,
          header: () => <div />,
          cell: (row) => (
            <ActionsCell
              onDelete={
                row.getValue() instanceof EntityRelation
                  ? onDeleteRelation((row.getValue() as EntityRelation).relationId)
                  : onModelDelete(row.getValue() as Entity | Relation)
              }
              onUpdate={() =>
                dispatch(
                  setActions({
                    type: ActionType.Update,
                    modelId: (row.getValue() as Entity).getId(),
                    modelType: (row.getValue() as Entity).modelType,
                    metaModelName,
                    tableId
                  })
                )
              }
            />
          ),
          meta: {
            cellProps: {
              className: s.actionCell
            }
          },
          size: 72,
          enableResizing: false,
          accessorFn: (row) => row
        }
      ]);
  }, [columns, tableId, dispatch, metaModel, modelId, modelType, nestedTable, rowType, showLink]);

  if (!modelNames.includes(rowType)) {
    return <div>Model type ${rowType} not found.</div>;
  }
  // https://github.com/microsoft/TypeScript/issues/44373#issuecomment-1405744410
  const modelsFix: Array<typeof models[number]> = models;
  return (
    <>
      {/*Не даем редактору обрабатывать действия над таблицей*/}
      <div
        className={cn(s.table, className)}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onCopy={(e) => e.stopPropagation()}
      >
        {title && (
          <div className={s.header}>
            <div className={s.title}>{title}</div>
          </div>
        )}
        <RTable
          data={modelsFix.filter((element) => {
            // Не показываем запись модели, стоящей на уровень выше по иерархии
            return getCurrentModelId(element) !== hiddenModelId;
          })}
          columns={rtColumns}
          expandable
          enableColumnResizing={rtColumns.length > 3}
          draggableColumns
          onColumnOrderChange={(oldOrder, newOrder: string[]) => setColumnOrderDebounce(tableId, newOrder, depth)}
          getRowCanExpand={() => !!showLink}
          renderSubComponent={({ row }: { row: Row<EntityRelation | Entity | Relation> }) => {
            return showLink ? (
              <ExpandTable
                depth={depth}
                configId={tableId}
                parentModelId={getCurrentModelId(row.original)}
                inheritModelId={modelId}
              />
            ) : (
              <span>showLink not found</span>
            );
          }}
          onColumnSizingChange={(oldSize: ColumnSizingState, newSize: ColumnSizingState) =>
            setColumnSizeDebounce(tableId, newSize, depth)
          }
        />
      </div>
    </>
  );
}
