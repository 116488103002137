import { Model } from './model';
import { SimpleValueType } from '../types/simpleValueType';
import { RelationMetaModel } from '../metaModel/relationMetaModel';
import { JsonSource } from '../types/jsonDescription';
import { ModelError } from '../errors';
import { Models } from '../engine/types';

export class Relation extends Model<RelationMetaModel, never, SimpleValueType, JsonSource> {
  public readonly modelType = Models.Relation;

  public override validateData() {
    super.validateData();
    this.getRelationNames().map((name) => {
      this.validateRelation(name, this.getRelationValue(name));
    });
  }

  protected override validateRelation(relationName: string, value?: SimpleValueType) {
    // Разрешим пустое значение
    if (!value || !this.engine) {
      return;
    }
    const entity = this.engine.getEntityById(value.toString());
    let errorMessage = '';
    if (!entity) {
      errorMessage = `entity with id ${value.toString()} not found`;
    } else if (entity.getMetaModel().getName() !== this.getMetaModel().getRelationValue(relationName)?.type) {
      errorMessage = `entity with id ${value.toString()} has wrong type ${
        this.getMetaModel().getRelationValue(relationName)?.type
      },${entity.getMetaModel().getName()} required for relation ${relationName}`;
    }
    if (errorMessage) {
      this.logger(errorMessage);
      throw new ModelError(errorMessage);
    }
  }
}
