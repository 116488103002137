import { Fragment, Node } from 'prosemirror-model';

/**
 * Перебираем все дочерние ноды первого уровня и вызываем на них колбек, если колбек вернет false заканчиваем перебор
 * @param doc
 * @param cb
 * @param from
 * @param to
 */
export function docForEach(
  doc: Node | Fragment,
  cb: (node: Node, pos: number, index: number) => boolean,
  from = 0,
  to?: number
) {
  let pos = 0;
  for (let childNumber = 0; childNumber < doc.childCount; childNumber++) {
    const node = doc.child(childNumber);
    if ((from <= pos + node.nodeSize && !cb(node, pos, childNumber)) || (to && to <= pos + node.nodeSize)) {
      return;
    }
    pos += node.nodeSize;
  }
}
