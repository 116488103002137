import { Markdown } from './markdown';
import { Table } from './table';
import { Diagram } from './diagram';
import { CustomNode } from './customNode';
import { Block as WidgetBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';

export enum BlockType {
  markdown = 'markdown',
  table = 'table',
  diagram = 'diagram',
  customNode = 'customNode'
}

export type AvailableBlock = Markdown | Table | Diagram | CustomNode | WidgetBlock;
