import { EditorView } from 'prosemirror-view';
import { blockHighlightPluginKey } from '@components/markdownEditor/plugins/blockHighlight';

export function scrollToBlockAndHighlight(
  view: EditorView,
  blockId: string,
  highlightColor = 'var(--smui-onPrimaryContainerHover)'
): void {
  // Найти позицию блока в документе
  let from: number | undefined;
  let to: number | undefined;
  view.state.doc.descendants((node, pos) => {
    if (node.attrs.id === blockId) {
      from = pos;
      to = from + node.nodeSize;
      return false; // Прекратить обход документа
    }
    return true;
  });
  if (from !== undefined && to !== undefined) {
    // Выделить блок
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (localStorage.mdEditor?.blockHighlight) {
      const tr = view.state.tr.setMeta(blockHighlightPluginKey, { add: { from, to, color: highlightColor } });
      view.dispatch(tr);
    }
    // Прокрутить к позиции блока
    const domNode = view.nodeDOM(from) as HTMLElement;
    if (domNode) {
      const rect = domNode.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollToPosition = rect.top + scrollTop - 100;
      window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.scrollToBlockAndHighlight = scrollToBlockAndHighlight;
