import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ActionsState {
  editableTableId?: string;
  editableTableIndex?: number;
  open: boolean;
}

const initialState: ActionsState = {
  editableTableId: undefined,
  open: false
};

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    editTableOpen: (
      state,
      action: PayloadAction<Pick<ActionsState, 'editableTableId' | 'editableTableIndex'> | undefined>
    ) => {
      state.open = true;
      state.editableTableId = action.payload?.editableTableId;
      state.editableTableIndex = action.payload?.editableTableIndex;
    },

    editTableClose: (state) => {
      state.open = false;
      state.editableTableId = undefined;
    }
  }
});

export const { editTableOpen, editTableClose } = actionsSlice.actions;

export default actionsSlice.reducer;
