import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export const blockHighlightPluginKey = new PluginKey('blockHighlight');

interface BlockHighlightAddAction {
  from: number;
  to: number;
  color: string;
}

interface BlockHighlightRemoveAction {
  from: number;
  to: number;
}

interface BlockHighlightAction {
  add?: BlockHighlightAddAction;
  remove?: BlockHighlightRemoveAction;
  removeAll?: boolean;
}

export const blockHighlightPlugin = new Plugin({
  key: blockHighlightPluginKey,
  state: {
    init: () => DecorationSet.empty,
    apply(tr, set) {
      const action = tr.getMeta(blockHighlightPluginKey) as BlockHighlightAction | undefined;
      if (action && action.add) {
        const color = action.add.color;
        const deco = Decoration.inline(action.add.from + 1, action.add.to - 1, { style: `background-color: ${color}` });

        const empty = DecorationSet.empty;

        return empty.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        const decorationsToRemove = set.find(action.remove.from + 1, action.remove.to - 1);
        return set.remove(decorationsToRemove);
      } else if (action && action.removeAll) {
        return DecorationSet.empty;
      } else {
        return set;
      }
    }
  },
  props: {
    decorations(state) {
      return this.getState(state);
    }
  }
});
