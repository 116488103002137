import { editor, Uri } from 'monaco-editor';

export function getOrCreateModel(value: string, language: string, path: string) {
  return getModel(path) || createModel(value, language, path);
}

function getModel(path: string) {
  return editor.getModel(createModelUri(path));
}

function createModel(value: string, language: string, path: string) {
  return editor.createModel(value, language, createModelUri(path));
}

function createModelUri(path: string) {
  return Uri.parse(path);
}
