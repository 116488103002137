import { EditorState, Transaction } from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';
import { EXTERNAL_NODE_TAG, INNER_TRANSACTION_META } from '@components/markdownEditor/const';
import { docForEach } from '@components/markdownEditor/utils/docForEach';

export function blockDeleteProtection(transaction: Transaction, state: EditorState): Transaction {
  if (transaction.getMeta(INNER_TRANSACTION_META)) {
    return transaction;
  }
  const filteredSteps = transaction.steps.filter((step) => {
    let containsCustomBlock = false;
    if (step instanceof ReplaceStep) {
      docForEach(
        transaction.before,
        (node) => {
          if (node.type.name === EXTERNAL_NODE_TAG) {
            containsCustomBlock = true;
            return false;
          }
          return true;
        },
        step.from,
        step.to
      );
    }
    return !containsCustomBlock;
  });
  if (filteredSteps.length !== transaction.steps.length) {
    const newTransaction = state.tr;
    filteredSteps.forEach((step) => {
      newTransaction.step(step);
    });
    return newTransaction;
  }
  return transaction;
}
