import React from 'react';
import { Modal as SmwbModal, ModalProps as MuiModalProps } from '@smwb/summer-ui';
import { CloseIcon } from '@ui/icons';
import s from './modal.module.less';

export interface ModalProps extends MuiModalProps {
  children?: React.ReactNode;
  disableCloseButton?: boolean;
}

export function Modal({ children, backdrop = 'darkBlured', onClose, disableCloseButton = false, ...rest }: ModalProps) {
  return (
    <SmwbModal onClose={onClose} backdrop={backdrop} {...rest}>
      {onClose && !disableCloseButton && (
        <button onClick={onClose} className={s.closeButton}>
          <CloseIcon />
        </button>
      )}
      {children}
    </SmwbModal>
  );
}

Modal.Header = SmwbModal.Header;
Modal.Content = SmwbModal.Content;
Modal.Footer = SmwbModal.Footer;
