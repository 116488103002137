import { Attribute, ContextCreateElements } from './graphElementResult';
import { MxCell } from '../../types/mxCell';
import find from 'lodash/find';

export function createElementEdgeCell(this: ContextCreateElements, attributes: Attribute[]): MxCell {
  const attributeStyle = find(attributes, { type: 'style' })?.value;

  const edge = new this.mxCell(
    '',
    new this.mxGeometry(0, 0, 0, 0),
    attributeStyle || 'endArrow=classic;edgeStyle=orthogonalEdgeStyle;html=1;jettySize=auto;orthogonalLoop=1;'
  );
  if (edge.geometry) {
    edge.geometry.setTerminalPoint(new this.mxPoint(0, 0), true);
    edge.geometry.setTerminalPoint(new this.mxPoint(100, 0), false);
    edge.geometry.relative = true;
  }
  edge.edge = true;

  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    if (attr.type === 'style') continue;

    this.graph.setAttributeForCell(edge, attr.type, attr.value);
  }

  return edge;
}
