import { Observable } from '@utils/observable';
import { JsonDescription, ModelType } from '@baseModel/types/jsonDescription';

export const defaultJsonObject: JsonDescription = {
  metamodel: {
    [ModelType.entities]: {},
    [ModelType.relations]: {}
  },
  model: {
    [ModelType.entities]: [],
    [ModelType.relations]: []
  },
  document: []
};

export const editorObservable = new Observable<JsonDescription>('yaml_editor_observer', defaultJsonObject);
