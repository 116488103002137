import { useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { Sender } from '@utils/observable';
import { DataStoreName } from '../utils/dataJuggler';
import { JsonField, JsonSource } from '../types/jsonDescription';
import { useModel } from './useModel';
import { Models } from '../engine/types';
import { SimpleValueType } from '../types/simpleValueType';
import { Entity } from '../model/entity';

export function useFieldBasic(
  modelType: Models,
  modelName: string,
  fieldName: string,
  dataStoreName: DataStoreName,
  sender?: Sender
): {
  value: SimpleValueType | JsonSource | undefined;
  setValue:
    | typeof Entity.prototype.setFieldValue
    | typeof Entity.prototype.setRelationValue
    | typeof Entity.prototype.setCommonValue;
} {
  const model = useModel(modelName, modelType);
  const [value, setFieldValue] = useState(model?.getValue(dataStoreName, fieldName));

  useEffect(() => {
    return model?.subscribeData(
      dataStoreName,
      fieldName,
      (newValue: JsonField | undefined) => {
        setFieldValue(newValue);
      },
      sender
    );
  }, [model, fieldName, dataStoreName, sender]);

  useEffect(() => {
    if (!model) {
      return;
    }
    setFieldValue(model.getValue(dataStoreName, fieldName));
  }, [model, fieldName, dataStoreName]);

  if (!model) {
    return {
      value: undefined,
      setValue: noop
    };
  }

  const setValue =
    dataStoreName === DataStoreName.fields
      ? model.setFieldValue.bind(model)
      : dataStoreName === DataStoreName.relations
      ? model.setRelationValue.bind(model)
      : model.setCommonValue.bind(model);
  return {
    value,
    setValue
  };
}

export function useField(modelType: Models, modelName: string, fieldName: string, sender?: Sender) {
  return useFieldBasic(modelType, modelName, fieldName, DataStoreName.fields, sender);
}

export function useCommon(modelType: Models, modelName: string, fieldName: string, sender?: Sender) {
  return useFieldBasic(modelType, modelName, fieldName, DataStoreName.common, sender);
}

export function useRelation(modelType: Models, modelName: string, fieldName: string, sender?: Sender) {
  return useFieldBasic(modelType, modelName, fieldName, DataStoreName.relations, sender);
}
