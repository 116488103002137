// Обработка изменения порядка сортировки блоков
import { Node } from 'prosemirror-model';
import { ReplaceStep } from 'prosemirror-transform';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_NODE_TAG } from '@components/markdownEditor/const';
import { Document, MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';
import { BlockType } from '@baseModel/document/blocks/types';
import { Transaction } from 'prosemirror-state';
import { docForEach } from '@components/markdownEditor/utils/docForEach';

/**
 * Обрабатываем транзакцию изменения сортировки блоков
 * @param transaction
 * @param document
 * @constructor
 * @return: true - продолжать транзакцию, false - нет
 */
export function moveNodes(transaction: Transaction, document: Document): boolean {
  // TODO при перемещении таблиц надо еще двигать блоки MARKDOWN_NEW_BLOCK_ID, пока не реализовано
  // Поищем наш блок в шагах, что бы узнать его новую позицию
  const movedNodes: Node[] = [];
  transaction.steps.forEach((el) => {
    if (el instanceof ReplaceStep) {
      el.slice.content.forEach((sliceNode) => {
        if (
          (sliceNode.type.name === DOCUMENT_BLOCK_TAG || sliceNode.type.name === EXTERNAL_NODE_TAG) &&
          sliceNode.attrs.id !== MARKDOWN_NEW_BLOCK_ID
        ) {
          movedNodes.push(sliceNode);
        }
      });
    }
  });
  if (!movedNodes.length) {
    console.warn(`Непредусмотренная ситуация с перестановкой блоков`, {
      transaction
    });
    return false;
  }
  let newPosition: number | undefined;
  let found = false;
  docForEach(transaction.doc, (child) => {
    if (child.attrs.id !== MARKDOWN_NEW_BLOCK_ID) {
      newPosition = newPosition === undefined ? 0 : newPosition + 1;
    } else {
      return true;
    }
    const foundNode = movedNodes.find((el) => el.attrs.id === child.attrs.id);
    // не участвующие блоки игнорируем
    if (foundNode) {
      const childInEngine = document.getBlockById(foundNode.attrs.id as string, foundNode.attrs.type as BlockType);
      if (!childInEngine) {
        console.warn(`Блок редактора ${child} не найден в модели`);
      } else {
        if (newPosition !== undefined) {
          document.setBlockSortIndex(foundNode.attrs.id as string, newPosition, {});
          found = true;
          return false;
        }
      }
    }
    return true;
  });
  if (!found) {
    console.warn(`Не нашли блок для смены позиции`, transaction);
    return false;
  }
  return true;
}
