import { Engine } from '@baseModel/engine/engine';
import { DataStoreName } from '@baseModel/utils/dataJuggler';

const engine = Engine.getInstance();

export enum FieldType {
  Text = 'text',
  Toggle = 'toggle',
  Select = 'select'
}

export interface FieldConfig {
  isRequired: boolean;
}

export interface BaseValues {
  key: string;
  title: string;
  config: FieldConfig;
}

export interface SimpleFieldColumn extends BaseValues {
  type: FieldType.Text | FieldType.Toggle;
}

export interface OptionsSelectFieldColumn {
  key: string;
  name: string;
}

export interface SelectFieldOptions {
  [DataStoreName.fields]: () => OptionsSelectFieldColumn[];
  [DataStoreName.relations]: () => OptionsSelectFieldColumn[];
}

export interface SelectFieldColumn extends BaseValues {
  type: FieldType.Select;
  options: SelectFieldOptions;
}

export type FieldColumn = (SimpleFieldColumn | SelectFieldColumn)[];

export const fieldColumns: FieldColumn = [
  { key: 'display-name', title: 'Отображаемое имя', type: FieldType.Text, config: { isRequired: true } },
  {
    key: 'type',
    title: 'Тип',
    type: FieldType.Select,
    config: { isRequired: true },
    options: {
      [DataStoreName.fields]: () => [
        { key: 'integer', name: 'Число' },
        { key: 'string', name: 'Строка' }
      ],
      [DataStoreName.relations]: () => {
        const metaEntityNames = engine.getEntityMetaModelsNames();

        return metaEntityNames.map((entityName) => {
          const entity = engine.getMetaEntityByName(entityName);

          return {
            key: entityName,
            name: entity.getDisplayName() ?? entityName
          };
        });
      }
    }
  },
  { key: 'primary-key', title: 'Основной ключ', type: FieldType.Toggle, config: { isRequired: false } },
  { key: 'required', title: 'Обязательность', type: FieldType.Toggle, config: { isRequired: false } },
  { key: 'unique', title: 'Уникальность', type: FieldType.Toggle, config: { isRequired: false } },
  { key: 'description', title: 'Описание', type: FieldType.Text, config: { isRequired: false } }
];
