import React from 'react';
import { Models } from '@baseModel/engine/types';
import { useModel } from './useModel';
import isEqual from 'lodash/isEqual';

export function useFields(modelType: Models, modelName: string) {
  const model = useModel(modelName, modelType);
  const [commonFieldNames, setCommonFieldNames] = React.useState(model?.getCommonNames() || []);
  const [fieldNames, setFieldNames] = React.useState(model?.getFieldNames() || []);
  const [relationFieldNames, setRelationFieldNames] = React.useState(model?.getRelationNames() || []);

  React.useEffect(() => {
    if (model === undefined) {
      return;
    }

    return model.subscribeModel(() => {
      const common = model.getCommonNames();
      const fields = model.getFieldNames();
      const relations = model.getRelationNames();

      if (!isEqual(common, commonFieldNames)) {
        setCommonFieldNames(common);
      }

      if (!isEqual(fields, fieldNames)) {
        setFieldNames(fields);
      }

      if (!isEqual(relations, relationFieldNames)) {
        setRelationFieldNames(relations);
      }
    });
  }, [model, commonFieldNames, fieldNames, relationFieldNames]);

  return {
    commonFieldNames,
    fieldNames,
    relationFieldNames
  };
}
