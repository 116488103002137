import { AnyObject } from '@baseModel/utils/dataJuggler';

export function replaceKeys(obj: AnyObject, replacer: (key: string) => string): AnyObject {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const newObj: AnyObject = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    const newKey = replacer(key);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    newObj[newKey] = replaceKeys(obj[key], replacer);
  });

  return newObj;
}

export function getJSON<T>(json: string | T): T {
  return typeof json === 'string' ? (JSON.parse(json) as T) : json;
}
