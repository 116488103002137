import { parse } from 'yaml';
import * as monaco from 'monaco-editor';
import isEmpty from 'lodash/isEmpty';
import { Observable } from '@utils/observable';
import { TextModel } from '../../types';
import { addText } from '../../utils/addText';
import { updateText } from '../../utils/updateText';
import { deleteText } from '../../utils/deleteText';
import { insertText } from '../../utils/insertText';
import { JsonDescription } from '@baseModel/types/jsonDescription';
import { EmitType, ModelPaths } from '@components/yamlEditor/plugins/yamlBind/types';
import { getPath } from '@utils/getPath';
import type { Paths, TargetPath } from '@commonTypes/index';
import { getPathInEditorByValuePath } from '@components/yamlEditor/utils/getPathInEditorByValuePath';

type Options = {
  type: EmitType;
  modelPath: ModelPaths;
  targetPath: TargetPath;
  data?: unknown;
};

const yamlEditorChange = new Observable<Options>('yaml_editor_change');

export function emitYaml(type: EmitType, modelPath: ModelPaths, targetPath: TargetPath, data?: unknown) {
  const options: Options = {
    type,
    modelPath,
    targetPath
  };

  switch (type) {
    case EmitType.Add:
    case EmitType.Update:
    case EmitType.Insert:
      options.data = data;

      break;
  }

  yamlEditorChange.setValue(options);
}

// Обработка внутренних событий на изменение содержимого редактора
export function editorInternalChangesSubscriber(model: TextModel, context?: monaco.editor.IContextKey) {
  const onChange = ({ type, modelPath, targetPath, data }: Options) => {
    let path: Paths;
    switch (modelPath) {
      case ModelPaths.Entities:
      case ModelPaths.Relations: {
        if (type === EmitType.Add && isEmpty(targetPath)) {
          path = getPath(modelPath, targetPath);

          break;
        }

        const parseYaml = parse(model.getValue()) as JsonDescription;
        const foundPath = getPathInEditorByValuePath(modelPath, targetPath, parseYaml);
        if (!foundPath) {
          console.error('path nor found', modelPath, targetPath);
          return;
        }
        path = foundPath;

        break;
      }
      default:
        path = getPath(modelPath, targetPath);

        break;
    }

    context?.set(true);

    switch (type) {
      case EmitType.Add: {
        addText(model, path, data);
        break;
      }
      case EmitType.Update: {
        updateText(model, path, data);
        break;
      }
      case EmitType.Remove: {
        deleteText(model, path);
        break;
      }
      case EmitType.Insert: {
        insertText(model, path, data);
        break;
      }
    }

    context?.set(false);
  };

  return yamlEditorChange.subscribe(onChange);
}
