import React from 'react';
import { SelectField, SelectFieldProps } from '@smwb/summer-ui';
import { Models } from '@baseModel/engine/types';
import { DataJuggler, DataStoreName } from '@baseModel/utils/dataJuggler';
import { Sender } from '@utils/observable';
import { useFieldBasic } from '@baseModel/hooks/useField';
import { JsonField, JsonSource } from '@baseModel/types/jsonDescription';
import { SimpleValueType } from '@baseModel/types/simpleValueType';

export interface SelectEntityFieldProps extends SelectFieldProps {
  modelType: Models;
  modelName: string;
  fieldName: string;
  dataStoreName: DataStoreName;
  fieldPart?: string;
  sender?: Sender;
}

export function SelectEntityField({
  modelType,
  modelName,
  fieldName,
  dataStoreName,
  fieldPart,
  sender,
  ...rest
}: SelectEntityFieldProps) {
  const { value, setValue } = useFieldBasic(modelType, modelName, fieldName, dataStoreName, sender);
  let fieldValue: string | number | boolean | undefined;

  if (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel) {
    if (fieldPart) {
      fieldValue = (value as JsonField | JsonSource)?.[fieldPart];
    } else {
      throw new Error('fieldPart is required for EntityMetaModel and RelationMetaModel');
    }
  } else {
    fieldValue = value as SimpleValueType;
  }

  // TODO Material SelectField: при установки нового значения выбранная отображаемая опция не меняется.
  return (
    <SelectField
      {...rest}
      value={fieldValue as string}
      onChange={(_, { value: currentValue }) => {
        const setField =
          dataStoreName === DataStoreName.fields
            ? (setValue as typeof DataJuggler.prototype.setFieldValue)
            : (setValue as typeof DataJuggler.prototype.setRelationValue);

        if (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel) {
          setField(
            fieldName,
            {
              ...(value as JsonField | JsonSource),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [fieldPart!]: currentValue
            },
            sender
          );
        } else {
          setField(fieldName, currentValue);
        }
      }}
    />
  );
}
