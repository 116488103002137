import { DrawioContext } from '../types/window';
import { MxCell } from '../types/mxCell';
import { getModelValues } from '@hooks/useDrawioModel';
import { Models } from '@baseModel/engine/types';
import { ID } from './graphElements/graphElementResult';

export function getTooltipForCellOverridePlugin(this: DrawioContext) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const getTooltipForCell: (cell: MxCell) => string = this.Graph.prototype.getTooltipForCell;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  this.Graph.prototype.getTooltipForCell = (cell: MxCell) => {
    let tooltip = getTooltipForCell.apply(this.EditorUi.editor.graph, [cell]);

    if (cell.edge) {
      const _id = cell.getAttribute(ID, null);
      if (_id) {
        const value = getModelValues(Models.Relation, _id);

        if (value.edge.source || value.edge.target)
          tooltip += `<div style="margin-top: 10px;"><b>${value.edge.source?.name || '-'}</b> (${
            value.edge.source?.id || '-'
          }) <span style="font-size: 16px">&#8594;</span> <b>${value.edge.target?.name || '-'}</b> (${
            value.edge.target?.id || '-'
          })</div>`;
      }
    }

    return tooltip;
  };
}
