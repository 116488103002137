import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';

let root: ReactDOM.Root;

function start() {
  root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

export function restart() {
  root.unmount();
  start();
}

start();
