import { FallbackProps } from 'react-error-boundary';
import { Button, Card } from '@smwb/summer-ui';
import React from 'react';
import { restart } from '../../index';

export function FallbackRender({ error }: FallbackProps) {
  return (
    <div role="alert">
      <Card>
        <p>Something went wrong:</p>
        <pre style={{ color: 'red' }}>{(error as Error).message}</pre>
        <pre style={{ color: 'red' }}>{(error as Error).stack}</pre>
        <Button type="button" onClick={restart}>
          Попробовать починить
        </Button>
      </Card>
    </div>
  );
}
