import { Relation } from '@baseModel/model/relation';

export function getEnds(relationModel: Relation) {
  const relationMetaModel = relationModel.getMetaModel();
  const relationMetaModelRelationNames = relationMetaModel.getRelationNames();

  let metaSourceName: string | undefined;
  let metaTargetName: string | undefined;

  for (const name of relationMetaModelRelationNames) {
    const value = relationMetaModel.getRelationValue(name);

    if (!value?.target && metaSourceName === undefined) {
      metaSourceName = name;
    } else if (
      ((typeof value?.target === 'boolean' && value.target) || metaSourceName !== name) &&
      metaTargetName === undefined
    ) {
      metaTargetName = name;
    }

    if (metaSourceName !== undefined && metaTargetName !== undefined) {
      break;
    }
  }

  const sourceValue = metaSourceName === undefined ? undefined : relationModel.getRelationValue(metaSourceName);
  const targetValue = metaTargetName === undefined ? undefined : relationModel.getRelationValue(metaTargetName);

  return {
    metaSourceName,
    metaTargetName,
    sourceValue,
    targetValue
  };
}
