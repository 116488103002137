import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { Engine } from '@baseModel/engine/engine';
import { EventEditorMetaModelObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { JsonMetaEntity } from '@baseModel/types/jsonDescription';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import difference from 'lodash/difference';
import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';

const engine = Engine.getInstance();

export function editorMetaEntitiesListener(event: EventEditorMetaModelObserver<JsonMetaEntity>) {
  const {
    changes: { after },
    meta
  } = event;

  if (meta.deleted) {
    engine.removeEntityMetaModel(meta.name, EDITOR_SENDER);
    return;
  }

  let isNew = false;
  let metaEntity: EntityMetaModel | undefined;
  try {
    metaEntity = engine.getMetaEntityByName(meta.name);
  } catch (e) {
    isNew = true;
    metaEntity = new EntityMetaModel(meta.name);
  }

  if (!after) {
    return;
  }

  const oldCommons = metaEntity.getCommonNames();
  const oldFields = metaEntity.getFieldNames();

  const newCommons = Object.keys(omit(after, ['fields', 'ends']));
  const newFields = Object.keys(after.fields ?? {});

  const commonsDeleted = difference(oldCommons.map(kebabCase), newCommons);
  const fieldsDeleted = difference(oldFields, newFields);

  commonsDeleted.forEach((el) => metaEntity?.removeCommon(el, EDITOR_SENDER));
  fieldsDeleted.forEach((el) => metaEntity?.removeField(el, EDITOR_SENDER));

  for (const fieldName of Object.keys(after ?? {})) {
    if (fieldName === 'fields') {
      continue;
    }
    if (metaEntity.getCommonValue(camelCase(fieldName)) === after[<keyof JsonMetaEntity>fieldName]) {
      continue;
    }
    try {
      if (after[<keyof JsonMetaEntity>fieldName] === undefined) {
        metaEntity.removeCommon(camelCase(fieldName), EDITOR_SENDER);
      } else {
        metaEntity.setCommonValue(
          camelCase(fieldName),
          after[<keyof Omit<JsonMetaEntity, 'fields'>>fieldName],
          EDITOR_SENDER
        );
      }
    } catch (e) {
      console.error(e);
      return;
    }
  }
  const fields = after?.fields;
  if (fields) {
    for (const field in fields) {
      if (isEqual(metaEntity.getFieldValue(field), fields[field])) {
        continue;
      }
      try {
        if (fields[field] === undefined) {
          metaEntity.removeField(field, EDITOR_SENDER);
        } else {
          metaEntity.setFieldValue(field, fields[field], EDITOR_SENDER);
        }
      } catch (e) {
        console.error(e);
        return;
      }
    }
  }

  if (isNew) {
    engine.addEntityMetaModel(metaEntity, EDITOR_SENDER);
  }
}
