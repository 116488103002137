import { useState, useRef, useCallback } from 'react';
declare type ReadOnlyRefObject<T> = {
  readonly current: T;
};

export function useStateRef<S>(initialState: S | (() => S)): [S, (setStateAction: S) => void, ReadOnlyRefObject<S>] {
  const [state, setState] = useState<S>(initialState);
  const ref = useRef<S>(state);
  const dispatch = useCallback(function (setStateAction: S) {
    ref.current = setStateAction;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
}
