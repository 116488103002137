import { Properties } from '../eventDifference/eventDifferenceResults';
import { MxCell, MxCellClass } from '../../types/mxCell';
import { MxGeometryClass } from '../../types/mxGeometry';
import { Graph, Sidebar } from '../../types/draw';
import { MxPointClass } from '../../types/mxPoint';
import { EngineEvent, EngineEventData, Models } from '@baseModel/engine/types';
import { ModelUpdateData } from '@baseModel/utils/dataJuggler';

export type GraphElement = GraphElementResultVertex | GraphElementResultEdge;

export const ID = '_id';
export const SenderBaseModel = 'Draw.io - ';

export interface GraphElementResult {
  id: string;
  _id: string | null;
  label: string;
  attributes: Properties;
  styles: Properties;
  type: GraphElementResultTypes;
}

export interface GraphElementResultVertex extends GraphElementResult {
  edges: GraphElementResultEdge[];
}

export interface GraphElementResultEdge extends GraphElementResult {
  edge: {
    source: GraphElementResult | null;
    target: GraphElementResult | null;
  };
}

export enum GraphElementResultTypes {
  vertex = 'vertex',
  edge = 'edge'
}

export interface GraphContextGetElements {
  getLabel: (cell: MxCell) => string;
}

export interface ContextCreateElements {
  mxCell: MxCellClass;
  mxGeometry: MxGeometryClass;
  mxPoint: MxPointClass;
  graph: Graph;
}

export interface SidebarContextCreateElements extends ContextCreateElements {
  sidebar: Sidebar;
}

export interface CreateSidebarElement {
  type: GraphElementResultTypes;
  attributes: Attribute[];
}

export interface Attribute {
  type: string;
  value: string;
}

export interface End {
  id: string;
  name: string;
  key: string;
}

export interface Edge {
  source: End | null;
  target: End | null;
}

export interface CreateElement extends CreateSidebarElement {
  edge: Edge;
}

export interface ChangeModel {
  model: Models;
  modelKey: string;
  value: ModelUpdateData;
}

export interface ChangeEngineModel {
  model: Models;
  modelKey: string;
  value: EngineEvent<EngineEventData>;
}
