import React from 'react';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { useModels } from '@baseModel/hooks/useModels';
import { EntityRelation, Table } from '../table/table';
import s from './expandTable.module.less';
import { ShowLink } from '@baseModel/document/blocks/table';
import { Relation } from '@baseModel/model/relation';
import { useTable } from '@baseModel/document/hooks/useTable';

export interface ExpandTableProps {
  configId: string;
  parentModelId: string;
  inheritModelId?: string;
  depth: number;
}

const engine = Engine.getInstance();

function useGetShowLink(tableId: string, depth: number): ShowLink | undefined {
  const [tableConfig] = useTable(tableId);
  let showLink = tableConfig?.showLink;
  while (showLink && depth > 0) {
    showLink = showLink.showLink;
    depth--;
  }
  return showLink;
}

export function ExpandTable({ configId, parentModelId, inheritModelId, depth }: ExpandTableProps) {
  const showLink = useGetShowLink(configId, depth);
  const { relation, endSelf, endTarget, showLink: childShowLink } = showLink || {};
  const relationIds = useModels(Models.Relation, relation);
  if (showLink === undefined || relation === undefined || endSelf === undefined) {
    return <div>showLink not found</div>;
  }
  const metaRelation = engine.getMetaRelationByName(relation);
  const endSelfValue = metaRelation.getRelationValue(endSelf);
  const endTargetValue = endTarget ? metaRelation.getRelationValue(endTarget) : undefined;
  if (endSelfValue === undefined) {
    return (
      <div>
        The `{endSelfValue}` field was not found in the relations of the `{relation}` metamodel.
      </div>
    );
  }

  const models: EntityRelation[] = [];
  const relationModels: Relation[] = [];
  for (const relationId of relationIds) {
    try {
      const relationModel = engine.getRelationById(relationId);
      const endSelfId = relationModel.getRelationValue(endSelf);
      if (endSelfId === undefined) {
        continue;
      }

      if (endSelfId === parentModelId) {
        if (endTarget && endTargetValue) {
          const endTargetId = relationModel.getRelationValue(endTarget);

          if (endTargetId === undefined) {
            continue;
          }

          const entity = engine.getEntityById(String(endTargetId));

          if (entity.getMetaModel().getName() === endTargetValue.type) {
            models.push(new EntityRelation(relationId, entity));
          }
        } else {
          relationModels.push(relationModel);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Table
      className={s.table}
      rowType={endTargetValue?.type || relation}
      modelType={endTarget ? Models.Entity : Models.Relation}
      configId={configId}
      columns={showLink.table.columns}
      showLink={childShowLink}
      models={endTargetValue ? models : relationModels}
      title={`Связь ${relation} ${metaRelation.getDisplayName()} ${endTarget ? Models.Entity : Models.Relation}`}
      modelId={parentModelId}
      nestedTable={{
        relation,
        endSelfName: endSelf,
        endTargetName: endTarget
      }}
      hiddenModelId={inheritModelId}
      depth={depth + 1}
    />
  );
}
