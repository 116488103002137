import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@smwb/summer-ui';
import { useBooleanState } from '@hooks/useBooleanState';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { ProseMirrorView } from '@components/markdownEditor/proseMirrorView';
import { EngineLoader } from '@baseModel/engine/engineLoader';
import { useAppDispatch } from '../../../redux/store';
import { highlightDocumentBlockById } from '@components/markdownEditor/redux/markdownEditor';
import { ContentItem } from './contentItem';
import { useHeadings } from './useHeadings';
import s from './contentList.module.less';

export interface ContentListProps {
  proseMirror: ProseMirrorView | null;
}

export function ContentList({ proseMirror }: ContentListProps) {
  const { isTrue: isOpen, toggleValue } = useBooleanState(true);
  const [searchParams] = useSearchParams();
  const isTablet = useMediaQuery('(max-width: 768.1px)');
  const dispatch = useAppDispatch();
  const headings = useHeadings(proseMirror);
  const blockId = searchParams.get('blockId');

  useEffect(() => {
    if (!blockId) {
      return;
    }

    const unsubscribe = EngineLoader.onModelLoaded(() => {
      dispatch(highlightDocumentBlockById(blockId));
    });

    return () => {
      unsubscribe();
    };
  }, [blockId, dispatch]);

  if (isTablet) {
    return null;
  }

  return (
    <>
      <div className={s.root}>
        <Button
          className={s.iconButton}
          icon={isOpen ? 'collapse_content' : 'toc'}
          variant="outlined"
          onClick={toggleValue}
        />
        {isOpen && (
          <div className={s.card}>
            <div className={s.cardHeader}>Содержание</div>
            <ul className={s.cardList}>
              {headings.map(({ id, text, level }, index) => (
                <ContentItem key={`${id}-${index}`} id={id} text={text} level={level} />
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
