import { useCallback, useState } from 'react';

export const useBooleanState = (init = false) => {
  const [isTrue, setIsTrue] = useState(init);

  const setTrue = useCallback(() => {
    setIsTrue(true);
  }, []);

  const setFalse = useCallback(() => {
    setIsTrue(false);
  }, []);

  const toggleValue = useCallback(() => {
    setIsTrue((prev) => !prev);
  }, []);

  return { isTrue, setTrue, setFalse, toggleValue };
};
