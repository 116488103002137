import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Models } from '@baseModel/engine/types';
import { ActionType, NestedTable } from '../types';

export interface ActionsState {
  type?: ActionType;
  modelId?: string;
  modelType?: Models.Entity | Models.Relation;
  metaModelName?: string;
  tableId?: string;
  nestedTable?: NestedTable;
}

const initialState: ActionsState = {
  type: undefined,
  modelId: undefined,
  modelType: undefined,
  metaModelName: undefined,
  tableId: undefined,
  nestedTable: undefined
};

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ActionsState>) => {
      state.type = action.payload.type;
      state.modelId = action.payload.modelId;
      state.modelType = action.payload.modelType;
      state.metaModelName = action.payload.metaModelName;
      state.tableId = action.payload.tableId;
      state.nestedTable = action.payload.nestedTable;
    },

    clear: () => initialState
  }
});

export const { set, clear } = actionsSlice.actions;

export default actionsSlice.reducer;
