import { highlightModelFieldByPath as highlightModelFieldByPathAction } from '@components/yamlEditor/redux/yamlEditor';
import { Models } from '@baseModel/engine/types';
import { getPath } from '@utils/getPath';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import { store } from '../../../../redux/store';
import { modelPathsMapping } from '@components/yamlEditor/const';

export function highlightModelFieldByPath(
  modelType: Models,
  modelName: string,
  dataStoreName: DataStoreName,
  fieldName: string
) {
  const modelPath = modelPathsMapping[modelType];
  const targetPath = modelType === Models.Relation ? [modelName, dataStoreName, fieldName] : [modelName, fieldName];
  const path = getPath(modelPath, targetPath);
  store.dispatch(highlightModelFieldByPathAction(path));
}
