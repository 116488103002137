import { EditorView } from 'prosemirror-view';
import { DOMNode } from 'prosemirror-view/src/dom';
import { EditorState, TextSelection } from 'prosemirror-state';
import { DOMSerializer } from 'prosemirror-model';
import { schema as markdownSchema, defaultMarkdownSerializer } from 'prosemirror-markdown';

import { markdownSchemaWithExtraNodes } from './utils/markdownSchemaWithExtraNodes';
import { markdownExtendParser } from './utils/markdownExtendParser';
import { baseModelCall } from './modelBind/baseModelCall';
import { RESOLVE_POSITION_META } from './const';
import { pluginsSet } from '@components/markdownEditor/plugins/pluginsSet';
import { Listener, Observable } from '@utils/observable';
import { EmptyObject } from '@baseModel/utils/dataJuggler';
import { blockDeleteProtection } from '@components/markdownEditor/modelBind/utils/blockDeleteProtection';

export class ProseMirrorView {
  private readonly editor: EditorView;
  private readonly topMenuOnUpdate = new Observable<EmptyObject>('topMenuOnUpdate');

  constructor(target: DOMNode, content: string) {
    // debugger;
    const doc = markdownExtendParser.parse(content);
    if (doc === null) {
      throw new Error('defaultMarkdownParser.parse(content) is null');
    }
    // debugger;
    const view = new EditorView(target, {
      state: EditorState.create({
        doc,
        plugins: pluginsSet({
          schema: markdownSchemaWithExtraNodes,
          topMenuOnUpdate: () => {
            this.topMenuOnUpdate.setValue({});
          }
        })
      }),
      dispatchTransaction(transaction) {
        const protectedTransaction = blockDeleteProtection(transaction, view.state);
        const callResult = baseModelCall(view, protectedTransaction);
        if (!callResult) {
          return;
        }

        let newState = view.state.apply(protectedTransaction);
        const resolvePosition = protectedTransaction.getMeta(RESOLVE_POSITION_META) as number | undefined;
        if (resolvePosition) {
          const $anchor = newState.doc.resolve(resolvePosition);
          const selection = new TextSelection($anchor);
          const selectionTransaction = protectedTransaction.setSelection(selection);
          newState = view.state.apply(selectionTransaction);
        }
        view.updateState(newState);
      }
    });

    this.editor = view;
  }

  public getHTML() {
    const div = document.createElement('div');
    const fragment = DOMSerializer.fromSchema(markdownSchema).serializeFragment(this.editor.state.doc.content);
    div.appendChild(fragment);
    return div.innerHTML;
  }

  public get content() {
    return defaultMarkdownSerializer.serialize(this.editor.state.doc);
  }

  public focus() {
    this.editor.focus();
  }

  public destroy() {
    this.editor.destroy();
  }

  public getEditor() {
    return this.editor;
  }

  public getState() {
    return this.editor.state;
  }

  public getDispatch() {
    return this.editor.dispatch.bind(this.editor);
  }

  public topMenuOnUpdateSubscribe(listener: Listener<EmptyObject>) {
    return this.topMenuOnUpdate.subscribe(listener);
  }
}
