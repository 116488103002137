import { Transaction } from 'prosemirror-state';
import { ReplaceAroundStep, ReplaceStep } from 'prosemirror-transform';
import { DOCUMENT_BLOCK_TAG } from '@components/markdownEditor/const';

/**
 * Проверяет, не пытается ли редактор вставить новый блок самостоятельно
 * @param transaction
 * @return boolean: true - если можно продолжать транзакцию
 */
export function newBlockTypeCheck(transaction: Transaction): boolean {
  // Если транзакция не изменяет документ, пропускаем фильтрацию.
  if (!transaction.docChanged) {
    return true;
  }
  for (const step of transaction.steps) {
    if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
      step.slice.content.forEach((node) => {
        if (node.type.name === DOCUMENT_BLOCK_TAG) {
          console.warn('Редактор пытается вставить новый блок ' + node.type.name + ' сам');
          return false;
        }
      });
    }
  }
  return true;
}
