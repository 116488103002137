import { Models } from '@baseModel/engine/types';
import { Button, SelectField, SelectFieldProps, Toggle, ToggleProps } from '@smwb/summer-ui';
import React, { useState } from 'react';
import { TableColumn } from './tableConfigurationForm';
import st from './insertTableSheet.module.less';
import { getRelationEndsItems, getRelationModelsItems } from './utils';
import { ColumnsPreview } from '@components/tableConfiguration/columnsPreview/columnsPreview';
import { Engine } from '@baseModel/engine/engine';
import { HierarchyRelation } from '@components/tableConfiguration/hooks/useEditTableData';

interface TableHierarchiesFormProps {
  modelType: Models.Entity | Models.Relation;
  rowType: string;
  columns: TableColumn[];
  setColumns: (columns: TableColumn[]) => void;
  setHierarchy: (newHierarchy?: HierarchyRelation) => void;
  hierarchy?: HierarchyRelation;
  childHierarchyColumns?: TableColumn[];
  setChildHierarchyColumns?: (columns: TableColumn[]) => void;
  setChildHierarchy?: (newHierarchy?: HierarchyRelation) => void;
  childHierarchy?: HierarchyRelation;
}

const engine = Engine.getInstance();

function TableHierarchiesForm({
  modelType,
  setHierarchy,
  hierarchy,
  rowType,
  columns,
  setColumns,
  childHierarchy,
  setChildHierarchy,
  childHierarchyColumns,
  setChildHierarchyColumns
}: TableHierarchiesFormProps) {
  const [showAddHierarchy, setShowAddHierarchy] = useState(false);
  const [showEditingHierarchy, setShowEditingHierarchy] = useState(false);

  const [hierarchyType, setHierarchyType] = useState<Models.Entity | Models.Relation>(Models.Entity);
  const [relation, setRelation] = useState<string | undefined>(hierarchy?.relation);
  const [endSelf, setEndSelf] = useState<string | undefined>(hierarchy?.endSelf);
  const [endTarget, setEndTarget] = useState<string | undefined>(hierarchy?.endTarget);

  const isEntity = modelType === Models.Entity;

  const handleChangeHierarchyType: ToggleProps['onChange'] = (event, data) => {
    setHierarchyType(data.checked ? Models.Relation : Models.Entity);
    setEndTarget(undefined);
  };

  const handleChangeEndSelf = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setEndSelf((data.value as string) || '');
  };

  const handleChangeEndTarget = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setEndTarget((data.value as string) || '');
  };

  const handleChangeRelation = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setEndSelf(undefined);
    setEndTarget(undefined);
    setRelation((data.value as string) || '');
  };

  const handleCloseHierarchyForm = () => {
    if (showEditingHierarchy) {
      setShowEditingHierarchy(false);
    } else if (showAddHierarchy) {
      setShowAddHierarchy(false);
    }
  };

  const handleDeleteHierarchy = () => {
    setHierarchy(undefined);
    setRelation(undefined);
    setEndSelf(undefined);
    setEndTarget(undefined);
    handleCloseHierarchyForm();
  };

  const onShowEditingHierarchy = () => {
    if (showEditingHierarchy) return;
    setShowEditingHierarchy(true);
  };

  const handleOpenAddHierarchy = () => {
    if (showAddHierarchy) return;
    setShowAddHierarchy(true);
  };
  const handleSaveHierarchy = () => {
    if (!endSelf || !relation) {
      console.warn(`!endTarget || !endSelf || !relation`);
      return;
    }
    setHierarchy({ endTarget, endSelf, relation });
    handleCloseHierarchyForm();
  };

  let inheritRowType: string | undefined;

  if (relation && endTarget) {
    try {
      const relationMetaModel = engine.getMetaRelationByName(relation);
      inheritRowType = relationMetaModel.getRelationValue(endTarget)?.type;
    } catch (e) {
      // empty
    }
  } else if (relation) {
    inheritRowType = relation;
  }

  return (
    <>
      <div className={st.hierarchies}>
        {isEntity && hierarchy ? (
          <div className={st.buttonsGroup}>
            <Button icon="account_tree" type="button" onClick={handleDeleteHierarchy}>
              Удалить иерархию
            </Button>
            <Button icon="account_tree" type="button" onClick={onShowEditingHierarchy}>
              Редактировать иерархию
            </Button>
          </div>
        ) : (
          <Button icon="account_tree" type="button" disabled={!isEntity} onClick={handleOpenAddHierarchy}>
            Добавить иерархию
          </Button>
        )}
      </div>

      {(showAddHierarchy || showEditingHierarchy) && (
        <div className={st.hierarchiesForm}>
          <Toggle
            label={hierarchyType}
            onChange={handleChangeHierarchyType}
            checked={hierarchyType === Models.Entity}
          />
          <SelectField
            label={`Relation`}
            items={getRelationModelsItems(rowType)}
            onChange={handleChangeRelation}
            value={relation}
          />
          {relation && (
            <SelectField
              label={`End-self`}
              items={getRelationEndsItems(relation, rowType)}
              onChange={handleChangeEndSelf}
              value={endSelf}
            />
          )}
          {relation && hierarchyType === Models.Entity && (
            <SelectField
              label={`End-target`}
              items={getRelationEndsItems(relation)}
              onChange={handleChangeEndTarget}
              value={endTarget}
            />
          )}
          <div>
            <Button icon="account_tree" type="button" onClick={handleCloseHierarchyForm}>
              Отмена
            </Button>
            <Button icon="account_tree" type="button" onClick={handleSaveHierarchy} disabled={!endSelf}>
              {showEditingHierarchy ? 'Сохранить' : 'Добавить'}
            </Button>
          </div>
        </div>
      )}
      {!showEditingHierarchy && isEntity && hierarchy && inheritRowType && (
        <ColumnsPreview columns={columns} setColumns={setColumns} modelType={hierarchyType} rowType={inheritRowType} />
      )}
      {!showEditingHierarchy &&
        isEntity &&
        hierarchy &&
        inheritRowType &&
        setChildHierarchyColumns &&
        setChildHierarchy && (
          <TableHierarchiesForm
            columns={childHierarchyColumns || []}
            setColumns={setChildHierarchyColumns}
            hierarchy={childHierarchy}
            modelType={hierarchyType}
            rowType={inheritRowType}
            setHierarchy={setChildHierarchy}
          />
        )}
    </>
  );
}

export default TableHierarchiesForm;
