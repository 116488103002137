import { Node } from 'prosemirror-model';
import { docForEach } from '@components/markdownEditor/utils/docForEach';
import { DOCUMENT_BLOCK_TAG } from '@components/markdownEditor/const';
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';

export function getAffectedBlocks(
  beforeDoc: Node,
  afterDoc: Node,
  from: number,
  to: number
): [Node[], Node[], Node[], Node[]] {
  const blocksBefore: Node[] = [];
  const blocksAfter: Node[] = [];
  docForEach(
    beforeDoc,
    (node) => {
      if (node.type.name === DOCUMENT_BLOCK_TAG) {
        blocksBefore.push(node);
      } else {
        console.log('b node.type.name', node.type.name);
      }
      return true;
    },
    from,
    to
  );
  docForEach(
    afterDoc,
    (node) => {
      if (node.type.name === DOCUMENT_BLOCK_TAG) {
        blocksAfter.push(node);
      } else {
        console.log('d node.type.name', node.type.name);
      }
      return true;
    },
    from,
    to
  );

  const deletedBlocks = differenceWith(blocksBefore, blocksAfter, (a, b) => a.attrs.id === b.attrs.id);
  // Возьмем те блоки, которые были и до и после изменений, только они остались в модели
  const blockForUpdate = intersectionWith(blocksBefore, blocksAfter, (a, b) => a.attrs.id === b.attrs.id);

  return [blocksBefore, blocksAfter, deletedBlocks, blockForUpdate];
}
