import { DrawioContext } from '../types/window';

export function previewPlugin(this: DrawioContext) {
  return (previewData?: string) => {
    const mxUtils = this.mxUtils;
    const LocalFile = this.LocalFile;
    const graph = this.EditorUi.editor.graph;

    this.EditorUi.fileLoaded(new LocalFile(this.EditorUi, this.EditorUi.emptyDiagramXml));

    if (previewData) {
      const xml = graph.decompress(previewData);

      if (xml) {
        const xmlDoc = mxUtils.parseXml(xml);

        this.EditorUi.editor.setGraphXml(xmlDoc.documentElement);
      }
    }
  };
}
