import { stringify, parseDocument, isCollection } from 'yaml';
import { TextModel } from '../types';
import { Paths } from '@commonTypes/index';
import { findRange, isPathIndexArray, getWordRegexp, arrayIndicatorRegexp } from './findRange';

export function updateText(model: TextModel, paths: Paths, value: unknown): boolean {
  if (!paths.length) {
    console.error('updateText: Empty path:', paths);
    return false;
  }

  const { foundRange } = findRange(model, paths);

  if (foundRange === undefined) {
    console.error('updateText: Could not be found on path:', paths);
    return false;
  }

  const lastPath = paths.at(-1);

  const rangeScope = foundRange.setEndPosition(
    foundRange.startLineNumber,
    model.getLineMaxColumn(foundRange.startLineNumber)
  );

  const isIndex = isPathIndexArray(lastPath);
  const searchString = isIndex ? arrayIndicatorRegexp : getWordRegexp(lastPath as string);
  const [keyFoundRange] = model.findMatches(searchString, rangeScope, true, true, null, false, 1);
  const range = foundRange.setStartPosition(foundRange.startLineNumber, keyFoundRange.range.endColumn);

  const textValue = stringify(value);
  const textParse = parseDocument(textValue);
  let prefix = '';

  if (!isIndex) {
    prefix = isCollection(textParse.contents) ? '\n' : ' ';
  }

  const startColumn = foundRange.startColumn;
  const text = (prefix + textValue).replace(/\n$/, '').replace(/\n/gm, '\n'.padEnd(startColumn + 2, ' '));

  model.applyEdits([
    {
      range,
      text
    }
  ]);

  return true;
}
