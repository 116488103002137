import { JsonEntity, JsonRelation } from '@baseModel/types/jsonDescription';
import isPlainObject from 'lodash/isPlainObject';

export function getId(el: JsonEntity | JsonRelation): string {
  if (!el || !isPlainObject(el)) {
    return '';
  }

  return '__id' in el ? el.__id : <string>el.fields?.__id;
}
