import { Schema } from 'prosemirror-model';
import { schema as markdownSchema } from 'prosemirror-markdown';
import { DOCUMENT_BLOCK_TAG, EXTERNAL_GROUP, EXTERNAL_NODE_TAG } from '../const';
import { tableNodes } from 'prosemirror-tables';

interface ExternalNodeDomAttrs {
  type: string;
  id: string;
}

export enum ExternalNodeAttributes {
  externalNode = 'external-node',
  externalNodeType = 'external-node-type',
  externalNodeId = 'external-node-id'
}

markdownSchema.spec.nodes = markdownSchema.spec.nodes.append(
  tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
      background: {
        default: null,
        getFromDOM(dom) {
          return dom.style.backgroundColor || null;
        },
        setDOMAttr(value, attrs) {
          if (value) attrs.style = ((attrs.style as string) || '') + `background-color: ${value};`;
        }
      }
    }
  })
);

markdownSchema.spec.nodes = markdownSchema.spec.nodes.addToEnd(EXTERNAL_NODE_TAG, {
  content: 'block*',
  defining: true,
  atom: true,
  draggable: false,
  selectable: false,
  isolating: true,
  attrs: { type: {}, id: {} },
  toDOM(node) {
    return [
      'div',
      {
        class: ExternalNodeAttributes.externalNode,
        [ExternalNodeAttributes.externalNode]: true,
        [ExternalNodeAttributes.externalNodeType]: node.attrs.type as ExternalNodeDomAttrs['type'],
        [ExternalNodeAttributes.externalNodeId]: node.attrs.id as ExternalNodeDomAttrs['id']
      }
    ];
  },
  parseDOM: [
    {
      tag: 'div',
      getAttrs(dom) {
        if (typeof dom === 'string') {
          throw new Error('parseDom error. dom is string');
        }
        return {
          type: dom.getAttribute(ExternalNodeAttributes.externalNodeType),
          id: dom.getAttribute(ExternalNodeAttributes.externalNodeId)
        };
      }
    }
  ]
});

markdownSchema.spec.nodes = markdownSchema.spec.nodes
  .addToEnd(DOCUMENT_BLOCK_TAG, {
    content: `(block | ${EXTERNAL_NODE_TAG})+`,
    group: EXTERNAL_GROUP,
    attrs: { id: {} },
    toDOM(node) {
      return [
        'div',
        {
          class: ExternalNodeAttributes.externalNode,
          [ExternalNodeAttributes.externalNodeType]: node.attrs.type as ExternalNodeDomAttrs['type'],
          [ExternalNodeAttributes.externalNodeId]: node.attrs.id as ExternalNodeDomAttrs['id']
        },
        0
      ];
    },
    parseDOM: [
      {
        tag: 'div',
        getAttrs(dom) {
          if (typeof dom === 'string') {
            throw new Error('parseDom error. dom is string');
          }
          return {
            type: dom.getAttribute(ExternalNodeAttributes.externalNodeType),
            id: dom.getAttribute(ExternalNodeAttributes.externalNodeId)
          };
        }
      }
    ]
  })
  .update('doc', {
    content: `${EXTERNAL_GROUP}*`
  });

export const markdownSchemaWithExtraNodes = new Schema({
  nodes: markdownSchema.spec.nodes,
  marks: markdownSchema.spec.marks,
  topNode: markdownSchema.spec.topNode
});
