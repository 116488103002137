import { MxTerminal } from '../../types/mxTerminalChange';
import { EventDifferenceAs, EventDifferenceResultsTerminal, getTypeCell } from './eventDifferenceResults';
import { ID } from '../graphElements/graphElementResult';
import { getStyleArray } from './eventDifferenceStyles';
import { MxCell } from '@components/drawio/types/mxCell';

export function getEventDifferenceTerminal(change: MxTerminal): EventDifferenceResultsTerminal {
  return {
    id: <string>change.cell.id,
    _id: change.cell.getAttribute(ID, null),
    type: getTypeCell(change.cell),
    as: EventDifferenceAs.terminal,
    edge: {
      isSource: change.source,
      previous: change.previous?.getAttribute(ID, null) || null,
      terminal: change.terminal?.getAttribute(ID, null) || null,
      isArrowEnd: isArrowEnd(change.cell)
    }
  };
}

export function isArrowEnd(cell: MxCell) {
  if (typeof cell?.style === 'string') {
    const styles = getStyleArray(cell.style);

    return styles['endArrow'] !== 'none';
  }

  return false;
}
