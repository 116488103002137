import { Listener, Unsubscriber } from '@utils/observable';
import type { EventEditorDocumentObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { YamlModelState } from '@components/yamlEditor/yamlModelState';
import { JsonDescription, JsonDocumentBlocks, JsonRootBlock } from '@baseModel/types/jsonDescription';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import differenceBy from 'lodash/differenceBy';
import { editorObservable } from '@components/yamlEditor/plugins/yamlBind/editor/editorObservable';

export interface DocumentDiffElement {
  id: string;
  changedSortIndex?: number;
  changedValue?: JsonDocumentBlocks;
  deleted?: boolean;
}

function getDocumentsDiff(
  before: JsonDescription[JsonRootBlock.document],
  after: JsonDescription[JsonRootBlock.document]
): Set<DocumentDiffElement> {
  const diff: Set<DocumentDiffElement> = new Set();
  after.forEach((afterElement, afterSortIndex) => {
    const beforeElement = before[afterSortIndex];
    // На этой позиции изменений нет
    if (isEqual(afterElement, beforeElement)) {
      return;
    }
    const oldIndex = before.findIndex((el) => afterElement.id === el.id);

    // На этой позиции элемент из другой позиции
    if (oldIndex >= 0 && isEqual(afterElement, before[oldIndex])) {
      diff.add({ changedSortIndex: afterSortIndex, id: afterElement.id });
      return;
    }

    // На этой позиции новый элемент или На этой позиции измененный элемент из другой позиции
    diff.add({ changedSortIndex: afterSortIndex, changedValue: afterElement, id: afterElement.id });
  });

  const deleted = differenceBy(before, after, 'id');
  deleted.forEach((el) => {
    diff.add({ deleted: true, id: el.id });
  });

  return diff;
}

export function editorDocumentBlockSubscriber(listener: Listener<EventEditorDocumentObserver>): Unsubscriber {
  return editorObservable.subscribe((newValue) => {
    const before = YamlModelState.getInstance().getDocument();
    const after = newValue[JsonRootBlock.document];
    // Если ввели дубль, проигнорируем
    if (uniqBy(after, 'id').length !== after.length) {
      return;
    }
    YamlModelState.getInstance().setDocument(after);
    // Проверить приходит ли новый объект TODo он всегда новый, т.к. сериализуется, полное сравнение делать дольше пока
    if (after === before) {
      return;
    }

    if (!Array.isArray(after) || !Array.isArray(before)) {
      console.error(`${JsonRootBlock.document} должен быть массив, получено ${before} ${after}`);
      return;
    }
    const diff = getDocumentsDiff(before, after);
    diff.forEach((el) => {
      listener({
        root: JsonRootBlock.document,
        changes: { after: el.changedValue },
        meta: {
          id: el.id,
          changedSortIndex: el.changedSortIndex,
          deleted: el.deleted
        }
      });
    });
  }, EDITOR_SENDER);
}
