import { Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { ExternalNodeAttributes } from '@components/markdownEditor/utils/markdownSchemaWithExtraNodes';
import { MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';
import debounce from 'lodash/debounce';
import { blockHighlightPluginKey } from '@components/markdownEditor/plugins/blockHighlight';
import { BlockControlsActions, blockControlsPluginKey } from '@components/markdownEditor/plugins/blockControls';
import config from '../../../config';
import { highlightDocumentBlockById } from '@components/yamlEditor/plugins/hightLight/highlightDocumentBlockById';

const hoverPluginKey = new PluginKey('hover');

const debouncedHighlightDocumentBlockById = debounce((id: string | undefined) => highlightDocumentBlockById(id), 17);

function findNodeId(element: HTMLElement): string | undefined {
  if (!element.classList.contains(ExternalNodeAttributes.externalNode)) {
    const parent = element.parentElement;
    if (parent) {
      return findNodeId(parent);
    } else {
      return undefined;
    }
  }
  const block = element.attributes.getNamedItem(ExternalNodeAttributes.externalNodeId);
  if (block?.value === MARKDOWN_NEW_BLOCK_ID) {
    return undefined;
  }
  return block?.value || undefined;
}

export const highlightBlockAndSendEventOnClickPlugin = new Plugin({
  key: hoverPluginKey,
  props: {
    handleDOMEvents: {
      mouseup: (view, event) => {
        const highlightColor = config.highlightColor;
        const blockId = findNodeId(event.target as HTMLElement);
        if (event.target instanceof HTMLSpanElement === false) {
          // if (event.target instanceof HTMLInputElement === false) {
          debouncedHighlightDocumentBlockById(blockId);
        }
        let from: number | undefined;
        let to: number | undefined;
        view.state.doc.descendants((node, pos) => {
          if (node.attrs.id === blockId) {
            from = pos;
            to = from + node.nodeSize;
            return false; // Прекратить обход документа
          }
          return true;
        });
        if (from !== undefined && to !== undefined) {
          let tr: Transaction;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (localStorage.mdEditor?.blockHighlight) {
            tr = view.state.tr.setMeta(blockHighlightPluginKey, { add: { from, to, color: highlightColor } });
          } else {
            tr = view.state.tr;
          }
          if (blockId) {
            const blockAddActions: BlockControlsActions = { addIcon: { pos: from }, blockId };
            tr = tr.setMeta(blockControlsPluginKey, blockAddActions);
          }
          view.dispatch(tr);
        }
        return false;
      }
    }
  }
});
