import { MxCell } from '../../types/mxCell';
import { GraphContextGetElements, GraphElementResult, GraphElementResultTypes, ID } from './graphElementResult';
import { getAttributesArray } from '../eventDifference/eventDifferenceValue';
import { getStyleArray } from '../eventDifference/eventDifferenceStyles';

export function getGraphElement(
  this: GraphContextGetElements,
  cell: MxCell,
  type: GraphElementResultTypes
): GraphElementResult {
  return {
    id: <string>cell.id,
    _id: cell.getAttribute(ID, null),
    label: this.getLabel(cell),
    attributes: cell.value ? getAttributesArray(cell.value) : {},
    styles: cell.style ? getStyleArray(cell.style) : {},
    type: type
  };
}
