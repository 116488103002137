import React, { useEffect, useState } from 'react';
import { Tabs as TabsSMWB, Tab, TabProps } from '@smwb/summer-ui';
import s from './tabs.module.less';

export interface TabProp extends TabProps {
  content: React.ReactNode;
  index?: number;
}

export interface TabsProps {
  tabs: TabProp[];
  activeDefault?: number;
  active?: number;

  TabContainer?: ({ children }: DefaultTabContainerProps) => JSX.Element;
  scrollable?: boolean;
}

interface DefaultTabContainerProps {
  children: React.ReactNode;
}

const DefaultTabContainer = ({ children }: DefaultTabContainerProps) => <>{children}</>;

export const Tabs = ({
  tabs,
  activeDefault = 0,
  scrollable = true,
  active,
  TabContainer = DefaultTabContainer
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(active !== undefined ? active : activeDefault);
  useEffect(() => {
    setActiveTab(() => (active !== undefined ? active : activeDefault));
  }, [active, activeDefault]);

  return (
    <>
      <TabsSMWB
        className={s.root}
        value={activeTab}
        scrollable={scrollable}
        onChange={(_, value: number) => setActiveTab(value)}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {tabs.map(({ content, index, ...tabProps }) => (
          <Tab key={tabProps.label} {...tabProps} />
        ))}
      </TabsSMWB>
      <TabContainer>
        {tabs.find((tab, i) => (tab.index !== undefined ? tab.index === activeTab : i === activeTab))?.content || null}
      </TabContainer>
    </>
  );
};
