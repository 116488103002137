import React from 'react';
import { Button, TableCell } from '@smwb/summer-ui';
import s from './table.module.less';

export interface CellActionsProps {
  onDelete: () => void;
  deleteDisabled?: boolean;
}

export function CellActions({ onDelete, deleteDisabled }: CellActionsProps) {
  return (
    <TableCell className={s.cellActions}>
      <div className={s.actions}>
        <Button className={s.iconButton} variant="text" icon="delete" onClick={onDelete} disabled={deleteDisabled} />
      </div>
    </TableCell>
  );
}
