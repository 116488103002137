import { Button, SelectField } from '@smwb/summer-ui';
import { JsonDocumentTableBlockColumn } from '@baseModel/types/jsonDescription';
import { ColumnsPreview } from '@components/tableConfiguration/columnsPreview/columnsPreview';
import React, { useMemo } from 'react';
import st from './insertTableSheet.module.less';
import { formatToTableColumns, getDiagramIdsItems, modelTypes } from './utils';
import { useEditTableData } from './hooks/useEditTableData';
import TableHierarchiesForm from './tableHierarchiesForm';
import classNames from 'classnames';
import { useTypedSelector } from '../../redux/types';
import { Engine } from '@baseModel/engine/engine';
import { Table, TableBlockValue } from '@baseModel/document/blocks/table';
import { useDispatch } from 'react-redux';
import { editTableClose } from '@components/tableConfiguration/redux/actions';
import uniqueId from 'lodash/uniqueId';

export type TableColumn = JsonDocumentTableBlockColumn & { relation?: string; id: number };

const engine = Engine.getInstance();

function isColumnContentChange(columns: TableColumn[], prevColumns: TableColumn[]) {
  if (columns.length !== prevColumns.length) {
    return true;
  }
  // Если каких то колонок больше нет ( заменили на другие ), значит считаем что изменилось
  for (let i = 0; i < columns.length; i++) {
    if (!prevColumns.find((prevEl) => prevEl.value === columns[i].value)) {
      return true;
    }
  }
  return false;
}

export function TableConfigurationForm() {
  const { editableTableId, editableTableIndex } = useTypedSelector((state) => state.app.tableConfiguration.actions);

  const dispatch = useDispatch();

  const {
    availableRowTypes,
    handleChangeModelType,
    modelType,
    handleChangeRowType,
    rowType,
    filter,
    handleChangeFilter,
    columns,
    prevColumns,
    prevHierarchyColumns,
    prevChildHierarchyColumns,
    setColumns,
    handleChangeHierarchy,
    hierarchy,
    hierarchyColumns,
    setHierarchyColumns,
    childHierarchy,
    setChildHierarchy,
    childHierarchyColumns,
    setChildHierarchyColumns
  } = useEditTableData(editableTableId);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const showLink = hierarchy ? hierarchy : undefined;

    if (!modelType) {
      console.warn(`modelType is empty`);
      return;
    }

    if (!rowType) {
      console.warn(`rowType is empty`);
      return;
    }

    if (isColumnContentChange(columns, prevColumns)) {
      columns.forEach((el) => (el.size = undefined));
    }

    const tableBlockValue: TableBlockValue = {
      rowType,
      modelType,
      columns: formatToTableColumns(columns),
      filter: filter
        ? {
            diagram: filter
          }
        : undefined
    };

    if (showLink) {
      if (isColumnContentChange(hierarchyColumns, prevHierarchyColumns)) {
        hierarchyColumns.forEach((el) => (el.size = undefined));
      }
      tableBlockValue.showLink = {
        ...showLink,
        table: {
          columns: formatToTableColumns(hierarchyColumns)
        }
      };
      // см комментарий к table block
      if (childHierarchy) {
        if (isColumnContentChange(childHierarchyColumns, prevChildHierarchyColumns)) {
          childHierarchyColumns.forEach((el) => (el.size = undefined));
        }
        tableBlockValue.showLink.showLink = {
          ...childHierarchy,
          table: {
            columns: formatToTableColumns(childHierarchyColumns)
          }
        };
      }
    }

    if (editableTableId) {
      const tableBlock = engine.getDocument().getTableById(editableTableId);

      if (!tableBlock) {
        console.warn(`tableBlock ${editableTableId} not found`);
        return;
      }
      tableBlock.setValue(tableBlockValue);
    } else {
      const tableBlock = new Table(uniqueId('md-' + Date.now().toString()));
      tableBlock.setValue(tableBlockValue);
      engine.getDocument().addBlock(tableBlock, editableTableIndex);
    }
    dispatch(editTableClose());
  };

  const diagramsIdsItems = useMemo(() => getDiagramIdsItems(), []);

  return (
    <div className={st.form}>
      <div className={classNames(st.formInner)}>
        <div className={st.mainSettings}>
          <SelectField
            label={`model-type`}
            items={modelTypes}
            onChange={handleChangeModelType}
            value={modelType}
            menuMaxHeight={240}
          />
          {modelType && (
            <SelectField
              label={`row-type`}
              items={availableRowTypes}
              onChange={handleChangeRowType}
              value={rowType}
              menuMaxHeight={240}
            />
          )}{' '}
          <SelectField
            label={`diagram filter`}
            items={diagramsIdsItems}
            onChange={handleChangeFilter}
            value={filter}
            menuMaxHeight={240}
          />
        </div>
        {modelType && rowType && (
          <ColumnsPreview columns={columns} setColumns={setColumns} modelType={modelType} rowType={rowType} />
        )}
        {modelType && rowType && (
          <TableHierarchiesForm
            columns={hierarchyColumns}
            setColumns={setHierarchyColumns}
            hierarchy={hierarchy}
            modelType={modelType}
            rowType={rowType}
            setHierarchy={handleChangeHierarchy}
            childHierarchy={childHierarchy}
            setChildHierarchy={setChildHierarchy}
            childHierarchyColumns={childHierarchyColumns}
            setChildHierarchyColumns={setChildHierarchyColumns}
          />
        )}
        <Button type="button" variant={'contained'} disabled={!modelType || !columns.length} onClick={handleSubmit}>
          Сохранить таблицу
        </Button>
      </div>
    </div>
  );
}
